<template>
  <v-card tile>
    <v-row class="mt-0 pt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Inspect Table
        </v-card-title>
        <v-card-subtitle class="mb-n4"> Admin </v-card-subtitle>
      </v-col>

      <v-col cols="3" class="mt-2">
        <v-btn v-if="hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text class="my-2" :hidden="hidden">
      <div class="text-caption gray01--text">
        Type any valid table name. It will be downloaded as a separate file (FAST) or pasted into the current file (SLOW).
      </div>
      <v-text-field
        v-model="tableName"
        label="Table Name"
      ></v-text-field>
      <v-textarea
          label="Filters"
          v-model="filters"
          hint="Please enter filters in a comma seperated format"
          rows="2"
      ></v-textarea>
      <p class="red--text caption my-0" v-if="errorMessage">{{errorMessage}}</p>
    </v-card-text>
    <div :hidden="hidden">
      <v-card-actions>
        <v-row class="mx-2" justify="end">
        <v-btn
          small
          :disabled="!tableName"
          @click="download"
        >
          Download
        </v-btn>
        <v-btn
          small
          :disabled="!tableName"
          @click="pasteToSheet"
          class="ml-2"
        >
          Paste
        </v-btn>
        </v-row>
      </v-card-actions>
      <v-col v-if="progress.show" cols="12">
        <v-progress-linear indeterminate color="yellow accent-4" height="4" />
      </v-col>
    </div>
    <div :hidden="hidden">
      <v-card-actions class="mx-2">
        <v-row class="mb-1">
          <v-col cols="9" xs="9" />
          <v-col cols="3" xs="3">
            <v-btn v-if="!hidden" icon @click="hidden = !hidden">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>
  
  <script>
  import { msalMixin } from 'vue-msal'
  import * as xlsx from 'xlsx'
  import { HTTP } from './../functions/http-common'
  import Excel from '../functions/excel'
  import Templates from '../functions/templates'
  import CFI from '../functions/cfi'

  export default {
    name: 'Inspection',
    mixins: [msalMixin],
    data: () => ({
      hidden: true,
      excel: new Excel(),
      cfi: new CFI(),
      templates: new Templates(),
      tableName: '',
      errorMessage: '',
      progress: {
        value: 0,
        query: false,
        show: false,
        interval: 0
      },
      filters: ''
    }),
    methods: {
      async retrieveTicker(inspect) {
        this.progress.show = true
        this.errorMessage = ''
        let filter = {}
        let key = '', value = ''
        await this.$msal.acquireToken()
        this.filters.length && this.filters.split(',').forEach(item => {
          const data = item.replaceAll('"', '').split(/:(.*)/s);
          key = data[0].trim()
          value = data[1].trim()
          if (data.length) filter = {...filter, [key]: value}
        });
        const config = {
          headers: { 
            'Authorization': `Bearer ${this.$msal.data.accessToken}`
          },
        };
        await HTTP.post('/table/retrievebyrecords/' + this.tableName, {filter}, config).then(
        async (response) => {
          if (response.data.length === 0) {
            console.log('no data: ', this.tableName)
            this.errorMessage = 'No Data Found'
          } else {
            console.log('response', response);
            if (inspect === 'download') {
              const ws = xlsx.utils.json_to_sheet(response.data)
              const wb = xlsx.utils.book_new()
              xlsx.utils.book_append_sheet(wb, ws, this.tableName.substring(0, 10)) 
              xlsx.writeFile(wb, `${this.tableName}.xlsx`)
            } else {
              const metadata = {
                sheetname: this.tableName
              }
              await this.cfi.writeToBuffer(
                response.data,
                metadata,
              )
              await this.excel.pasteSheets(this.cfi.buffer, this.cfi.header, this.$root.snackbar)
              this.cfi.buffer = []
            }
          }
        }, (error) => {
          console.log('Error', error);
          this.errorMessage = 'Failed to establish API call'
        })
        this.progress.show = false
      },
      async download() {
        await this.retrieveTicker('download')
      },
      async pasteToSheet() {
        await this.retrieveTicker('pasteSheet')
      }
    }
  }
  </script>
  