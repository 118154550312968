var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-menu',{attrs:{"open-on-hover":"","top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item-title',[_vm._v("3. Meta Data / tickersMeta "),_c('v-icon',_vm._g(_vm._b({staticClass:"pl-1",staticStyle:{"vertical-align":"middle"},attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])],1)]}}])},[_c('div',{staticClass:"px-2 py-2 offwhite"},[_c('v-list-item-subtitle',[_vm._v(" Press "),_c('v-icon',{attrs:{"color":"offblack","x-small":""}},[_vm._v(" mdi-compare-horizontal ")]),_vm._v(" to compare ")],1),_c('v-list-item-subtitle',[_vm._v("Compare and send")])],1)]),_c('v-list-item-subtitle',[_vm._v(" DB: "+_vm._s(_vm.cfi.uploadSheets.tickersmeta.len)+" ")]),(_vm.metaTickers.length && _vm.sheets.length)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.sheets .filter((a) => _vm.tickerTables.includes(a.name))[0] .excel.reduce((arr, entry) => { if (!_vm.metaTickers.includes(entry.ticker)) { arr.push(entry.ticker) } return arr }, []).length)+" need Meta Information ")]):_vm._e(),(_vm.cfi.uploadSheets.tickersmeta.reading)?_c('v-progress-linear',{attrs:{"size":"25","color":"yellow","indeterminate":_vm.cfi.uploadSheets.tickersmeta.reading}}):_vm._e()],1),(
        _vm.sheets.filter((a) => _vm.tickerTables.includes(a.name)).length > 0
      )?_c('v-list-item-icon',[_c('v-btn',{attrs:{"color":"offblack","icon":""},on:{"click":function($event){return _vm.cfi.openModal(3)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"color":"offblack","icon":""},on:{"click":function($event){return _vm.downloadMetaTickers()}}},[_c('v-icon',[_vm._v("mdi-compare-horizontal")])],1)],1):_vm._e(),_c('v-list-item-icon',[(!_vm.metaLoading)?_c('v-btn',{attrs:{"color":"offblack","disabled":_vm.metaTickers.length == 0,"icon":""},on:{"click":function($event){_vm.updateMetaTickers(
            _vm.sheets
              .filter((a) => _vm.tickerTables.includes(a.name))[0]
              .excel.reduce((arr, entry) => {
                if (!_vm.metaTickers.includes(entry.ticker)) {
                  arr.push(entry.ticker)
                }
                return arr
              }, [])
          )}}},[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1):_c('v-progress-circular',{attrs:{"size":"25","color":"yellow","value":_vm.metaprogress}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }