<template>
  <v-card tile class="mb-6">
    <!-- Card Head -->
    <v-row class="mt-0 pt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Beta
        </v-card-title>
        <v-card-subtitle class="mb-n4"> Equity Markets </v-card-subtitle>
      </v-col>
      <v-col cols="3" class="mt-2">
        <v-btn
          v-if="!hidden"
          color="offblack"
          icon
          small
          @click="download = !download"
        >
          <v-tooltip v-if="download" left nudge-bottom="50">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-download-circle </v-icon>
            </template>
            <span>
              Click to switch to calculation. <br />
              Now: Betas will be downloaded from the database, as used in Power
              BI.
            </span>
          </v-tooltip>
          <v-tooltip v-else left nudge-bottom="50">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-calculator-variant </v-icon>
            </template>
            <span>
              Click to switch to download. <br />
              Now: Betas will be calculated.
            </span>
          </v-tooltip>
        </v-btn>
        <v-btn v-else icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-card-text class="px-0" :hidden="hidden">
      <!-- <v-row class="mx-4">
        <p class="text-caption">1. Select your settings.</p>
      </v-row> -->
      <v-row class="mx-4 pa-0">
        <p class="text-body-2">Do you want to download or calculate Betas</p>
      </v-row>
      <v-row class="mx-4 pa-0">
        <v-switch v-model="download" class="my-0 mx-2" color="gray01" dense>
          <template v-slot:label>
            <div class="text-body-2">
              {{ download ? 'Download' : 'Calculation' }}
            </div>
          </template>
        </v-switch>
      </v-row>
      
      <v-expansion-panels flat accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="8"> Levered Beta </v-col>
                <v-col
                  v-if="leveredBeta.status"
                  cols="4"
                  class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                    <span v-if="open" key="0" />
                    <v-icon v-else key="1" small color="green">
                      mdi-check
                    </v-icon>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-select
                  v-model="selIndex"
                  :items="selectIndex"
                  :item-text="selectIndex.text"
                  :item-value="selectIndex.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Indices</div>
                  </template>
                </v-select>
                <v-select
                  v-model="periodFreq"
                  :items="selectPeriodFreq"
                  :item-text="selectPeriodFreq.text"
                  :item-value="selectPeriodFreq.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Period & Frequency</div>
                  </template>
                </v-select>
                <v-select
                  v-model="yieldMethod"
                  :items="selectYieldMethod"
                  :item-text="selectYieldMethod.text"
                  :item-value="selectYieldMethod.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Yield Method in Regression</div>
                  </template>
                </v-select>
                <!-- <v-select
                  v-if="download"
                  v-model="sig"
                  :items="selectSig"
                  :item-text="selectSig.text"
                  :item-value="selectSig.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs}">
                    <v-list-item  dense v-on="on" v-bind="attrs">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                        <span>{{ item.text }}</span>
                        <v-spacer></v-spacer>
                        <v-chip color="gray01" text-color="offwhite" label small v-if="item.default"
                        >default</v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Significance Level</div>
                  </template>
                </v-select> -->
                <v-switch v-model="betaAdj" dense :disabled="download">
                  <template v-slot:label>
                    <div class="text-body-2">
                      {{ betaAdj ? 'Adjusted Beta' : 'Raw Beta' }}
                    </div>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="8"> Net Debt </v-col>
                <v-col
                  v-if="leveredBeta.status"
                  cols="4"
                  class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                    <span v-if="open" key="0" />
                    <v-icon v-else key="1" small color="green">
                      mdi-check
                    </v-icon>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-select
                  v-model="finServices"
                  :items="selectFinServices"
                  :item-text="selectFinServices.text"
                  :item-value="selectFinServices.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Financial Services</div>
                  </template>
                </v-select>
                <v-select
                  v-model="pension"
                  :items="selectPension"
                  :item-text="selectPension.text"
                  :item-value="selectPension.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Pensions</div>
                  </template>
                </v-select>
                <v-select
                  v-model="netCash"
                  :items="selectNetCash"
                  :item-text="selectNetCash.text"
                  :item-value="selectNetCash.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Net Cash</div>
                  </template>
                </v-select>
                <v-select
                  v-model="leaseLiability"
                  :items="selectLeaseLiability"
                  :item-text="selectLeaseLiability.text"
                  :item-value="selectLeaseLiability.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Lease Liability</div>
                  </template>
                </v-select>
                <v-select
                  v-model="debtApproach"
                  :items="selectDebtApproach"
                  :item-text="selectDebtApproach.text"
                  :item-value="selectDebtApproach.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Debt Approach</div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="8"> Unlevering </v-col>
                <v-col
                  v-if="unleveredBeta.status"
                  cols="4"
                  class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                    <span v-if="open" key="0" />
                    <v-icon v-else key="1" small color="green">
                      mdi-check
                    </v-icon>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mt-n4">
              <v-col>
                <v-select
                  v-model="taxShield"
                  :items="selectTaxShield"
                  :item-text="selectTaxShield.text"
                  :item-value="selectTaxShield.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Risk of Tax Shield</div>
                  </template>
                </v-select>
                <v-select
                  v-model="debtBeta"
                  :items="selectDebtBeta"
                  :item-text="selectDebtBeta.text"
                  :item-value="selectDebtBeta.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Debt Beta</div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="8"> Timeline </v-col>
                <v-col
                  v-if="date != '' || download != false"
                  cols="4"
                  class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                    <span v-if="open" key="0" />
                    <v-icon v-else key="1" small color="green">
                      mdi-check
                    </v-icon>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <p
                  v-if="download"
                  class="text-caption gray02--text text-center"
                >
                  All available historical betas will be downloaded from the
                  database.
                </p>
                <!-- <v-checkbox v-model="download" :disabled="disableDownload" dense>
                <template v-slot:label>
                  <div class="text-body-2">Download from Database</div>
                </template>
              </v-checkbox> -->
                <v-menu
                  v-if="!download"
                  ref="menu"
                  v-model="menu"
                  :disabled="download"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :disabled="download"
                      label="Qualifying Date"
                      prepend-icon="mdi-calendar"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    color="offblack"
                    first-day-of-week="1"
                    full-width
                    dense
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-alert
                  v-if="new Date(date).getDay() != 5 && date != ''"
                  dense
                  outlined
                  dismissible
                  type="error"
                  class="text-caption"
                >
                  Qualifying Date will be set to last Friday before {{ date }}.
                </v-alert>
                <v-select
                  v-if="!download"
                  v-model="valdates_span"
                  :items="selectValdatesSpan"
                  :item-text="selectValdatesSpan.text"
                  :item-value="selectValdatesSpan.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:label>
                    <div class="text-caption">Historical Betas</div>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <add-tickers v-model="tickers" />
    </v-card-text>
    <div :hidden="hidden">
      <v-card-actions class="mx-2">
        <v-row class="mb-1">
          <!-- <v-col cols="6" xs="6">
            <v-btn small @click="readValues()">Select Tickers</v-btn>
          </v-col>
          <v-col cols="6" xs="6" align="end">
            <v-btn small depressed plain text tile disabled>
              <span v-if="tickers.length === 0">{{ tickers.length }} tickers</span>
              <span v-else class="primary--text">{{ tickers.length }} tickers</span>
            </v-btn>
          </v-col> -->
          <v-col cols="4" xs="4">
            <!-- <v-btn
              v-if="download"
              small
              :disabled="disabledSend"
              @click="downloadBetas()"
            > -->
            <v-btn
              v-if="download"
              small
              :disabled="disabledSend"
              @click="modal.isVisible = 1; modal.isBetaPipeline = 1"
            >
              Download
            </v-btn>
            <v-btn
              v-else
              small
              :loading="computeAlert"
              :disabled="disabledSend || computeAlert"
              @click="computeBetas()"
            >
              Compute
              <template v-slot:loader>
                <v-icon v-if="computeId != ''" small class="ml-2" color="green">
                  mdi-check
                </v-icon>
                <v-progress-circular v-else :width="3" indeterminate />
              </template>
            </v-btn>
          </v-col>
          <v-col cols="5" xs="5">
            <v-chip v-if="computeAlert" label small>
              {{ computeId }}
            </v-chip>
          </v-col>
          <v-col cols="3" xs="3">
            <v-btn v-if="!hidden" icon @click="hidden = !hidden">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col cols="3" xs="3">
          <v-btn small text @click="chartbuilding()"><v-icon>mdi-chart-line</v-icon></v-btn>
          </v-col>   -->
        </v-row>
      </v-card-actions>
    </div>
    <v-col v-if="progress.show" cols="12">
      <v-progress-linear indeterminate color="yellow accent-4" height="4" />
    </v-col>

    <h2 :hidden=true>{{ updateCheckBoxes }}</h2> <!-- required to call the computed function -->
    <confirmation-modal
      @close="modal.isVisible = 0"
      @download="downloadBetas(); modal.isVisible = 0"
      :modal="modal"
    />
  </v-card>
</template>

<script>
import Excel from '../functions/excel'
import Templates from '../functions/templates'
import { v4 as uuidv4 } from 'uuid'
import { msalMixin } from 'vue-msal'
import AddTickers from './Common/AddTickers.vue'
import { HTTP } from './../functions/http-common'
import CFI from '../functions/cfi'
import ConfirmationModal from './Common/ConfirmationModal.vue'

export default {
  name: 'BetaDoc',
  components: {
    addTickers: AddTickers,
    confirmationModal: ConfirmationModal
  },
  mixins: [msalMixin],
  data: () => ({
    excel: new Excel(),
    cfi: new CFI(),
    templates: new Templates(),
    tickers: [],
    menu: false,
    hidden: false,
    computeAlert: false,
    date: '',
    computeId: '',
    progress: {
      value: 0,
      query: false,
      show: false,
      interval: 0
    },
    counter: 0,
    modal: {
      isVisible: 0,
      isBetaPipeline: 1,
      isMultiplesPipeline: 0,
      metaSheetAvailable: 1,
      cbs: {
        betas: {
          dataSheets: {
            meta: 1,
            stockPrices: 1,
            leveredBeta: 1,
            deRatioKPIs: 1,
            creditSpread: 1,
            deRatio: 1,
            debtBeta: 1,
            unleveredBeta: 1,
          },
          templates: {
            beta: 1,
            chart: 1,
          }
        },
        multiples: {
          dataSheets: {
            meta: 0,
            multipleKPIs: 0,
            calculateTimeline: 0,
            calculateMultiple: 0,
          },
          templates: {
            chart: 0,
          },
        },
      },
      heading: 'Select Tables',
      content: 'Please select the tables you wish to download',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Download', event: 'download', color: '' },
      ]
    },
    download: undefined,
    disableDownload: false,
    selIndex: 'broad local price',
    periodFreq: '5 Years Monthly',
    yieldMethod: 'Discrete',
    sig: '99%',
    finServices: 'not include in Net Debt',
    pension: 'include in Net Debt',
    taxShield: 'include Tax Shield',
    debtBeta: 'No',
    netCash: "Don't Allow",
    leaseLiability: "include in Net Debt",
    debtApproach: "Net Debt",
    betaAdj: false,
    scenario: {},
    valdates_span: 1,
    selectValdatesSpan: [
      { text: 'no history', value: 0, disabled: false, default: false },
      { text: '1 Years', value: 1, disabled: false, default: true },
      { text: '2 Years', value: 2, disabled: false, default: false },
      { text: '3 Years', value: 3, disabled: false, default: false },
      { text: '4 Years', value: 4, disabled: false, default: false }
    ],
    disableOptions: [
      { selectPension: 'not include in Net Debt' },
      { selectNetCash: 'Allow' },
      { selectLeaseLiability: 'exclude in Net Debt' },
      // { selectDebtApproach: 'Total Debt' },
      { selectIndex: 'broad local performance' },
      { selectIndex: 'MSCI performance' },
      { selectYieldMethod: 'Continuous' },
      { selectPeriodFreq: '3 Years Weekly' },
      { selectPeriodFreq: '4 Years Weekly' }
    ],
    selectIndex: [
      {
        text: 'Local (performance index)',
        value: 'broad local performance',
        disabled: true,
        default: false
      },
      {
        text: 'Local (price index)',
        value: 'broad local price',
        disabled: false,
        default: true
      },
      {
        text: 'MSCI (performance index)',
        value: 'MSCI performance',
        disabled: true,
        default: false
      },
      {
        text: 'MSCI (price index)',
        value: 'MSCI price',
        disabled: true,
        default: false
      }
    ],
    selectPeriodFreq: [
      {
        text: '2 years, weekly',
        value: '2 Years Weekly',
        disabled: false,
        default: true
      },
      {
        text: '3 years, weekly',
        value: '3 Years Weekly',
        disabled: true,
        default: false
      },
      {
        text: '4 years, weekly',
        value: '4 Years Weekly',
        disabled: true,
        default: false
      },
      {
        text: '5 years, monthly',
        value: '5 Years Monthly',
        disabled: false,
        default: false
      }
    ],
    selectYieldMethod: [
      { text: 'Discrete', value: 'Discrete', disabled: false, default: true },
      {
        text: 'Continuous',
        value: 'Continuous',
        disabled: true,
        default: false
      }
    ],
    selectSig: [
      { text: '99%', value: '99%', disabled: false, default: true },
      { text: '95%', value: '95%', disabled: false, default: false }
    ],
    selectFinServices: [
      {
        text: 'include in Net Debt',
        value: 'include in Net Debt',
        disabled: true,
        default: false
      },
      {
        text: 'exclude in Net Debt',
        value: 'not include in Net Debt',
        disabled: false,
        default: true
      }
    ],
    selectPension: [
      {
        text: 'include in Net Debt',
        value: 'include in Net Debt',
        disabled: false,
        default: true
      },
      {
        text: 'exclude in Net Debt',
        value: 'not include in Net Debt',
        disabled: true,
        default: false
      }
    ],
    selectTaxShield: [
      {
        text: 'risk of debt',
        value: 'include Tax Shield',
        disabled: false,
        default: true
      },
      {
        text: 'risk of unlevered equity',
        value: 'No Tax Shield',
        disabled: true,
        default: false
      }
    ],
    selectDebtBeta: [
      { text: 'Yes', value: 'Yes', disabled: true, default: false },
      { text: 'No', value: 'No', disabled: false, default: true }
    ],
    selectNetCash: [
      { text: 'Allow', value: 'Allow', disabled: true, default: false },
      {
        text: "Don't Allow",
        value: "Don't Allow",
        disabled: false,
        default: true
      }
    ],
    selectLeaseLiability: [
      { text: 'include in Net Debt', value: 'include in Net Debt', disabled: false, default: true },
      {
        text: "exclude in Net Debt",
        value: "exclude in Net Debt",
        disabled: true,
        default: false
      }
    ],
    selectDebtApproach: [
      { text: 'Total Debt', value: 'Total Debt', disabled: true, default: false },
      {
        text: "Net Debt",
        value: "Net Debt",
        disabled: false,
        default: true
      }
    ],
  }),
  computed: {
    updateCheckBoxes(){
      this.updateCheckBoxes_pt2()
      return null
    },
    downloadProgress() {
      return this.progress
    },
    computeBeta() {
      return (
        this.netDebt.pension == 'False' ||
        this.leveredBeta.index == 'broad local performance' ||
        this.leveredBeta.index == 'MSCI performance' ||
        this.leveredBeta.yield == 'Continuous' ||
        this.periodFreq == '3 Years Weekly' ||
        this.periodFreq == '4 Years Weekly'
      )
    },
    disabledSend() {
      return !(
        this.tickers.length !== 0 &&
        this.leveredBeta.status &&
        this.unleveredBeta.status &&
        (this.date != '' || this.download != false)
      )
    },
    leveredBeta() {
      let levered = {}
      const { selIndex, periodFreq, yieldMethod, sig } = this
      if (selIndex) {
        levered.index = selIndex
      }
      levered.performance = selIndex.includes('performance') ? 'True' : 'False'
      levered.international = selIndex.includes('MSCI') ? 'True' : 'False'

      if (periodFreq) {
        levered.freq = periodFreq.includes('Weekly') ? 'W' : 'M'
        levered.lengthPeriod = parseInt(periodFreq.charAt(0))
      }
      if (yieldMethod) {
        levered.yield = yieldMethod == 'Continuous' ? 'continuous' : 'discrete'
      }
      if (sig) {
        levered.sig = sig
      }
      levered.status =
        selIndex && periodFreq && yieldMethod && sig ? true : false
      levered.adj = this.betaAdj ? 'True' : 'False'
      return levered
    },
    netDebt() {
      let netDebt = {}
      const { netCash, leaseLiability, debtApproach, finServices, pension } = this
      if (netCash) {
        netDebt.netCash = netCash == 'Allow' ? 'True' : 'False'
      }
      if (leaseLiability) {
        netDebt.leaseLiability = leaseLiability == 'include in Net Debt' ? 'True' : 'False'
      }
      if (debtApproach) {
        netDebt.debtApproach = debtApproach == 'Total Debt' ? 'True' : 'False'
      }
      if (finServices) {
        netDebt.finServices =
          finServices == 'include in Net Debt' ? 'True' : 'False'
      }
      if (pension) {
        netDebt.pension = pension == 'include in Net Debt' ? 'True' : 'False'
      }
      netDebt.status = finServices && pension
      return netDebt
    },
    unleveredBeta() {
      let unlevered = {}
      const { debtBeta, taxShield } = this
      if (debtBeta) {
        if (debtBeta == 'Yes') {
          unlevered.debtBeta = 'debt beta'
          unlevered.debtBetaBool = 'True'
        } else {
          unlevered.debtBeta = 'no debt beta (debt beta is 0)'
          unlevered.debtBetaBool = 'False'
        }
      }
      if (taxShield) {
        if (taxShield == 'include Tax Shield') {
          unlevered.taxShield = 'risk of debt'
          unlevered.taxShieldBool = 'True'
        } else {
          unlevered.taxShield = 'risk of unlevered equity'
          unlevered.taxShieldBool = 'False'
        }
      }
      unlevered.status = taxShield && debtBeta
      return unlevered
    }
  },
  watch: {
    computeAlert(value) {
      if (value) {
        setTimeout(() => {
          this.computeAlert = false
          this.computeId = ''
        }, 5000)
      }
    },
    download(beta) {
      if (beta) {
        this.resetDefault()
        this.disableDownload = true
        this.disableOptions.forEach((option) => {
          let key = Object.keys(option)[0]
          let value = Object.values(option)[0]
          switch (key) {
            case 'selectPension':
              this.selectPension.find((a) => a.value == value).disabled = true
              break
            case 'selectNetCash':
              this.selectNetCash.find((a) => a.value == value).disabled = true
              break
            case 'selectLeaseLiability':
              this.selectLeaseLiability.find((a) => a.value == value).disabled = true
              break
            case 'selectDebtApproach':
              this.selectDebtApproach.find((a) => a.value == value).disabled = true
              break
            case 'selectIndex':
              this.selectIndex.find((a) => a.value == value).disabled = true
              break
            case 'selectYieldMethod':
              this.selectYieldMethod.find(
                (a) => a.value == value
              ).disabled = true
              break
            case 'selectPeriodFreq':
              this.selectPeriodFreq.find(
                (a) => a.value == value
              ).disabled = true
          }
        })
      } else {
        this.resetDefault()
        this.disableDownload = false
        this.disableOptions.forEach((option) => {
          let key = Object.keys(option)[0]
          let value = Object.values(option)[0]
          switch (key) {
            case 'selectPension':
              this.selectPension.find((a) => a.value == value).disabled = false
              break
            case 'selectNetCash':
              this.selectNetCash.find((a) => a.value == value).disabled = false
              break
            case 'selectLeaseLiability':
              this.selectLeaseLiability.find((a) => a.value == value).disabled = false
              break
            case 'selectDebtApproach':
              this.selectDebtApproach.find((a) => a.value == value).disabled = false
              break
            case 'selectIndex':
              this.selectIndex.find((a) => a.value == value).disabled = true
              if (value == 'broad local performance') {
                this.selectIndex.find((a) => a.value == value).disabled = true
              }
              break
            case 'selectYieldMethod':
              this.selectYieldMethod.find(
                (a) => a.value == value
              ).disabled = false
              break
            case 'selectPeriodFreq':
              this.selectPeriodFreq.find(
                (a) => a.value == value
              ).disabled = false
          }
        })
      }
    }
  },
  mounted() {
    this.download = true
  },
  methods: {
    updateCheckBoxes_pt2(){
      if(this.modal.cbs.betas.templates.beta){
        this.modal.cbs.betas.dataSheets.meta = 1
        this.modal.cbs.betas.dataSheets.unleveredBeta = 1
      }
      if(this.modal.cbs.betas.templates.chart){
        this.modal.cbs.betas.dataSheets.unleveredBeta = 1
      }
    },
    async downloadBetas() {
      this.setScenarios()
      let suffix = ' ' + String(uuidv4()).substring(0, 7)
      this.progress.show = true
      this.cfi.snackbar = this.$root.snackbar
      let tableSuffix = ''
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }

      let tickerlist = this.excel.getTickerList(this.tickers)

      await this.cfi.downloadBeta(
        tickerlist,
        this.scenario,
        suffix,
        tableSuffix,
        config,
        this.modal.cbs
      )
      await this.excel.pasteSheets(this.cfi.buffer, this.templates.datasheetHeader_dark, this.modal, this.$root.snackbar)
      this.cfi.buffer = []
      this.progress.show = false
    },
    async computeBetas() {
      const {
        freq: frequency,
        adj: adjusted_beta,
        lengthPeriod: regression_horizon,
        performance,
        international,
        yield: yieldValue
      } = this.leveredBeta
      const {
        pension: pensions,
        netCash: net_cash,
        leaseLiability: include_lease_liability,
        debtApproach: use_total_debt,
        finServices: financial_services
      } = this.netDebt
      const { debtBetaBool: risk_of_debt_beta, taxShieldBool: risk_of_tax_shield } =
        this.unleveredBeta
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      const suffix = String(uuidv4()).substring(0, 11).replace('-', '')
      let tickerlist = this.excel.getTickerList(this.tickers)

      let parameters = {
        tickers: tickerlist,
        end: this.date,
        valdates_span: this.valdates_span,

        frequency,
        performance,
        international,

        regression_horizon,
        regression_method: yieldValue,
        adjusted_beta,

        financial_services,
        pensions,
        net_cash,
        include_lease_liability,
        use_total_debt,

        risk_of_debt_beta,
        risk_of_tax_shield,

        num_tickers_per_job: 10,
        caption: suffix,
        queue_id: 0
      }
      HTTP
        .post('/pipeline/start/unleveredbeta/', parameters, config)
        .then(
          (response) => {
            if (response.data != '') {
              this.computeAlert = true
              this.computeId = suffix
            }
          },
          (error) => {
            console.log(error)
          }
        )
    },
    progressBalken(progress) {
      this.progress.value = progress
    },
    setScenarios() {
      const {
        freq: frequency,
        index: indices,
        lengthPeriod: length_period,
        adj,
        yield: yieldValue
      } = this.leveredBeta
      const {
        pension,
        netCash: net_cash,
        leaseLiability: include_lease_liability,
        debtApproach: use_total_debt,
        finServices: financial_services
      } = this.netDebt
      const { debtBeta: risk_of_debt_beta, taxShield: risk_of_tax_shield } =
        this.unleveredBeta
      this.scenario = {
        Step1: {
          frequency,
          indices
        },
        Step2: {
          length_period_year: length_period,
          yield: yieldValue,
          frequency,
          indices,
          adjusted_beta: adj == 'True' ? 1 : 0
        },
        Step3: {},
        Step4: {},
        Step5: {
          regression_horizon: length_period,
          pension,
          net_cash,
          include_lease_liability,
          use_total_debt,
          financial_services
        },
        Step6: {
          financial_services
        },
        Step7: {
          frequency,
          yield: yieldValue,
          indices,
          length_period_year: length_period,
          risk_of_debt_beta,
          risk_of_tax_shield,
          pension,
          net_cash,
          include_lease_liability,
          use_total_debt,
          financial_services,
          adjusted_beta: adj == 'True' ? 1 : 0
        }
      }
    },
    resetDefault() {
      this.date = ''
      this.disableDownload = false
      this.selIndex = 'broad local price'
      this.periodFreq = '2 Years Weekly'
      this.yieldMethod = 'Discrete'
      this.sig = '99%'
      this.finServices = 'not include in Net Debt'
      this.pension = 'include in Net Debt'
      this.taxShield = 'include Tax Shield'
      this.debtBeta = 'No'
      this.netCash = "Don't Allow"
      this.leaseLiability = "include in Net Debt"
      this.debtApproach = "Net Debt"
      this.betaAdj = false
      this.valdates_span = 3
    },
    chartbuilding() {
      window.Excel.run(async (context) => {
        let sheets = context.workbook.worksheets
        sheets.load('items/name')
        await context.sync()
        let newSheet = '(0) Charts'
        let sheetnames = []
        sheets &&
          sheets.items.forEach((item) => {
            sheetnames.push(item.name)
          })
        const dataSheet = [
          '(1) Stock Prices',
          '(2) Levered Beta',
          '(3) DE Ratio KPIs',
          '(4) Credit Spread KPIs',
          '(5) DE Ratio',
          '(6) Debt Beta',
          '(7) Unlevered Beta'
        ]
        dataSheet.forEach((element) => {
          if (!sheetnames.includes(element)) {
            throw 'Missing Data Sheets!'
          }
        })
        let sheet = null
        if (!sheetnames.includes(newSheet)) {
          sheet = sheets.add(newSheet)
        } else {
          sheet = sheets.getItem(newSheet)
        }
        // get Table Step 7
        // unlev beta
        let step7table = sheet.tables.getItem('UnleveredBeta')
        let betaUnlev = step7table.columns.getItem('beta_unlev').getRange()
        let dates = step7table.columns
          .getItem('end_timewindow')
          .getDataBodyRange()
        let chart = sheet.charts.add('Line', betaUnlev, 'Auto')
        chart.series.getItemAt(0).setXAxisValues(dates)
        chart.title.text = 'Unlevered Beta'
        chart.legend.position = 'right'
        chart.width = 1000
        chart.height = 250
        chart.legend.format.fill.setSolidColor('white')

        // lev beta
        let betalev = step7table.columns.getItem('beta_lev').getRange()
        let chartLev = sheet.charts.add('Line', betalev, 'Auto')
        chartLev.series.getItemAt(0).setXAxisValues(dates)
        chartLev.title.text = 'Levered Beta'
        chartLev.legend.position = 'right'
        chartLev.width = 1000
        chartLev.height = 250
        chartLev.top = 250
        chartLev.legend.format.fill.setSolidColor('white')

        sheet.activate()
        await context.sync()
      }).catch((error) => {
        this.message = error
        console.log(error)
      })
    }
  }
}
</script>
