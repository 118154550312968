<template>
  <div>
    <v-stepper-step :complete="cfi.stepper > 2" step="2" class="my-0 py-0">
      Compare staging
    </v-stepper-step>
    <div v-if="(cfi.stepper === 2)" class="ml-6">
      <v-chip
        label
        x-small
        text-color="orangedark"
        color="orangelight"
        class="mr-2"
      >
        {{
            tableupdate.stag != undefined
            ? tableupdate.stag.length
            : '-'
        }}
        Staging rows <br />
      </v-chip>
      <v-chip
        label
        x-small
        class="mr-2"
      >
        {{
            tableupdate.prod != undefined
            ? tableupdate.prod.length
            : '-'
        }}
        Production rows <br />
      </v-chip>
      <v-chip
        label
        x-small
        text-color="greendark"
        color="greenbg"
        class="mr-2"
      >
        {{
            tableupdate.newRows != undefined
            ? tableupdate.newRows.length
            : '-'
        }}
        new Rows
      </v-chip>
    </div>
    <v-stepper-content class="ma-1 pa-1" step="2">
      <v-row align="center" v-if="cfi.tabledata.data != undefined" class="my-0 mx-6">
        <v-col class="d-flex" cols="12" md="6" xs="6">
          <v-select
            v-model="cfi.filterCol"
            label="Select Column to filter"
            dense
            :items="Object.keys(cfi.tabledata.data[0])"
            v-on:change="checkData"
          />
        </v-col>
      </v-row>
      <div class="float-right my-2">
        <v-btn color="primary" x-small class="ml-6" @click="cfi.stepper = 3">
            Next
        </v-btn>
        <v-btn text x-small class="mx-2" @click="cfi.stepper = 1">
            Back
        </v-btn>
      </div>
    </v-stepper-content>
  </div>
</template>

<script>
export default {
  name: 'CompareStaging',
  props: {
    cfi: {
      type: Object,
      required: true
    },
    checkData: {
      type: Function,
      required: true
    },
    tableupdate: {
      type: Object,
      required: true
    }
  }
}
</script>