<template>
  <v-card tile>
    <v-row class="mt-0 pt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Update Database
        </v-card-title>
        <v-card-subtitle class="mb-n4"> Admin </v-card-subtitle>
      </v-col>

      <v-col cols="3" class="mt-2">
        <v-btn v-if="hidden" icon @click="hidden = !hidden; cfi && cfi.getTableEntries()">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          v-if="!hidden"
          icon
          small
          @click="onReload()"
        >
          <v-icon small> mdi-reload </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text v-if="!cfi.openOverlay" class="px-0" :hidden="hidden">
      <v-card
        v-if="sheets.filter((a) => updateTablesList.includes(a.name)).length == 0"
        class="mb-n4"
        width="100vw"
        flat
      >
        <v-card-text class="pa-0 d-flex justify-center">
          <v-row class="d-flex align-center justify-center mx-4">
            <div class="text-center text-caption gray01--text mt-4">
              <span class="offblack--text">
                1. Updating VME Database Tables
              </span>
              <br />
              Click the button to scan for VME Database sheets <br />
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="ma-2"
            x-small
            color="gray01"
            outlined
            @click="readSheets(updateTablesList)"
          >
            Scan
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-list dense>
        <vme-tables
          :sheets="sheets"
          :updateTablesList="updateTablesList"
          :updateTables="updateTables"
          :cfi="cfi"
        />
        <ticket-set
          :cfi="cfi"
          :sheets="sheets"
          :pasteTable="pasteTable"
          :readSheets="readSheets"
        />
        <meta-data
          :sheets="sheets"
          :cfi="cfi"
        />
        <!-- <industry-data
          :cfi="cfi"
          :readTables="readTables"
        /> -->
        <kpi-data
          :cfi="cfi"
          :readTables="readTables"
        />
      </v-list>
    </v-card-text>
    <v-card-text class="px-0" v-else :hidden="hidden">
      <v-divider />
      <vme-table-detail
        :cfi="cfi"
        :excel="excel"
        :templates="templates"
      />
    </v-card-text>
    <div :hidden="hidden">
      <v-card-actions class="mx-2">
        <v-row class="mb-1">
          <v-col cols="9" xs="9" />
          <v-col cols="3" xs="3">
            <v-btn v-if="!hidden" icon @click="hidden = !hidden">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <confirmation-modal
        :modal="cfi.modal"
        @close="(cfi.modal.isVisible = false)"
        @delete="deleteEntry()"
      />
    </div>
  </v-card>
</template>

<script>
import { msalMixin } from 'vue-msal'
import Excel from '../../functions/excel'
import ConfirmationModal from './../Common/ConfirmationModal.vue'
import VMETableDetail from './VMETableDetails'
import VMETables from './VMETables.vue'
import TickerSet from './TickerSet.vue'
import MetaData from './MetaData.vue'
import KPIData from './KPIData.vue'
//import IndustryData from './IndustryData.vue'
import { HTTP } from '../../functions/http-common'
import CFI from '../../functions/cfi'
import Templates from '../../functions/templates'

export default {
  name: 'UploadData',
  components: {
    confirmationModal: ConfirmationModal,
    vmeTableDetail: VMETableDetail,
    vmeTables: VMETables,
    ticketSet: TickerSet,
    metaData: MetaData,
    //IndustryData: IndustryData,
    kpiData: KPIData
  },
  mixins: [msalMixin],
  data: () => ({
    excel: new Excel(),
    cfi: new CFI(),
    templates: new Templates(),
    hidden: true,
    sheets: [],
    industryTickers: [],
    updateTables: {
      'tRiskFreeRate': 'RFZID',
      'tCountry': 'ID',
      'tFKRates': 'ID',
      'tTaxRates': 'TaxID',
      'tMRP': 'MRPID',
      'tGrowthrate': 'ID',
      'tRelativeVolatility': 'ID',
      'mnemonics': '' 
    },
    tables: [
      'tickersmeta',
      //'tickermeta',
      'SupplementaryInfos'
    ],
  }),
  async mounted() {
    await this.$msal.acquireToken()
    this.cfi.token = this.$msal.data.accessToken
    if (!this.hidden) {
      this.readSheets(this.updateTablesList)
    }
  },
  computed: {
    updateTablesList() {
      return Object.keys(this.updateTables)
    }
  },
  methods: {
    async readTables(sheetName) {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      if (await this.excel.sheetExists(sheetName, this.$root.snackbar)) {
        this.cfi.uploadSheets[sheetName].reading = true
        this.cfi.uploadSheets[sheetName].range = await this.excel.getTableRange(
          sheetName
        )
        this.cfi.uploadSheets[sheetName].reading = false
      } else {
        await this.pasteTable(this.templates.templateTables[sheetName], sheetName)
      }
    },
    transformName(name) {
      return String(name).replace(' ', '_').replace('(', '').replace(')', '')
    },
    async readSheets(tableNames, load = true) {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      if (tableNames == 'tickerInputs') this.cfi.uploadSheets[tableNames].reading = true
      const config = {
         headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await window.Excel.run(async (context) => {
        const sheets = context.workbook.worksheets
        sheets.load('items/name')
        await context.sync()
        for (const sheet of sheets.items) {
          const sheetName = sheet.name
            if (tableNames.includes(sheetName)) {
              const table = {}
              let exists = undefined
              table['name'] = sheetName
              if (load) {
                await HTTP
                .get(
                  '/table/retrievebyrecords/' + sheetName,
                  config
                )
                .then(
                  (response) => {
                  response.data.forEach((entry) => {
                    delete entry['RowKey']
                    delete entry['etag']
                    delete entry['Timestamp']
                    delete entry['_IndCol']
                  
                    if (entry['PartitionKey'] == 'sample') {
                      entry['PartitionKey'] = 'latest'
                    }
                  })
                  table['data'] = response.data
                    table['stag'] = response.data.filter(
                      (a) => a['PartitionKey'] == 'staging'
                    )
                    exists = true
                },
                  (error) => {
                    console.log(error)
                    exists = false
                  }
                )
              } else {
                table['data'] = [
                ['', ''],
                ['', '']
                ]
                exists = true
              }
              if (exists) {
                console.log('table exists: ', sheetName)
                const sheet = sheets.getItem(sheetName)
                let foundCols = true
                let foundRows = true
                let colNums = 30
                let rowNums = 100
                await context.sync()
                while (foundCols) {
                  const columns = sheet
                  .getRange('A1:' + this.excel.getColumnLetter(colNums) + '1')
                  .load('values')
                  await context.sync()
                  const filteredColumnLength = columns.values[0].filter(
                  (a) => a != ''
                ).length
                if (filteredColumnLength < colNums) {
                  colNums = filteredColumnLength
                    foundCols = false
                  } else {
                    colNums = colNums + 10
                  }
                }
                let columnLetter = this.excel.getColumnLetter(colNums)
                while (foundRows) {
                  let rows = sheet
                  .getRange('A2:' + columnLetter + String(rowNums + 2))
                  .load('values')
                  .load('text')
                  await context.sync()
                  const filteredRowsLength = rows.text.filter((a) => a[0] != '').length
                  if (filteredRowsLength < rowNums) {
                    rowNums = filteredRowsLength
                    foundRows = false
                  } else {
                    rowNums = rowNums + 200
                  }
                }
                table['excel'] = []
                if (rowNums > 0) {
                  const excelTable = sheet
                  .getRange('A1:' + columnLetter + String(rowNums + 1))
                  .load('values')
                  .load('text')
                  .load('format')
                  await context.sync()
                  for (const row in excelTable.text) {
                    if (row == 0) {
                      continue
                    }
                  const json = { PartitionKey: 'staging' }
                  for (const col in excelTable.text[row]) {
                    if (
                      (
                        '' +
                        table['data'][0][
                          this.transformName(excelTable.text[0][col])
                        ]
                      ).length == 24
                    ) {
                      if (
                        excelTable.text[0][col].includes('Date') ||
                        excelTable.text[0][col].includes('Stand')
                      ) {
                        json[this.transformName(excelTable.text[0][col])] =
                          this.getJsDateFromExcel(excelTable.values[row][col])
                            .toISOString()
                            .split('T')[0]
                          } else {
                        json[this.transformName(excelTable.text[0][col])] =
                          excelTable.values[row][col].substring(0, 10)
                      }
                    } else {
                      if (
                        excelTable.text[0][col].includes('Date') ||
                        excelTable.text[0][col].includes('Stand')
                      ) {
                        json[this.transformName(excelTable.text[0][col])] =
                          this.getJsDateFromExcel(
                            excelTable.values[row][col]
                          ).toLocaleDateString('de-DE')
                      } else {
                        if (
                          typeof table['data'][0][
                            this.transformName(excelTable.text[0][col])
                          ] == 'number' ||
                          typeof table['data'][0][
                            this.transformName(excelTable.text[0][col])
                          ] == 'object'
                        ) {
                          if (excelTable.text[row][col] != 'N/A') {
                            json[this.transformName(excelTable.text[0][col])] =
                              excelTable.values[row][col]
                          } else {
                            json[this.transformName(excelTable.text[0][col])] =
                              null
                          }
                        } else {
                          if (excelTable.text[row][col] != 'N/A') {
                            if(excelTable.text[0][col] == 'ticker'){
                              json[this.transformName(excelTable.text[0][col])] =
                              excelTable.text[row][col]
                            }else{
                              json[this.transformName(excelTable.text[0][col])] =
                              excelTable.text[row][col].replaceAll('.', ',')
                            }
                          } else {
                            json[this.transformName(excelTable.text[0][col])] =
                              ''
                          }
                        } 
                      }
                    }
                  }
                  await context.sync()
                  table['excel'].push(json)
                }
                }
              }
              console.log(table)
              const filteredTable = this.sheets.filter(
              (a) => a.name == table.name
            )
            if (filteredTable.length) {
              filteredTable[0] = table
            } else {
                this.sheets.push(table)
              }
            }
        }
        if (
          tableNames.length == 1 &&
          Object.keys(this.templates.templateTables).includes(tableNames[0]) &&
          sheets.items.filter((a) => a.name == tableNames[0]).length <= 0
        ) {
          console.log('Sheet not found! Pasting template for: ', tableNames[0])
          await this.pasteTable(
            this.templates.templateTables[tableNames[0]],
            tableNames[0]
          )
        }
      }).catch(err => {
        const errorPayload = {
          message:  err,
          color: 'error',
          icon: 'mdi-alert'
        }
        this.$root.snackbar.showSnackbar(errorPayload)
        if (tableNames == 'tickerInputs') this.cfi.uploadSheets[tableNames].reading = false
      })
      if (tableNames == 'tickerInputs') this.cfi.uploadSheets[tableNames].reading = false
    },
    async pasteTable(inputData, sheetName) {
      // inputData is a dict with key column and vlaue formula
      let paste = window.Excel.run(async (context) => {
        let sheets = context.workbook.worksheets
        sheets.load('items/name')
        await context.sync()
        let newSheet = sheetName
        let sheetnames = []
        let sheet = null
        sheets && sheets.items.forEach((item) => sheetnames.push(item.name))
        if (!sheetnames.includes(newSheet)) {
          sheet = sheets.add(newSheet)
        } else {
          sheet = sheets.getItem(newSheet)
          sheet.activate()
          return await context.sync()
        }
        // Paste Header Row
        let headers = Object.keys(inputData)
        let headCol = this.excel.getColumnLetter(headers.length)
        let headerRange = 'A1:' + headCol + '1'
        let range = sheet.getRange(headerRange)
        range.values = [headers]
        range.format.autofitColumns()
        // Paste First Formula Row
        let content = Object.values(inputData)
        let contentRange = 'A2:' + headCol + '2'
        range = sheet.getRange(contentRange)
        range.formulas = [content]
        range.format.autofitColumns()
        await context.sync()
        sheet.activate()
        return await context.sync()
      }).catch((error) => {
        console.log(error)
        const errorPayload = {
          message:  error,
          color: 'error',
          icon: 'mdi-alert'
        }
        this.$root.snackbar.showSnackbar(errorPayload)
      })
      return await paste
    },
    getJsDateFromExcel(excelDateValue) {
      return new Date((excelDateValue - (25567 + 2)) * 86400 * 1000)
    },
    deleteEntry() {
      switch (this.cfi.modal.selectedId) {
        case 1:
          this.cfi.removePartition(this.tables[1], 'latest')
          break
        case 2:
          this.cfi.removePartition(this.tables[2], 'latest')
          break
        case 3:
          this.cfi.removePartition(this.tables[0], 'latest')
          break
        default:
      }
    },
    async onReload() {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      this.sheets = []
      this.readSheets(this.updateTablesList)
      this.cfi && this.cfi.getTableEntries()
    }
  }
}
</script>
