class Templates {
    constructor() {
        // FONTS
        this.fonts = {
            header_font_light: { font: 'Arial', size: 14, bold: true, color: '#F6F6FA', wrapText: false },
            header_font_dark: { font: 'Arial', size: 14, bold: true, color: '#2E2E38', wrapText: false },

            subheader_font_light: { font: 'Arial', size: 10, bold: true, color: '#F6F6FA', wrapText: false },
            subheader_font_dark: { font: 'Arial', size: 10, bold: true, color: '#2E2E38', wrapText: false },

            info_font: { font: 'Arial Narrow', size: 10, bold: true, color: '#c4c4cd', wrapText: false },
        }

        // HEADER TEMPLATES
        this.datasheetHeader_dark = {
            header_row: 1,
            info_row_1: 4,
            info_row_2: 5,
            rows: [
                [''],
                ['HEADER'],
                ['Corporate Finance Insights'],
                [''],
                ['ID: ' + '', 'Date: ' +''],
                ['SCENARIO'],
            ],
            fonts: [
                {},
                this.fonts.header_font_light,
                this.fonts.subheader_font_light,
                {},
                this.fonts.info_font,
                this.fonts.info_font,
            ],
            background_color: '#2E2E38',
        }
        this.datasheetHeader_light = {
            header_row: 1,
            info_row_1: 4,
            info_row_2: 5,
            rows: [
                [''],
                ['HEADER'],
                ['Corporate Finance Insights'],
                [''],
                ['ID: ' + '', 'Date: ' +''],
                ['SCENARIO'],
            ],
            fonts: [
                {},
                this.fonts.header_font_dark,
                this.fonts.subheader_font_dark,
                {},
                this.fonts.info_font,
                this.fonts.info_font,
            ],
            background_color: '#F6F6FA',
        }

        // COLUMN FORMATING
        this.columnsToTrans = [
            {
              field: 'ticker',
              format: 'General',
              formula: {
                key: '',
              },
              givenName: 'Ticker'
            },
            {
              field: 'Company_name',
              format: 'General',
              formula: {
                key: 'ticker',
              },
              givenName: 'Company Name'
            },
            {
              field: 'Exchange',
              format: 'General',
              formula: '#exchange',
              givenName: 'Exchange'
            },
            {
              field: 'end_timewindow',
              format: 'yyyy-mm-dd',
              formula: '',
              givenName: 'Qualifying Date'
            },
            {
              field: 'beta_unlev',
              format: '#,##0.00',
              formula: '',
              givenName: 'Beta Unlevered'
            },
            {
              field: 'beta_lev',
              format: '#,##0.00',
              formula: '',
              givenName: 'Beta Levered'
            },
            { field: 'tax', format: '0.00%', formula: '', givenName: 'Tax Rate' },
            {
              field: 'avg_DE_ratio',
              format: '#,##0.00',
              formula: '',
              givenName: 'Debt/Equity Ratio'
            },
            {
              field: 'debt_beta',
              format: '#,##0.00',
              formula: '',
              givenName: 'Debt Beta'
            },
            { field: 'ss', format: '0', formula: '', givenName: 'Data Points' },
            {
              field: 't_stat_significant',
              format: 'General',
              formula: '',
              givenName: '99% Significance'
            },
            {
              field: 't_stat_significant_2',
              format: 'General',
              formula: '',
              givenName: '95% Significance'
            },
            {
              field: 'adjusted_beta',
              format: 'General',
              formula: '',
              givenName: 'Adjusted Beta'
            },
            {
              field: 'length_period_year',
              format: 'General',
              formula: '',
              givenName: 'Regression period in Years'
            },
            {
              field: 'frequency',
              format: 'General',
              formula: '',
              givenName: 'Frequency'
            },
            {
              field: 'indices',
              format: 'General',
              formula: '',
              givenName: 'Indices'
            },
            {
              field: 'yield',
              format: 'General',
              formula: '',
              givenName: 'Yield calculation'
            },
            {
              field: 'financial_services',
              format: 'General',
              formula: '',
              givenName: 'Financial Services included in Net Debt'
            },
            {
              field: 'pension',
              format: 'General',
              formula: '',
              givenName: 'Pensions Included in Net Debt'
            },
            {
              field: 'net_cash',
              format: 'General',
              formula: '',
              givenName: 'Allow Net Cash'
            },
            {
              field: 'risk_of_tax_shield',
              format: 'General',
              formula: '',
              givenName: 'Risk of Tax Shield'
            },
            {
              field: 'risk_of_debt_beta',
              format: 'General',
              formula: '',
              givenName: 'Risk of Debt Beta'
            }
        ]

      //Exchanges
      this.exchanges= {
        ADX: 'Abu Dhabi Securities Exchange',
        AIM: 'London Stock Exchange AIM Market',
        AMEX: 'American Stock Exchange',
        ARCA: 'NYSE Arca',
        ASE: 'Amman Stock Exchange',
        ASX: 'Australian Securities Exchange',
        ATSE: 'The Athens Stock Exchange',
        BARCLAYS: 'Barclays Capital',
        BASE: 'Buenos Aires Stock Exchange',
        BAX: 'Bahrain Stock Exchange',
        BAYB: 'Bayerische Boerse',
        BBSE: 'Berlin-Bremen Stock Exchange',
        BCSE: 'Barcelona Stock Exchange',
        BDB: 'Bourse de Beyrouth',
        BDL: 'Bourse de Luxembourg',
        BME: 'Bolsa de Madrid',
        BDV: 'Bolsa de Valencia',
        BELEX: 'Belgrade Stock Exchange',
        BER: 'The Bermuda Stock Exchange',
        BIT: 'Borsa Italiana',
        BITE: 'Borsa Italiana ETFplus',
        BLSE: 'Bilbao Stock Exchange',
        BMV: 'Bolsa Mexicana de Valores',
        BOIN: 'Bond Indices or OTC Bonds',
        BOVESPA: 'Bolsa de Valores de Sao Paulo',
        BRSE: 'Berne Stock Exchange',
        BRVM: 'Bourse Regionale des Valeurs Mobilieres',
        BSE: 'Mumbai Stock Exchange',
        BSM: 'Botswana Share Market',
        BSSE: 'Bratislava Stock Exchange',
        BST: 'Boerse-Stuttgart',
        BUL: 'Bulgaria Stock Exchange',
        BUSE: 'Budapest Stock Exchange',
        BVB: 'Bucharest Stock Exchange',
        BVC: 'Bolsa de Valores de Colombia',
        BVL: 'Bolsa de Valores de Lima',
        BVMT: 'Bourse des Valeurs Mobilieres',
        BVRJ: 'Bolsa de Valores de Rio de Janeiro',
        CASE: 'Cairo and Alexandria Stock Exchange',
        Catalist: 'Singapore Exchange Catalist Market',
        CATS: 'CATS',
        CBSE: 'Casablanca Stock Exchange',
        CCSE: 'Caracas Stock Exchange',
        CISE: 'Cayman Islands Stock Exchange',
        CISX: 'Channel Islands Stock Exchange',
        CM: 'Continuous Market',
        COSE: 'Colombo Stock Exchange',
        CPSE: 'Copenhagen Stock Exchange',
        CSE: 'Cyprus Stock Exchange',
        DB: 'Deutsche Boerse AG',
        DFM: 'Dubai Financial Market',
        DIFX: 'Dubai International Financial Exchange',
        DSE: 'Dhaka Stock Exchange Ltd.',
        DSM: 'Doha Securities Market',
        DUSE: 'Dusseldorf Stock Exchange',
        ENXTAM: 'Euronext Amsterdam',
        ENXTBR: 'Euronext Brussels',
        ENXTLS: 'Euronext Lisbon',
        ENXTPA: 'Euronext Paris',
        FKSE: 'Fukuoka Stock Exchange',
        GHSE: 'Ghana Stock Exchange',
        GTSM: 'GreTai Securities Market',
        GYSE: 'Guayaquil Stock Exchange',
        HASTC: 'Hanoi Securities Trading Center',
        HLSE: 'The Helsinki Stock Exchange',
        HMSE: 'Hamburg Stock Exchange',
        HNSE: 'Hannover Stock Exchange',
        HOSE: 'Ho Chi Minh Stock Exchange',
        IBSE: 'Istanbul Stock Exchange',
        ICSE: 'Iceland Stock Exchange',
        ISE: 'Irish Stock Exchange',
        JASDAQ: 'JASDAQ',
        JKSE: 'Jakarta Stock Exchange',
        JMSE: 'The Jamaica Stock Exchange',
        JSE: 'The Johannesburg Securities Exchange',
        KAS: 'Kazakhstan Stock Exchange',
        KASE: 'Karachi Stock Exchange',
        KHSE: 'Khartoum Stock Exchange',
        KLSE: 'Kuala Lumpur Stock Exchange',
        KOSE: 'Korea Stock Exchange',
        KWSE: 'Kuwait Stock Exchange',
        LJSE: 'Ljubljana Stock Exchange',
        LSE: 'London Stock Exchange',
        LUSE: 'Lusaka Stock Exchange',
        MISX: 'Moscow Exchange - All Markets',
        MOS: 'Moscow Central Stock Exchange',
        MSM: 'Muscat Securities Market',
        MTSE: 'Malta Stock Exchange',
        MUSE: 'The Stock Exchange of Mauritius Ltd.',
        NasdaqCM: 'Nasdaq Capital Market',
        NasdaqGM: 'Nasdaq Global Market',
        NasdaqGS: 'Nasdaq Global Select',
        NASE: 'Nairobi Stock Exchange',
        NBSE: 'Nablus Stock Exchange',
        NGM: 'Nordic Growth Market',
        NGSE: 'Nigerian Stock Exchange',
        NMSE: 'Namibian Stock Exchange',
        NSE: 'Nagoya Stock Exchange',
        NSEI: 'National Stock Exchange of India',
        NSEL: 'Vilnius Stock Exchange',
        NYSE: 'New York Stock Exchange',
        NZSE: 'New Zealand Stock Exchange',
        OB: 'Oslo Bors',
        OFEX: 'Off Exchange (London)',
        OM: 'OM Stockholm Exchange',
        OSE: 'Osaka Securities Exchange',
        OTCBB: 'OTC Bulletin Board',
        OTCMU: 'Mauritius OTC',
        OTCNO: 'Norway OTC',
        OTCPK: 'Pink Sheets LLC',
        OTCUS: 'Other "Grey Market" OTC',
        PSE: 'Philippines Stock Exchange',
        RASDAQ: 'RASDAQ',
        RISE: 'Riga Stock Exchange',
        RTS: 'RTS Stock Exchange',
        SASE: 'Saudi Arabian Stock Exchange',
        SEAQ: 'SEAQ International',
        SEHK: 'The Stock Exchange of Hong Kong Ltd.',
        SEP: 'The Stock Exchange Prague Co. Ltd.',
        SET: 'The Stock Exchange of Thailand',
        SGX: 'Singapore Exchange',
        SHSE: 'Shanghai Stock Exchange',
        SNSE: 'Santiago Stock Exchange',
        SOMA: 'Sociedade Operadora do Mercado de Ativos S A',
        SPSE: 'Sapporo Stock Exchange',
        SWX: 'The Swiss Exchange',
        SZSE: 'Shenzhen Stock Exchange',
        TASE: 'The Tel-Aviv Stock Exchange',
        TFX: 'Tokyo International Financial Futures Exchange (TIFFE)',
        THAMSE: 'Bangkok-Alien Market Stock Exchange',
        TLSE: 'Tallinn Stock Exchange',
        TSE: 'The Tokyo Stock Exchange',
        TSEC: 'Taiwan Stock Exchange',
        TSX: 'The Toronto Stock Exchange',
        TSXV: 'TSX Venture Exchange',
        TTSE: 'Trinidad & Tobago Stock Exchange',
        UKR: 'PFTS Ukraine Stock Exchange',
        VIRTX: 'SWX Europe Limited',
        WBAG: 'Wiener Boerse AG',
        WSE: 'Warsaw Stock Exchange',
        XTRA: 'XETRA Trading Platform',
        ZGSE: 'Zagreb Stock Exchange',
        ZMSE: 'Zimbabwe Stock Exchange'
      }

      // Beta & Multiples Template Tables
      this.templateTables = {
        tickerInputs: {
          ticker: 'Ticker:Exchange',
          set: 'YearMonth'
        },
        SupplementaryInfos: {
          Ticker_CapIQ: `=IFERROR(UPPER(RIGHT('tickerInputs'!A2,LEN('tickerInputs'!A2)-FIND(":",'tickerInputs'!A2)) &":" & LEFT('tickerInputs'!A2,FIND(":",'tickerInputs'!A2)-1)), "XTRA:BMW")`,
          ticker: `=IFERROR('tickerInputs'!A2, "BMW:XTRA")`,
          QuantSuite: `Insert 1 or 0`,
          ValSuite: `Insert 1 or 0`,
          Company: `=@CIQ(A2, "IQ_COMPANY_NAME")`,
          Country_of_Headquarter: `=@CIQ(A2, "IQ_COUNTRY_NAME")`,
          Company_Description: `=@CIQ(A2, "IQ_BUSINESS_DESCRIPTION")`,
          Industry: '=@CIQ(A2, "IQ_Industry")',
          Region: '=@CIQ(A2, "IQ_REGION")',
          Sector: '=@CIQ(A2, "IQ_INDUSTRY_SECTOR")',
          Classification: 'Capital IQ',
          FY_revenues: `=@CIQ(A2, "IQ_TOTAL_REV", IQ_FY, , , , "EUR", )`,
          'FY-1_revenues': `=@CIQ(A2, "IQ_TOTAL_REV", IQ_FY - 1, , , , "EUR", )`,
          FY_EBITDA: `=@CIQ(A2, "IQ_EBITDA", IQ_FY, , , , "EUR", )`,
          'FY-1_EBITDA': `=@CIQ(A2, "IQ_EBITDA", IQ_FY - 1, , , , "EUR", )`,
          FY_EBIT: `=@CIQ(A2, "IQ_EBIT", IQ_FY, , , , "EUR", )`,
          'FY-1_EBIT': `=@CIQ(A2, "IQ_EBIT", IQ_FY - 1, , , , "EUR", )`,
          FY_Market_Cap: `=@CIQ(A2, "IQ_MARKETCAP", , "EUR")`,
          FTE: `=@CIQ(A2, "IQ_EMPLOYEES")`,
          Free_float: `=@CIQ(A2, "IQ_FLOAT_PERCENT")`,
          Primary_Industry_Classification: `=@CIQ(A2, "IQ_PRIMARY_INDUSTRY")`,
          Segment_1: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 1)`,
          Segment_2: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 2)`,
          Segment_3: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 3)`,
          Segment_4: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 4)`,
          Segment_5: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 5)`,
          Segment_6: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 6)`,
          Segment_7: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 7)`,
          Segment_8: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 8)`,
          Segment_9: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 9)`,
          Segment_10: `=@CIQ(A2, "IQ_BUS_SEG_NAME", IQ_LTM, , , , , , 10)`,
          Segment_1_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 1)`,
          Segment_2_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 2)`,
          Segment_3_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 3)`,
          Segment_4_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 4)`,
          Segment_5_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 5)`,
          Segment_6_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 6)`,
          Segment_7_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 7)`,
          Segment_8_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 8)`,
          Segment_9_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 9)`,
          Segment_10_Revenue: `=@CIQ(A2, "IQ_BUS_SEG_REV", IQ_LTM, , , , "EUR", , 10)`,
          Segment_Geography_1: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 1)`,
          Segment_Geography_2: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 2)`,
          Segment_Geography_3: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 3)`,
          Segment_Geography_4: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 4)`,
          Segment_Geography_5: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 5)`,
          Segment_Geography_6: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 6)`,
          Segment_Geography_7: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 7)`,
          Segment_Geography_8: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 8)`,
          Segment_Geography_9: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 9)`,
          Segment_Geography_10: `=@CIQ(A2, "IQ_GEO_SEG_NAME", IQ_LTM, , , , , , 10)`,
          Segment_Geography_1_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 1)`,
          Segment_Geography_2_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 2)`,
          Segment_Geography_3_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 3)`,
          Segment_Geography_4_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 4)`,
          Segment_Geography_5_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 5)`,
          Segment_Geography_6_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 6)`,
          Segment_Geography_7_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 7)`,
          Segment_Geography_8_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 8)`,
          Segment_Geography_9_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 9)`,
          Segment_Geography_10_Revenue: `=@CIQ(A2, "IQ_GEO_SEG_REV", IQ_LTM, , , , "EUR", , 10)`,
          'S&P_Issuer_Rating': `=@CIQ(A2, "IQ_SP_ISSUER_RATING")`,
          PartitionKey: 'latest'
        },
        screening: {
          ScreeningFormula: `=@CIQSCREEN("IQS717786884","D","IQ_ALL_RESULTS")`,
          'ticker for inputs': `=UPPER(RIGHT(A2,LEN(A2)-FIND(":",A2)) &":" & LEFT(A2,FIND(":",A2)-1))`
        }
      },

      //Multiple steps 
      this.multipleStepDict = {
        Step1: '(1) Multiple KPI',
        Step2: '(2) Calculate Timeline',
        Step3: '(3) Calculate Multiple',
        ticke: '(0) Meta'
      },

      this.multipleStepTables = {
        Step1: 'Step1DownloadMultiplesKPIs',
        Step2: 'Step2CalculateTimeline',
        Step3: 'Step3CalculateMultiples'
      }

      //Beta steps
      this.betaStepDict = {
        Step1: '(1) Stock Prices',
        Step2: '(2) Levered Beta',
        Step3: '(3) DE Ratio KPIs',
        Step4: '(4) Credit Spread KPIs',
        Step5: '(5) DE Ratio',
        Step6: '(6) Debt Beta',
        Step7: '(7) Unlevered Beta',
        ticke: '(0) Meta'
      }

      this.betaStepTables = {
        Step1: 'Step1DownloadStockPrices',
        Step2: 'Step2CalculateLeveredBeta',
        Step3: 'Step3DownloadDEratioKPIs',
        Step4: 'Step4DownloadCreditSpreadKPIs',
        Step5: 'Step5CalculateDERatio',
        Step6: 'Step6CalculateDebtBeta',
        Step7: 'Step7CalculateUnleveredBeta'
      }

      this.betaRuns = {
        monthly: {
          valdates_span: 3,
          frequency: ['W', 'M'],
          performance: 'False',
          international: 'False',
          regression_horizon: [2, 5],
          regression_method: 'Discrete',
          adjusted_beta: ['True', 'False'],
          financial_services: ['True', 'False'],
          pensions: 'True',
          net_cash: 'False',
          risk_of_debt_beta: ['True', 'False'],
          risk_of_tax_shield: ['True', 'False'],
          index_by_headquarter: true,
          use_total_debt: ['True', 'False'],
          include_lease_liability: 'True',
        },
        esg: {
          valdates_span: 3,
          frequency: "W",
          performance: "False",
          international: "False",
          regression_horizon: 2,
          regression_method: "Discrete",
          adjusted_beta: "False",
          financial_services: "False",
          pensions: "True",
          net_cash: "True",
          risk_of_debt_beta: "False",
          risk_of_tax_shield: "True",
          index_by_headquarter: true,
          use_total_debt: 'False',
          include_lease_liability: 'False',
        }
      }

      this.multipleRuns = {
        monthly: {
          valdates_span: 3,
          frequency: "M",
          diameter: 3,
          financial_services: ["True", "False"],
          pensions: "True",
          net_cash: "True",
          minority_preferred: "True",
          calendarised: "True",
          use_total_debt: ['True', 'False'],
          include_lease_liability: 'True',
        },
        esg: {
          frequency: "W",
          valdates_span: 3,
          diameter: 1,
          financial_services: "False",
          pensions: "True",
          net_cash: "True",
          minority_preferred: "True",
          calendarised: "True",
          use_total_debt: 'False',
          include_lease_liability: 'False',
        }
      }
    }
}

export default Templates
