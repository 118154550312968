import Templates from '../functions/templates'
import ETP from '../functions/etp'
//import CFI from '../functions/cfi'
import BetaTemplates from './betatemplates'
import PasteCharts from './pasteCharts'

class Excel {
  constructor() {
    this.readingTable = false,
    this.alphabet = 'abcdefghijklmnopqrstuvwxyz'
    this.templates = new Templates()
    this.betatemplates = new BetaTemplates()
    this.etp = new ETP()
    this.pasteCharts = new PasteCharts()
    //this.cfi = new CFI()
  }

  colName(n) {
    let ordA = 'a'.charCodeAt(0)
    let ordZ = 'z'.charCodeAt(0)
    let len = ordZ - ordA + 1

    let s = ''
    while (n >= 0) {
      s = String.fromCharCode((n % len) + ordA) + s
      n = Math.floor(n / len) - 1
    }
    return s.toUpperCase()
  }

  async sheetExists(sheetName, snackbar) {
    let exists = false
    await window.Excel.run(async (context) => {
      let sheets = context.workbook.worksheets
      sheets.load('items/name')
      await context.sync()
      sheets &&
        sheets.items &&
        sheets.items.forEach((item) => {
          if (item.name == sheetName) {
            exists = true
          }
        })
    }).catch(err => {
      const errorPayload = {
        message:  err,
        color: 'error',
        icon: 'mdi-alert'
      }
      snackbar.showSnackbar(errorPayload)
    })
    return exists
  }

  async readTableData(range, sheetName, types = {}) {
    // types is a dict with key column name and value of Int or Float
    // String is default
    let rangeArray = this.decomposeRange(range)
    let data = []
    await window.Excel.run(async (context) => {
      let workSheet = context.workbook.worksheets.getItem(sheetName)
      await context.sync()
      // header range starts at A1
      let headerRange = workSheet
        .getRange('A1:' + rangeArray[2] + '1')
        .load('values')
        .load('text')
      let contentRange = workSheet.getRange(range).load('values').load('text')
      await context.sync()
      let header = Object.values(headerRange.text[0])
      contentRange.text.forEach((rowElement) => {
        let row = {}
        rowElement.forEach((colElement, colNum) => {
          if (Object.keys(types).includes(header[colNum])) {
            if (types[header[colNum]] == 'Int') {
              row[header[colNum]] = Number(colElement)
            } else if (types[header[colNum]] == 'Float') {
              row[header[colNum]] = parseFloat(colElement)
            }
          } else {
            row[header[colNum]] = String(colElement)
          }
        })
        data.push(row)
      })
    })
    return data
  }

  async getTableRange(sheetName) {
    let range = 'A1:A1'
    this.readingTable = true
    await window.Excel.run(async (context) => {
      let sheets = context.workbook.worksheets
      sheets.load('items/name')
      await context.sync()
      let workSheet = sheets.getItem(sheetName)
      let foundRows = true
      let foundCols = true
      let colNums = 0
      let colRead = 100
      let rowNums = 0
      let rowRead = 500
      await context.sync()
      // header range starts at A1
      while (foundCols) {
        let columns = workSheet
          .getRange('A1:' + this.colName(colRead) + '1')
          .load('values')
        await context.sync()
        while (
          columns.values[0][colNums] != '' &&
          columns.values[0][colNums] != undefined
        ) {
          colNums++
        }
        if (columns.values[0][colNums] === undefined) {
          colRead = colRead + 400
        } else {
          colNums--
          foundCols = false
        }
      }
      if (colNums < 0) {
        return 'A1:A1'
      }
      let rowsToSearch = workSheet
        .getRange('A1:' + this.colName(colNums) + String(rowRead))
        .load('values')
        .load('text')
      await context.sync()
      let rows = rowsToSearch.values
      while (foundRows) {
        // stops only when one entire row is missing --> every == ''
        while (
          rows[rowNums] != undefined &&
          !rows[rowNums].every((x) => x == '')
        ) {
          rowNums++
        }
        if (rows[rowNums] === undefined) {
          rowRead = rowRead + 400
          rows = Array(rows.length).fill(['Read'])
          rowsToSearch = workSheet
            .getRange(
              'A' +
                String(rowRead - 399) +
                ':' +
                this.colName(colNums) +
                String(rowRead)
            )
            .load('values')
            .load('text')
          await context.sync()
          rows = rows.concat(rowsToSearch.values)
        } else {
          // no rowNums-- since excel does not count from 0
          foundRows = false
        }
      }
      range = `A1:${this.colName(colNums)}${rowNums}`
    })
    this.readingTable = true
    return range
  }

  deleteEntry(entry, props) {
    for (const item of props) {
      item in entry && delete entry[item]
    }
  }

  async pasteSheets(buffer, headerTemplate, modal, snackbar) {
    // PAYLOAD SIZE LIMIT INFO
    // excel web:     payload size limit for requests and responses of 5MB
    // all platforms: range is limited to five million cells for get operations

    // delete sheets first 
    //await this.deleteSheets() // This is too general and dangerous for production!
    
    // loop through sheets
    for(let i = 0; i < buffer.length; i++){
      let sheet = buffer[i]

      // insert data into the header template
      let header = {}
      Object.assign(header, headerTemplate)
      header.rows = []
      for(let j = 0; j < headerTemplate.rows.length; j++){
        header.rows[j] = headerTemplate.rows[j]
      }
      header.rows[header.header_row] = [sheet.metadata.sheetname]
      header.rows[header.info_row_1] = (sheet.metadata.id && sheet.metadata.date && ['ID: ' + sheet.metadata.id, 'Date: ' + sheet.metadata.date]) || ['']
      header.rows[header.info_row_2] = sheet.metadata.scenario || ['']

      await this.pasteSheet(buffer, i, header, this.templates.columnsToTrans, snackbar)
    }

    if(modal.isBetaPipeline && modal.cbs.betas.templates.beta)
      await this.pasteTemplate(this.etp.process(this, this.betatemplates.beta_template_1, buffer, headerTemplate.rows.length), buffer[0].metadata.id)

    if(modal.isBetaPipeline && modal.cbs.betas.templates.chart)
      await this.pasteCharts.paste('betas', buffer)

    if(modal.isMultiplesPipeline && modal.cbs.multiples.templates.chart)
      await this.pasteCharts.paste('multiples', buffer)
  }

  async deleteSheets(){
    await window.Excel.run(async (context) => {
      console.log("delete sheets")
      const sheets = context.workbook.worksheets
      let sheetCountObj = sheets.getCount(true)
      await context.sync()
      let sheetCount = sheetCountObj.m_value

      while(sheetCount > 1){
        const lastSheet = sheets.getLast()
        lastSheet.delete()
        sheetCountObj = sheets.getCount(true)
        await context.sync()
        sheetCount = sheetCountObj.m_value
      }
      
      await context.sync()
    })
  }

  async pasteSheet(buffer, thisSheetIndex, header, ctt, snackbar){
    let sheet = buffer[thisSheetIndex]

    // REORDER TABLES --> move to CFI.js
    //sheet = this.reorderTables(buffer, sheet, thisSheetIndex, header, ctt, this)
    
    await window.Excel.run(async (context) => {
      // PASTE DATA
      // options
      const pasteAsTable = false
      const headerHeight = header.rows.length

      // create new worksheet
      const sheetNameID = sheet.metadata.sheetname + ' ' + (sheet.metadata.id || '')
      context.workbook.worksheets.add(sheetNameID.substring(0,30))
      let workSheet = context.workbook.worksheets.getItem(sheetNameID.substring(0,30))

      // define ranges
      let xRange = this.getColumnLetter(sheet.data[0][0].length)
      let yRangeUsed = headerHeight // only needed if not pasted as table

      let table = ""
      let tableRows = 0
      if(pasteAsTable){
        // create table
        table = workSheet.tables.add("A" + (headerHeight + 1) + ":" + xRange + (headerHeight + 1))
        table.name = sheet.metadata.sheetname.replaceAll(' ', '').substring(3)
        table.load('tableStyle')
        table.style = 'TableStyleMedium1'

        table.getHeaderRowRange().values = sheet.data[0]
      }else{
        // paste head row
        let range = workSheet.getRange("A" + (headerHeight + 1) + ":" + xRange + (headerHeight + 1))
        range.values = sheet.data[0]
        yRangeUsed += 1
      }
      await context.sync()

      // paste each chunk
      for(let j = 1; j < sheet.data.length; j++){
        let chunk = sheet.data[j]
        if(pasteAsTable){ 
          table.rows.add(null /*add rows to the end of the table*/, chunk)
          tableRows += chunk.length
        }
        else{
          let yRange = chunk.length
          let range = workSheet.getRange("A" + (yRangeUsed + 1) + ":" + xRange + (yRangeUsed + yRange))
          range.values = chunk
          yRangeUsed += yRange
        }
        await context.sync()
      }

      // FORMAT COLUMN DATA
      for(let i = 0; i < sheet.data[0][0].length; i++){
        for(let j = 0; j < ctt.length; j++){
          if(sheet.data[0][0][i] == ctt[j].field){
            let head = workSheet.getRange(this.getColumnLetter(i + 1) + (headerHeight + 1))
            head.values = [[ctt[j].givenName]]

            let lastRow = 0
            if(pasteAsTable){ lastRow = headerHeight + 2 + tableRows }
            else{ lastRow = yRangeUsed }

            let column = workSheet.getRange(this.getColumnLetter(i + 1) + (headerHeight + 2) + ":" + this.getColumnLetter(i + 1) + (lastRow))
            let formats = []
            for(let y = headerHeight + 2; y <= lastRow; y++){ formats.push([ctt[j].format]) }
            column.numberFormat = formats
          }
        }
      }

      // auto fit columns
      const usedRange = workSheet.getUsedRange()
      usedRange.format.autofitColumns()
      await context.sync()

      // paste header
      for (let r = 0; r < header.rows.length; r++) {
        let rowRange =
          'A' +
          String(r + 1) +
          ':' +
          this.alphabet[header.rows[r].length - 1].toUpperCase() +
          String(r + 1)
        let range = workSheet.getRange(rowRange)
        range.values = [header.rows[r]]

        if(header.fonts[r]){
          let font = header.fonts[r] 
          if(Object.keys(font).length != 0){
            range.format.font.name = font.font
            range.format.font.size = font.size
            range.format.font.bold = font.bold
            range.format.font.color = font.color
            range.format.wrapText = font.wrapText
          }
        }
        
        rowRange = String(r + 1) + ':' + String(r + 1)
        range = workSheet.getRange(rowRange)
        range.format.fill.color = header.background_color
      }
      await context.sync()
    }).catch(err => {
      console.log(err)
      const errorPayload = {
        message:  err,
        color: 'error',
        icon: 'mdi-alert'
      }
      if(snackbar){ snackbar.showSnackbar(errorPayload) }
    })
  }

  async sheetToArray(sheetName){
    await window.Excel.run(async (context) => {
      let sheet = context.workbook.worksheets.getItem(sheetName)
      sheet.load(['showGridlines', 'tabColor'])
      await context.sync()
      let sheetData = {
          showGridlines: sheet.showGridlines,
          tabColor: sheet.tabColor,
          cells: [],
          columns: [],
          rows: []
      }

      let usedRange = sheet.getUsedRange()
      usedRange.load(['rowCount', 'columnCount',])
      await context.sync()

      for(let i = 0; i < usedRange.rowCount; i++){
        sheetData.cells.push([])

        // store row data
        let rowNumber = i + 1
        let row = sheet.getRange(rowNumber + ':' + rowNumber)
        row.load(['address', 'height'])
        await context.sync()
        sheetData.rows.push(row.height * 1.666) // height is given in pixels / 1.666

        for(let j = 0; j < usedRange.columnCount; j++){

          // store column data
          if(i == 0){
            let columnLetter = this.getColumnLetter(j + 1)
            let column = sheet.getRange(columnLetter + ':' + columnLetter)
            column.load(['address', 'width'])
            await context.sync()
            sheetData.columns.push(column.width * 1.666 ) // width is given in pixels / 1.666
          }

          let cell = usedRange.getCell(i, j)
          cell.load([ // content
                      'formulas', 
                      // format
                      'format/autoIndent',
                      'format/borders',
                      'format/fill',
                      'format/font',
                      'format/horizontalAlignment',
                      'format/indentLevel',
                      'format/protection',
                      'format/readingOrder',
                      'format/shrinkToFit',
                      'format/textOrientation',
                      'format/useStandardHeight',
                      'format/useStandardWidth',
                      'format/verticalAlignment',
                      'format/wrapText',
                      'numberFormat',
                      'dataValidation'
                    ])
          await context.sync()

          let cellData = {
            formula: cell.formulas[0][0],
            format: {
              autoIndent: cell.format.autoIndent,
              borders: cell.format.borders,
              fill: cell.format.fill,
              font: cell.format.font,
              horizontalAlignment: cell.format.horizontalAlignment,
              indentLevel: cell.format.indentLevel,
              protection: cell.format.protection,
              readingOrder: cell.format.readingOrder,
              shrinkToFit: cell.format.shrinkToFit,
              textOrientation: cell.format.textOrientation,
              useStandardHeight: cell.format.useStandardHeight,
              useStandardWidth: cell.format.useStandardWidth,
              verticalAlignment: cell.format.verticalAlignment,
              wrapText: cell.format.wrapText,
            },
            numberFormat: cell.numberFormat,
            dataValidation: cell.dataValidation
          }
          sheetData.cells[i].push(cellData)
        }
      }
      await context.sync()
      //console.log(sheetData)
      return await sheetData
    })
  }

  async pasteTemplate(sheetData, id){
    await window.Excel.run(async (context) => {
      let array = sheetData.cells
      let sheet = context.workbook.worksheets.add('BETAS ' + id)
      sheet.position = 1

      // PASTE SHEET DATA
      sheet.showGridlines = sheetData.showGridlines
      sheet.tabColor = sheetData.tabColor

      // load the cell borders before looping through cells for speedup
      let cellBordersArray = []
      for(let i = 0; i < array.length; i++){
        cellBordersArray.push([])
        for(let j = 0; j < array[i].length; j++){
          let cellAddress = this.getColumnLetter(j+1) + (i+1) + ':' + this.getColumnLetter(j+1) + (i+1)
          let cell = sheet.getRange(cellAddress)
          cell.load(['format/borders/items'])
          cellBordersArray[i].push(cell)
        }
      }
      await context.sync()

      for(let i = 0; i < array.length; i++){
        for(let j = 0; j < array[i].length; j++){
          // VALUES
            let cellAddress = this.getColumnLetter(j+1) + (i+1) + ':' + this.getColumnLetter(j+1) + (i+1)
            let cell = sheet.getRange(cellAddress)
            cell.values = [[array[i][j].formula]]

          // FORMAT
            // borders
            if(array[i][j].format.borders){
              for(let k = 0; k < array[i][j].format.borders.items.length; k++){
                if(array[i][j].format.borders.items[k].style != 'None'){
                  // set style after weight and color, otherwise a border will always be drawn
                  cellBordersArray[i][j].format.borders.items[k].color = array[i][j].format.borders.items[k].color
                  cellBordersArray[i][j].format.borders.items[k].weight = array[i][j].format.borders.items[k].weight
                  cellBordersArray[i][j].format.borders.items[k].tintAndShade = array[i][j].format.borders.items[k].tintAndShade
                  cellBordersArray[i][j].format.borders.items[k].style = array[i][j].format.borders.items[k].style 
                }
              }
            }
            
            // fill
            if(array[i][j].format.fill){
              if(array[i][j].format.fill.color != '#FFFFFF'){
                cell.format.fill.color = array[i][j].format.fill.color
              }
              if(array[i][j].format.fill.pattern){
                cell.format.fill.pattern = array[i][j].format.fill.pattern
                cell.format.fill.patternColor = array[i][j].format.fill.patternColor
                cell.format.fill.patternTintAndShade = array[i][j].format.fill.patternTintAndShade
              }
              cell.format.fill.tintAndShade = array[i][j].format.fill.tintAndShade
            }

            // font
            if(array[i][j].format.font){
              if(array[i][j].formula){
                cell.format.font.bold = array[i][j].format.font.bold
                cell.format.font.color = array[i][j].format.font.color
                cell.format.font.italic = array[i][j].format.font.italic
                cell.format.font.name = array[i][j].format.font.name
                cell.format.font.size = array[i][j].format.font.size
                cell.format.font.strikethrough = array[i][j].format.font.strikethrough
                cell.format.font.subscript = array[i][j].format.font.subscript
                cell.format.font.superscript = array[i][j].format.font.superscript
                cell.format.font.tintAndShade = array[i][j].format.font.tintAndShade
                cell.format.font.underline = array[i][j].format.font.underline
              }
            }
            cell.format.horizontalAlignment = array[i][j].format.horizontalAlignment ? array[i][j].format.horizontalAlignment : 'General'
            cell.format.verticalAlignment = array[i][j].format.verticalAlignment ? array[i][j].format.verticalAlignment : 'Bottom'
            cell.format.wrapText = array[i][j].format.wrapText ? array[i][j].format.wrapText : false
            cell.numberFormat = array[i][j].numberFormat ? array[i][j].numberFormat : 'General'

            // data validation
            //console.log(array[i][j].dataValidation)
            cell.dataValidation.rule = array[i][j].dataValidation ? array[i][j].dataValidation.rule : null
            // let listRule = {
            //   list: {
            //     inCellDropDown: true,
            //     source: '=Sheet1!$C$1:$C$5'
            //   }
            // }
            // cell.dataValidation.rule = listRule


          //   dont paste unused stuff for better performance

          //   //protection
          //   cell.format.protection.formulaHidden = array[i][j].format.protection.formulaHidden
          //   cell.format.protection.locked = array[i][j].format.protection.locked

          //   cell.format.autoIndent = array[i][j].format.autoIndent
             cell.format.indentLevel = array[i][j].format.indentLevel
          //   cell.format.readingOrder = array[i][j].format.readingOrder
          //   cell.format.shrinkToFit = array[i][j].format.shrinkToFit
          //   cell.format.textOrientation = array[i][j].format.textOrientation
          //   cell.format.useStandardHeight = array[i][j].format.useStandardHeight
          //   cell.format.useStandardWidth = array[i][j].format.useStandardWidth
        }
      }
      //let usedRange = sheet.getUsedRange()
      //usedRange.format.autofitColumns()
      await context.sync()

      for(let c = 0; c < sheetData.columns.length; c++){
        let letter = this.getColumnLetter(c + 1)
        let column = sheet.getRange(letter + ':' + letter)
        column.format.columnWidth = sheetData.columns[c] / 1.666
      }
      for(let r = 0; r < sheetData.rows.length; r++){
        let row = sheet.getRange((r + 1) + ':' + (r + 1))
        row.format.rowHeight = sheetData.rows[r] / 1.666
      }
      await context.sync()
    })
  }

  getColumnLetter(colNum) {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')
    let rounds = 0
    let position = colNum
    while (position > 26) {
      position = position - 26
      rounds = rounds + 1
    }
    let letter = ''
    if (rounds) {
      letter = alphabet[rounds - 1] + alphabet[position - 1]
    } else {
      letter = alphabet[position - 1]
    }
    return letter
  }

  getRowNumer(chunk, row, workSheet){
    let rowNumber = 0
    for(let chunkIndex = 0; chunkIndex <= chunk; chunkIndex++){
      if(chunkIndex == chunk){ rowNumber += row }
      else{ rowNumber += workSheet[chunkIndex].length }
    }
    return rowNumber
  }

  getRowCount(sheet){
    let rowCount = 0
    for(let chunkIndex = 0; chunkIndex < sheet.length; chunkIndex++){
      rowCount += sheet[chunkIndex].length
    }
    return rowCount
  }

  getTickerList(tickers) {
    let tickerlist = []
    Object.values(tickers).forEach((ticker) => {
      let exchanges = Object.keys(this.templates.exchanges).map((a) => a.toUpperCase())
      if (
        exchanges.includes(ticker.split(':')[1].toUpperCase()) &&
        !exchanges.includes(ticker.split(':')[0].toUpperCase())
      ) {
        tickerlist.push(ticker.toUpperCase())
      } else {
        tickerlist.push(
          ticker.split(':')[1].toUpperCase() +
            ':' +
            ticker.split(':')[0].toUpperCase()
        )
      }
    })
    return tickerlist
  }

  reorderTables(buffer, sheet, thisSheetIndex, header, ctt, excel){
    // PART 1: ROWS
    // put each ticker into a seperate chunk
    if(thisSheetIndex == 7){
      let col = 0
      let dataOrdered2 = [[sheet.data[0][0]]]
      for(let i = 1; i < sheet.data.length; i++){
        for(let j = 0; j < sheet.data[i].length; j++){
          let found = false
          for(let k = 0; k < dataOrdered2.length; k++){
            if(dataOrdered2[k][0][col] == sheet.data[i][j][col]){
              found = true
              dataOrdered2[k].push(sheet.data[i][j])
            }
          }
          if(!found){
            dataOrdered2.push([sheet.data[i][j]])
          }
        }
      }
      sheet.data = dataOrdered2
    }

    // PART 2: COLUMNS
    // options
    const includeColumnsNotInCTT = true
    const addNewColumnsFromCTT = true
  
    // find meta sheet
    let metaSheet
    for(let i = 0; i < buffer.length; i++){
      if(String(buffer[i].metadata.sheetname).includes('Meta')){
        metaSheet = buffer[i]
      }
    }
    if(thisSheetIndex != 0){
      // generate dataOrdered array
      let dataOrdered = new Array(sheet.data.length)
      for(let i = 0; i < dataOrdered.length; i++){
        dataOrdered[i] = new Array(sheet.data[i].length)
        for(let j = 0; j < dataOrdered[i].length; j++){
          dataOrdered[i][j] = new Array(ctt.length)
        }
      }
      // reorder the columns and generate reorderMap
      for(let i = 0; i < sheet.data[0][0].length; i++){
        let headerIsInCTT = false
        for(let j = 0; j < ctt.length; j++){
          if(sheet.data[0][0][i] == ctt[j].field){
            headerIsInCTT = true
            for(let chunkIndex = 0; chunkIndex < dataOrdered.length; chunkIndex++){
              for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
                dataOrdered[chunkIndex][rowIndex][j] = sheet.data[chunkIndex][rowIndex][i]
              }
            }
          }
        }
        // append columns not found in CTT to the end
        if(!headerIsInCTT && includeColumnsNotInCTT){ 
          for(let chunkIndex = 0; chunkIndex < dataOrdered.length; chunkIndex++){
            for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
              dataOrdered[chunkIndex][rowIndex].push(sheet.data[chunkIndex][rowIndex][i])
            }
          }
        }
      }
      // delete empty spots or insert new columns from ctt
      for(let i = dataOrdered[0][0].length - 1; i >= 0; i--){
        if(dataOrdered[0][0][i] == null){
          let refSheet = metaSheet.metadata.sheetname + ' ' + metaSheet.metadata.id
          let column = excel.getColumnLetter(i+1)
          // find key column (key columns in current workSheet and reference workSheet are equal thanks to ctt)
          let keyColumn
          if(ctt[i].formula.key){
            for(let j = 0; j < ctt.length; j++){
              if(ctt[j].field == ctt[i].formula.key){
                keyColumn = excel.getColumnLetter(j+1)
              }
            }
          }
          let refSheetLastColumn = excel.getColumnLetter(metaSheet.data[0][0].length)
          for(let chunkIndex = 0; chunkIndex < dataOrdered.length; chunkIndex++){
            for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
              if(addNewColumnsFromCTT && i < ctt.length && ctt[i].formula.key){
                dataOrdered[0][0][i] = ctt[i].field
                let row = header.rows.length + 1 + excel.getRowNumer(chunkIndex, rowIndex, sheet.data)
                let f1 = `=INDEX('${refSheet}'!A${header.rows.length+1}:${refSheetLastColumn}${header.rows.length+1+excel.getRowCount(buffer[0].data)-1}, `
                let f2 =  `MATCH(${keyColumn}${row},'${refSheet}'!${keyColumn}${header.rows.length+1}:${keyColumn}${header.rows.length+1+excel.getRowCount(buffer[0].data)-1},0), `
                let f3 =  `MATCH(${column}${header.rows.length+1},'${refSheet}'!A${header.rows.length+1}:${refSheetLastColumn}${header.rows.length+1},0))`
                dataOrdered[chunkIndex][rowIndex][i] = f1 + '\n' + f2 + '\n' + f3
              }else{
                // delete column
                dataOrdered[chunkIndex][rowIndex].splice(i, 1)
              }
            }
          }
        }
      }
      sheet.data = dataOrdered
    }

    return sheet
  }
}

export default Excel

