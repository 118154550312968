<template>
  <v-card tile>
    <v-row class="mt-0 pt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Track Pipeline
        </v-card-title>
        <v-card-subtitle class="mb-n4"> Admin </v-card-subtitle>
      </v-col>
      <v-col cols="3" class="mt-2">
        <v-btn v-if="hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn v-if="!hidden" icon small @click="updateOnReload()">
          <v-icon small> mdi-reload </v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="jobs.progress" cols="12" class="px-4">
        <v-progress-linear v-if="!jobs.loadingTablesDeletion" indeterminate color="yellow accent-4" height="4" />
        <v-progress-linear v-if="jobs.loadingTablesDeletion" :value="Math.ceil(jobs.loadingTablesDeletion/10)" color="yellow accent-4" height="4" />
      </v-col>
    </v-row>
    <v-card-text
      v-if="!openOverlay && !openJobMenu && !openSubJobs && !openJobCleanup"
      class="px-0"
      :hidden="hidden"
    >
      <self-tracking
        @on-update-overlay="openOverlay = $event"
        :jobs="jobs"
        :openOverlay="openOverlay"
      /> 
      <all-tracking
        @on-update-userId="updateUserId"
        v-model="openSubJobs"
        @on-update-jobcleanup="openJobCleanup = $event"
        :jobs="jobs"
      />  
    </v-card-text>
    <v-card-text v-if="openOverlay" :hidden="hidden">
      <pipeline-detail
        v-model="openOverlay"
        :excel="excel"
        :cfi="cfi"
        :jobs="jobs"
        :templates="templates"
      />
    </v-card-text>
    <v-card-text v-if="openJobMenu" :hidden="hidden">
      <job-menu
        v-model="openJobMenu"
        @on-update-overlay="openOverlay = $event" 
        :trackingFocus="trackingFocus"
        :openOverlay="openOverlay"
        :jobs="jobs"
      />
    </v-card-text>
    <v-card-text v-if="openSubJobs" :hidden="hidden">
      <job-sub-menu
        v-model="openSubJobs"
        :jobs="jobs"
        :excel="excel"
        :cfi="cfi"
        :templates="templates"
      />
    </v-card-text>
    <v-card-text v-if="openJobCleanup" :hidden="hidden">
      <job-cleanup
        v-model="openJobCleanup"
        :jobs="jobs"
        :excel="excel"
        :cfi="cfi"
        :templates="templates"
      />
    </v-card-text>
    <div :hidden="hidden">
      <v-card-actions class="mx-2">
        <v-row class="mb-1">
          <v-col cols="9" xs="9" />
          <v-col cols="3" xs="3">
            <v-btn v-if="!hidden" icon @click="hidden = !hidden">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { msalMixin } from 'vue-msal'
import AllTracking from './AllTracking.vue'
import SelfTracking from './SelfTracking.vue'
import PipelineDetails from './PipelineDetails/index.vue'
import JobMenu from './JobMenu.vue'
import JobSubMenu from './JobSubMenu.vue'
import Jobs from './../../functions/jobs'
import Excel from '../../functions/excel'
import JobCleanup from './JobCleanup.vue'
import Templates from '../../functions/templates'
import CFI from '../../functions/cfi'

export default {
  name: 'JobTracking',
  mixins: [msalMixin],
  components: {
    allTracking: AllTracking,
    selfTracking: SelfTracking,
    pipelineDetail: PipelineDetails,
    jobMenu: JobMenu,
    jobSubMenu: JobSubMenu,
    jobCleanup: JobCleanup
  },
  data: () => ({
    jobs: new Jobs(),
    excel: new Excel(),
    cfi: new CFI(),
    templates: new Templates(),
    hidden: true,
    openOverlay: false,
    openJobMenu: false,
    openSubJobs: false,
    trackingFocus: '',
    openJobCleanup: false,
  }),
  async mounted() {
    if (!this.hidden) {
      this.jobs.getMyPipelines()
      this.jobs.getPipelines()
    }
    await this.$msal.acquireToken()
    this.jobs.token = this.$msal.data.accessToken
    this.jobs.profileMail = this.$msal.data.graph.profile && this.$msal.data.graph.profile.mail
  },
  methods: {
    updateUserId(props) {
      this.jobs.getUserPipelines(props.trackingFocus)
      this.openJobMenu = props.openJobMenu
      this.trackingFocus = props.trackingFocus
    },
    async updateOnReload() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.jobs.update(this.openOverlay)
    }
  }
}
</script>
