<template>
  <div>
    <v-row class="mt-0 pt-0">
      <v-col cols="2" class="mt-0 pt-0">
        <v-btn icon small @click="openJobMenuLocal = !openJobMenu">
          <v-icon small> mdi-arrow-left-thick </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10" class="mt-0 pt-0" />
    </v-row>
    <v-list dense>
      <v-list-item v-for="(job, i) in jobs.tracking[trackingFocus].slice(jobpage * 10 - 10, jobpage * 10)" :key="i" link>
        <!-- using v-if=job.caption will give the correct result for a second, then show an error, but WHY?! -->
        <v-list-item-content v-if=true @click="getPipeline(job.caption)">
          {{ helperFunction(job) }}
          <v-list-item-title class="text-caption">
            {{ job.function.replace('Pipeline.run(', '').replace(')', '') }} 
            <span class="text-caption">{{ job.caption }}</span>
          </v-list-item-title>
          <v-list-item-subtitle v-if="job.progress < 1" width="90vw">
            <v-progress-linear :value="job.progress * 100" />
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ new Date(job.last_updated + 'Z').toLocaleString('de-DE') }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <!-- <v-list-item-content v-else>
          <v-list-item-title>
            {{ job.message }}
          </v-list-item-title>
        </v-list-item-content> -->
        <v-list-item-icon v-if="job.pipeline_job">
          <v-btn
            small
            icon
            @click="
             modal.isVisible = 1
             selectedTableId = job.pipeline_job.caption
            ">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </v-list-item-icon>
        <confirmation-modal
          @close="modal.isVisible = 0"
          @delete="modal.isVisible = 0; deleteJob()"
          @deleteAll="modal.isVisible = 0; deleteAllJob()"
          :modal="modal"
        />
      </v-list-item>
      <pagination
        :isPagination="jobs.tracking[trackingFocus].length > 10"
        :noOfPages="Math.ceil(jobs.tracking[trackingFocus].length / 10)"
        v-model="jobpage"
      />
    </v-list>
  </div>
</template>

<script>
import ConfirmationModal from '../Common/ConfirmationModal.vue'
import Pagination from './../Common/Pagination.vue'

export default {
  name: 'JobMenu',
  selectedTableId: '',
  data: () => ({
    jobpage: 1,
    modal: {
      isVisible: 0,
      heading: 'Confirm Delete',
      content: 'Please confirm if you want to proceed with delete functionality',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Delete', event: 'delete', color: 'error' },
        { name: 'Delete All', event: 'deleteAll', color: 'error' }
      ]
    }
  }),
  components: {
    confirmationModal: ConfirmationModal,
    pagination: Pagination
  },
  props: {
    openOverlay:{
      type: Boolean,
      required: true
    },
    openJobMenu: {
      type: Boolean,
      required: true
    },
    jobs: {
      type: Object,
      required: true
    },
    trackingFocus: {
      type: String,
      required: true
    }
  },
  model: {
      prop: 'openJobMenu',
      event: 'openJobMenuChange'
  },
  computed: {
    openJobMenuLocal: {
      get: function() {
          return this.openJobMenu
      },
      set: function(value) {
          this.$emit('openJobMenuChange', value)
      }
    }
  },
  methods: {
    async getPipeline(tableId) {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.$emit('on-update-overlay', true)
      this.openJobMenuLocal = !this.openJobMenu
      this.jobs.pipelineFocus= {...this.jobs.pipelineFocus, name: tableId}
      this.jobs.getPipelineInfo(this.jobs.pipelineFocus.name)
    },
    async deleteJob() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.jobs.deleteJob(this.selectedTableId, this.openOverlay)
    },
    async deleteAllJob() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.jobs.deleteAllJob(this.selectedTableId, this.openOverlay)
    },
    helperFunction(s){
      console.log(s)
    }
  }
}
</script>
