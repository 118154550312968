<template>
  <v-pagination
    v-if="isPagination"
    v-model="jobpageLocal"
    :length="noOfPages"
    class="custom"
  />
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    isPagination: {
      type: Boolean,
      required: true
    },
    noOfPages: {
      type: Number,
      required: true
    },
    jobpage: {
      type: Number,
      required: true
    }
  },
  model: {
      prop: 'jobpage',
      event: 'jobpageChange'
  },
  computed: {
    jobpageLocal: {
      get: function() {
          return this.jobpage
      },
      set: function(value) {
          this.$emit('jobpageChange', value)
      }
    },
  }
}
</script>
<style>
  .custom {
    width: auto;
    margin-left: auto;
  }

  .custom .v-pagination__navigation {
    height: 26px !important;
    width: 26px !important;
  }

  .custom .v-pagination__navigation .v-icon {
    font-size: 16px !important;
  }

  .custom .v-pagination__item {
    height: 26px !important;
    min-width: 26px !important;
    font-size: 0.85rem !important;
  }
</style>