<template>
  <div>
    <v-list-item three-line>
      <v-list-item-content>
        <v-menu
          offset-y
          open-on-hover
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-title>4. KPI Data / SupplementaryInfos
              <v-icon class="pl-1" small v-on="on" v-bind="attrs" style="vertical-align: middle">
                mdi-information
              </v-icon>
            </v-list-item-title>
          </template>
          <div class="px-2 py-2 offwhite">
            <v-list-item-subtitle>Read SupplementaryInfos and upload</v-list-item-subtitle>
            <v-list-item-subtitle>Creates a template if not found</v-list-item-subtitle>
            <v-list-item-subtitle>
              Click <v-icon x-small> mdi-cloud-upload </v-icon> to upload
            </v-list-item-subtitle>
          </div>
        </v-menu>
        <v-list-item-subtitle>
          DB: {{cfi.uploadSheets.SupplementaryInfos.len}}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="cfi.uploadSheets.SupplementaryInfos.range">
          Range {{ cfi.uploadSheets.SupplementaryInfos.range }}
        </v-list-item-subtitle>
        <v-progress-linear
          v-if="cfi.uploadSheets.SupplementaryInfos.reading"
          size="25"
          color="yellow"
          :indeterminate="cfi.uploadSheets.SupplementaryInfos.reading"
        />
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn color="offblack" icon @click="cfi.openModal(2)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn color="offblack" icon @click="readTables(kpiTables[0])">
          <v-icon>mdi-plus-box</v-icon>
        </v-btn>
      </v-list-item-icon>
      <v-list-item-icon>
        <v-btn
          v-if="cfi.loaderId !== kpiTables[0]"
          icon
          color="offblack"
          :disabled="cfi.uploadSheets.SupplementaryInfos.range == ''"
          @click="uploadTable()"
        >
          <!-- uploadData(sheet.name, sheet.excel) -->
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-progress-circular
          v-else
          size="25"
          color="yellow"
          :value="cfi.uploadProgress"
        />
      </v-list-item-icon>
    </v-list-item>
    <error-message :errorDetails="cfi.errorDetails[kpiTables[0]]" :info="cfi.loaderId !== kpiTables[0]" />
  </div>
</template>

<script>
import ErrorMessage from './../ErrorMessage.vue'

export default {
  name: 'KPIData',
  components: {
    errorMessage: ErrorMessage
  },
  props: {
    cfi: {
      type: Object,
      required: true
    },
    readTables: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    kpiTables: ['SupplementaryInfos'],
  }),
  methods: {
    async uploadTable() {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      this.cfi.uploadTable(
        this.cfi.uploadSheets.SupplementaryInfos.range,
        this.kpiTables[0],
        200
      )
    }
  }
}
</script>