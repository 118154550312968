// excel template processing
class ETP {
    constructor() {
        this.excel
        this.buffer = {}
        this.headerHeight
        this.processedTemplate
      }

    process(excel, template, buffer, headerHeight){
        this.excel = excel
        this.buffer = buffer
        this.headerHeight = headerHeight

        //console.log('PROCESSING RESULTS:')
        this.processedTemplate = {}
        //console.log(template)
        this.processedTemplate.cells = new Array(template.cells.length)
        for(let i = 0; i < template.cells.length; i++){
          this.processedTemplate.cells[i] = new Array(template.cells[i].length)
        }
        this.processedTemplate.columns = template.columns
        this.processedTemplate.rows = template.rows
        this.processedTemplate.showGridlines = template.showGridlines
        this.processedTemplate.tabColor = template.tabColor

        for(let i = 0; i < template.cells.length; i++){
            for(let j = 0; j < template.cells[i].length; j++){
                let cell = template.cells[i][j]
                this.processedTemplate.cells[i][j] = {...cell}
            }
        }

        for(let i = 0; i < this.processedTemplate.cells.length; i++){
            for(let j = 0; j < this.processedTemplate.cells[i].length; j++){
                let cell = this.processedTemplate.cells[i][j]
                if(!(typeof(cell.formula) == 'undefined') && isNaN(cell.formula)){
                    if(cell.formula.indexOf('{{') != -1){
                        let content
                        if(cell.formula.indexOf('//') == -1){
                            content = cell.formula
                        }else{
                            content = cell.formula.substring(cell.formula.indexOf('//') + 2, cell.formula.length)
                        }

                        let tail = content
                        let blocks = []
                        while(tail != ''){
                            let block
                            if(tail.indexOf('{{') == 0){
                                block = tail.substring(0, tail.indexOf('}}') + 2)
                                tail = tail.substring(tail.indexOf('}}') + 2, tail.length)
                            }
                            else if(tail.indexOf('{{') == -1){
                                block = tail
                                tail = ''
                            }else{
                                block = tail.substring(0, tail.indexOf('{{'))
                                tail = tail.substring(tail.indexOf('{{'), tail.length)
                            }
                            blocks.push(block)
                        }
                        
                        //console.log('')
                        //console.log('CELL(' + i + ',' + j + ')')
                        let res = ''
                        for(let k = 0; k < blocks.length; k++){
                            // solve block
                            if(blocks[k].indexOf('{{') != -1){
                                blocks[k] = blocks[k].substring(blocks[k].indexOf('{{') + 2, blocks[k].indexOf('}}'))

                                // make an exception for the dropdown function
                                let firstFunc = blocks[k].substring(0, blocks[k].indexOf('('))
                                if(firstFunc == 'dropDown'){
                                    let rr = this.solveCommand(blocks[k])
                                    let rule = {
                                        list: {
                                            inCellDropDown: true,
                                            source: rr[1]
                                        }
                                    }
                                    res = rr[0]
                                    // setting the rule will cause the cell in the original template to turn into a dropdown
                                    //this.processedTemplate.cells[i][j].dataValidation.rule = rule
                                    this.processedTemplate.cells[i][j].dataValidation.rule.list = rule.list
                                }
                                else{
                                    blocks[k] = this.solveCommand(blocks[k], 1)
                                    if(Array.isArray(blocks[k])){
                                        res = blocks[k]
                                        break
                                    }else{
                                        res += blocks[k]
                                    }
                                }
                            }else{
                                res += blocks[k]
                            }
                        }

                        //console.log('CELL_RES:')
                        //console.log(res)
                        if(Array.isArray(res)){
                            // count the empty rows until the next cell containing a formula int this column
                            let emptyRowCount = 0
                            for(let k = 1; i + k < this.processedTemplate.cells.length; k++){
                                if(this.processedTemplate.cells[i + k][j].formula == ''){ 
                                    emptyRowCount++ 
                                }
                                else{break}
                            }

                            let rows = [] // contains cells
                            let rowsR = [] // contains rows
                            let newRowsNeeded = 0
                            if(res.length - 1 - emptyRowCount > 0){ newRowsNeeded = res.length - 1 - emptyRowCount }
                            for(let m = 0; m < newRowsNeeded; m++){
                                //rowsToAdd++

                                // add cells into row
                                rows.push([])
                                for(let n = 0; n < this.processedTemplate.cells[i].length; n++){
                                    // rows[m].push(template.cells[i][n])
                                    let emptyCell = {
                                        formula: '',
                                        format: {
                                            borders: {
                                                items: []
                                            }
                                        }
                                    }
                                    rows[m].push(emptyCell)
                                }

                                // add rowRs
                                rowsR.push(this.processedTemplate.rows[i])
                            }
                            // add empty rows
                            if(rows.length > 0){
                                this.processedTemplate.cells = this.insertAtIndex(this.processedTemplate.cells, i, rows) // those are empty
                                this.processedTemplate.rows = this.insertAtIndex(this.processedTemplate.rows, i, rowsR) // rowsR actually aren't empty
                            }
                            // apply content and styling to the new CELL-rows
                            for(let l = 0; l < res.length; l++){
                                this.processedTemplate.cells[i + l][j] = {...cell}
                                // apply no-borders to all cells in the new row
                                for(let j2 = 0; j2 < this.processedTemplate.cells[i + l].length; j2++){
                                    let borders = this.processedTemplate.cells[i + l][j2].format.borders.items
                                    for(let m = 0; m < 8; m++){
                                        borders[m] = {
                                            color: '#000000',
                                            weight: 'Thin',
                                            tintAndShade: null,
                                            style: 'None'
                                        }
                                    }
                                }
                                this.processedTemplate.cells[i + l][j].formula = res[l][0]
                            }
                        }else{
                            this.processedTemplate.cells[i][j].formula = res
                        }
                    } else{
                        if(cell.formula.indexOf('//') !== -1){
                            cell.formula = cell.formula.substring(cell.formula.indexOf('//') + 2, cell.formula.length)
                        }
                    }
                }
            }
            //rowsAdded += rowsToAdd
        }
        //console.log('PROCESSED TEMPLATE:')
        //console.log(this.processedTemplate)
        return this.processedTemplate
    }
    solveCommand(cmd, depth){
        // let indent = ''
        // for(let i = 0; i < depth; i++){
        //     indent += '      '
        // }
        let args = this.splitBy(cmd, ',')

        let layerRes = []
        for(let h = 0; h < args.length; h++){
            //console.log('%c' + indent + args[h], 'font-weight: bold;')

            let splits = this.splitBy(args[h], '+')

            // CHECK SPLITS
            let argRes
            for(let i = 0; i < splits.length; i++){
                //console.log('%c' + indent + '    ' + splits[i], 'color: #0000ff')

                let func
                let arg
                let res
                // split is a function
                if(splits[i].indexOf('(') != -1){
                    func = splits[i].substring(0, splits[i].indexOf('('))
                    arg = splits[i].substring(splits[i].indexOf('(') + 1, splits[i].lastIndexOf(')'))
                    //console.log(indent + '      ' + 'FUNC: ' + func)
                    //console.log(indent + '      ' + 'ARGS: ' + arg)

                    // CHECK 
                    let args_new
                    // arg is a function
                    if(arg.indexOf('(') != -1){
                        args_new = this.solveCommand(arg, depth + 2)
                        //console.log(indent + '      ' + 'FUNC: ' + func)

                        // let args_new_LOG = ''
                        // for(let i = 0; i < args_new.length; i++){
                        //     if(Array.isArray(args_new[i])){
                        //         args_new_LOG  += '[' + args_new[i] + ']'
                        //     }else{
                        //         args_new_LOG  += args_new[i]
                        //     }

                        //     if(i != args_new.length - 1){
                        //         args_new_LOG  += '  ,  '
                        //     }
                        // }
                        //console.log(indent + '      ' + 'ARGS_NEW: ' + args_new_LOG)
                    }
                    // arg isn't a function
                    else{
                        args_new = arg.split(',')
                    }
                    

                    // argument should be resolved now
                    switch(func){
                        case 'add':
                            // returns int
                            res = this.add(args_new)
                            break
                        case 'getID':
                            // returns string
                            res = this.getId()
                            break
                        case 'getTimeline':
                            // returns string
                            res = this.getTimeline()
                            break
                        case 'getSheetIndex':
                            // returns int
                            res = this.getSheetIndex(args_new)
                            break
                        case 'getSheetName':
                            // returns string
                            res = this.getSheetName(args_new)
                            break
                        case 'repeat':
                            // returns array
                            res = this.repeat(args_new)
                            break
                        case 'increase':
                            // returns array
                            res = this.increase(args_new)
                            break
                        case 'convertToArray':
                            // returns array
                            res = this.convertToArray(args_new)
                            break
                        case 'getCompanies':
                            // returns array
                            res = this.getCompanies(args_new)
                            break
                        case 'getCompaniesReverse':
                            //returns array
                            res = this.getCompaniesReverse(args_new)
                            break
                        case 'getCompanyCount':
                            // returns int
                            res = this.getCompanyCount(args_new)
                            break
                        case 'getFirstRow':
                            // returns int
                            res = this.getFirstRow()
                            break
                        case 'getLastRow':
                            // returns int
                            res = this.getLastRow(args_new)
                            break
                        case 'getLastColumn':
                            // returns string
                            res = this.getLastColumn(args_new)
                            break
                        case 'indexMatch':
                            res = this.indexMatch(args_new)
                            break
                        case 'indexMatch_inc':
                            res = this.indexMatch_inc(args_new)
                            break
                        case 'indexMatch_2col':
                            res = this.indexMatch_2col(args_new)
                            break
                        case 'indexMatch_2col_inc':
                            res = this.indexMatch_2col_inc(args_new)
                            break
                        case 'dropDown':
                            res = this.dropDown(args_new)
                            break
                        case 'CIQ_formula_inc':
                            res = this.CIQ_formula_inc(args_new)
                            break
                        default:
                            // returns string
                            res = '*function not found*'
                    }
                    let resLOG = res
                    if(Array.isArray(res)){
                        resLOG = '[' + resLOG + ']'
                    }
                    //console.log('%c' + indent + '      ' + 'SPLIT_RES: ' + resLOG, 'color: #0000ff')
                    if(!argRes){
                        argRes = res
                    }else{
                        argRes += res
                    }
                    
                }
                // split isn't a function
                else{
                    res = splits[i]
                    if(!argRes){
                        argRes = res
                    }else{
                        argRes += res
                    }
                }
            }
            let argResLOG = argRes
            if(Array.isArray(argRes)){
                argResLOG = '[' + argResLOG + ']'
            }
            //console.log('%c' + indent + '    ' + 'ARG_RES: ' + argResLOG, 'font-weight: bold;')
            layerRes.push(argRes)
        }

        // let layerResLOG = ''
        // for(let i = 0; i < layerRes.length; i++){
        //     if(Array.isArray(layerRes[i])){
        //         layerResLOG += '[' + layerRes[i] + ']'
        //     }else{
        //         layerResLOG += layerRes[i]
        //     }

        //     if(i != layerRes.length - 1){
        //         layerResLOG += '  ,  '
        //     }
        // }
        
        
        //console.log('%c' + indent + 'LAYER_RES: ' + layerResLOG, 'font-weight: bold;')
        if(layerRes.length == 1){ 
            layerRes = layerRes[0] 
        }
        return layerRes
    }

    add(args){
        let a = parseInt(args[0], 10)
        let b = parseInt(args[1], 10)
        return a + b
    }
    getId(){
        let id = this.buffer[0].metadata.id
        //console.log(id)
        return id
    }
    getTimeline(){
        if(this.buffer[this.buffer.length - 1].metadata.scenario){
            let params = this.buffer[this.buffer.length - 1].metadata.scenario[0].split(',')
            let frequency = ''
            let lengthPeriodYear = ''
            for(let i = 0; i < params.length; i++){
                if(params[i].includes('frequency')){
                    frequency = params[i][params[i].length - 1]
                }
                if(params[i].includes('length_period_year')){
                    lengthPeriodYear = params[i][params[i].length - 1]
                }
            }
            let frequencyStr = ''
            if(frequency == 'W')
                frequencyStr = 'weekly'
            if(frequency == 'M')
                frequencyStr == 'monthly'
            return `Beta (${lengthPeriodYear} years ${frequencyStr})`
        }
        else{
            let unlevBeta = this.buffer[this.getSheetIndex('Unlevered Beta')]
            let lpyIndex = ''
            let frequencyIndex = ''
            for(let i = 0; i < unlevBeta.data[0][0].length; i++){
                if(unlevBeta.data[0][0][i] == 'length_period_year')
                    lpyIndex = i
                if(unlevBeta.data[0][0][i] == 'frequency')
                    frequencyIndex = i
            }
            let lengthPeriodYear = unlevBeta.data[1][0][lpyIndex]
            let frequency = unlevBeta.data[1][0][frequencyIndex]
            
            let frequencyStr = ''
            if(frequency == 'W')
                frequencyStr = 'weekly'
            if(frequency == 'M')
                frequencyStr == 'monthly'
            return `Beta (${lengthPeriodYear} years ${frequencyStr})`
        }
            
    }
    repeat(args){
        let pre = args[0]
        let increaser = parseInt(args[1], 10)
        let post = args[2]
        let factor = parseInt(args[3], 10)
        
        let array = []
        for(let i = 0; i < factor; i++){
            if(isNaN(increaser)){ array.push([pre + post]) }
            else{ array.push([pre + (increaser + i) + post]) }
        }
        return array
    }
    increase(args){
        let value = args[0]
        let str = value.replace(/\d+/g, '')
        let number = parseInt(value.match(/(\d+)/)[0])
        let reps = parseInt(args[1], 10)

        let array = []
        for(let i = 0; i < reps; i++){
            array.push([str + (number + i)])
        }
        return array
    }
    convertToArray(args){
        let pre = args[0]
        let arr = args[1]
        let post = args[2]

        let array = []
        for(let i = 0; i < arr.length; i++){
            array.push([pre + arr[i] + post])
        }

        return array
    }
    getCompanies(args){
        let sheetIndex = this.getSheetIndex(args[0])
        let sheetName = this.getSheetName(args[0])

        let companies = []
        let references = []
        let row = this.headerHeight + 1
        for(let i = 1; i < this.buffer[sheetIndex].data.length; i++){
            for(let j = 0; j < this.buffer[sheetIndex].data[i].length; j++){
                let company = this.buffer[sheetIndex].data[i][j][0]
                row++
                if(!companies.includes(company)){
                    companies.push(company)
                    let refStr = `='` + sheetName + `'!A` + row
                    references.push([refStr])
                }
            }
        }
        return references
    }
    getCompaniesReverse(args){
        let sheetIndex = this.getSheetIndex(args[0])

        let companies = []
        let companiesReverse = []
        for(let i = 1; i < this.buffer[sheetIndex].data.length; i++){
            for(let j = 0; j < this.buffer[sheetIndex].data[i].length; j++){
                let company = this.buffer[sheetIndex].data[i][j][0]
                if(!companies.includes(company)){
                    companies.push(company)
                    let crs = company.split(':')
                    let companyReverse = crs[1] + ':' + crs[0]
                    companiesReverse.push([companyReverse])
                }
            }
        }
        return companiesReverse
    }
    getCompanyCount(args){
        return this.getCompanies(args).length
    }
    getSheetName(args){
        let name = args[0]
        
        let sheetName
        for(let i = 0; i < this.buffer.length; i++){
            if(String(this.buffer[i].metadata.sheetname).includes(name)){
                sheetName = this.buffer[i].metadata.sheetname + ' ' + this.buffer[i].metadata.id
            }
        }
        if(!sheetName){ 
            //console.log('%c' + '!sheet_not_found', 'color: #ff0000')
            return '(' + '!sheet_not_found' + ')'
        }
        return sheetName
    }
    getSheetIndex(args){
        let name = args

        let index
        for(let i = 0; i < this.buffer.length; i++){
            if(String(this.buffer[i].metadata.sheetname).includes(name)){
                index = i
                break
            }
        }
        if(index == null){ 
            //console.log('%c' + '!sheet_not_found', 'color: #ff0000')
            return '(' + '!sheet_not_found' + ')'
        }
        return index
    }
    getFirstRow(){
        return this.headerHeight + 1
    }
    getLastRow(args){
        let sheetData = this.buffer[args].data

        let tableSize = 0
        for(let i = 0; i < sheetData.length; i++){
            for(let j = 0; j < sheetData[i].length; j++){
                tableSize++
            }
        }
        return tableSize + this.headerHeight
    }
    getLastColumn(args){
        return this.excel.getColumnLetter(this.buffer[args].data[0][0].length)
    }
    indexMatch(args){
        let sheetIndex = this.getSheetIndex([args[0]])
        let sheetName = this.getSheetName([args[0]])
        
        let valInCol = args[1]
        let column = args[2]
        let valInRow = args[3]

        let firstRow = this.getFirstRow()
        let lastRow = this.getLastRow(sheetIndex)
        let lastColumn = this.getLastColumn(sheetIndex)

        let res0 = `=INDEX('${sheetName}'!A${firstRow}:${lastColumn}${lastRow},`
        let res1 = `MATCH(${valInCol},'${sheetName}'!${column}${firstRow}:${column}${lastRow},0),`
        let res2 = `MATCH(${valInRow},'${sheetName}'!A${firstRow}:${lastColumn}${firstRow},0))`

        let res = res0 + res1 + res2
        return res
    }
    indexMatch_inc(args){
        let sheetIndex = this.getSheetIndex([args[0]])
        let sheetName = this.getSheetName([args[0]])

        let array = []
        for(let i = 0; i < args[1].length; i++){
            let valInCol = args[1][i]
            let column = args[2]
            let valInRow = args[3]

            let res = ''
            // try if the metasheet is available
            try{
                let firstRow = this.getFirstRow()
                let lastRow = this.getLastRow(sheetIndex)
                let lastColumn = this.getLastColumn(sheetIndex)

                let res0 = `=INDEX('${sheetName}'!A${firstRow}:${lastColumn}${lastRow},`
                let res1 = `MATCH(${valInCol},'${sheetName}'!${column}${firstRow}:${column}${lastRow},0),`
                let res2 = `MATCH(${valInRow},'${sheetName}'!A${firstRow}:${lastColumn}${firstRow},0))`
                res = res0 + res1 + res2
            }
            // if not just leave the field empty
            catch{ 
                res = '' // only write this here bc an empty catch block is not allowed
                //console.log('meta sheet not found') 
            }
            
            //.log(res)
            array.push([res])
        }

        return array
    }
    indexMatch_2col(args){
        let sheetIndex = this.getSheetIndex([args[0]])
        let sheetName = this.getSheetName([args[0]])

        let valInCol1 = args[1]
        let column1 = args[2]
        let valInCol2 = args[3]
        let column2 = args[4]
        let valInRow = args[5]

        let firstRow = this.getFirstRow()
        let lastRow = this.getLastRow(sheetIndex)
        let lastColumn = this.getLastColumn(sheetIndex)

        let res0 = `=INDEX('${sheetName}'!A${firstRow}:${lastColumn}${lastRow},`
        let res1 = `MATCH(1,(${valInCol1}='${sheetName}'!${column1}${firstRow}:${column1}${lastRow})*(${valInCol2}='${sheetName}'!${column2}${firstRow}:${column2}${lastRow}),0),`
        let res2 = `MATCH(${valInRow},'${sheetName}'!A${firstRow}:${lastColumn}${firstRow},0))`
        
        let res = res0 + res1 + res2
        return res
    }
    indexMatch_2col_inc(args){
        //console.log(args)

        let sheetIndex = this.getSheetIndex([args[0]])
        let sheetName = this.getSheetName([args[0]])

        let array = []
        for(let i = 0; i < args[1].length; i++){
            let valInCol1 = args[1][i]
            let column1 = args[2]
            let valInCol2 = args[3]
            let column2 = args[4]
            let valInRow = args[5]

            let firstRow = this.getFirstRow()
            let lastRow = this.getLastRow(sheetIndex)
            let lastColumn = this.getLastColumn(sheetIndex)

            let res0 = `=INDEX('${sheetName}'!A${firstRow}:${lastColumn}${lastRow},`
            let res1 = `MATCH(1,(${valInCol1}='${sheetName}'!${column1}${firstRow}:${column1}${lastRow})*(${valInCol2}='${sheetName}'!${column2}${firstRow}:${column2}${lastRow}),0),`
            let res2 = `MATCH(${valInRow},'${sheetName}'!A${firstRow}:${lastColumn}${firstRow},0))`
            
            let res = res0 + res1 + res2
            //console.log(res)
            array.push([res])
        }

        return array
    }
    dropDown(args){
        let sheetName = this.getSheetName([args[0]])
        let sheetIndex = this.getSheetIndex([args[0]])
        let col = args[1]
        let columns = this.buffer[sheetIndex].data[0][0]

        //console.log(col)
        //console.log(columns)

        let colIndex
        for(let i = 0; i < columns.length; i++){
            if(columns[i] == col){
                colIndex = i
            }
        }
        let colLetter = this.excel.getColumnLetter(colIndex + 1)

        let firstTicker = this.buffer[sheetIndex].data[1][0][0]
        let firstRes = this.buffer[sheetIndex].data[1][0][colIndex]
        let firstRow = this.headerHeight + 2
        let lastRow = this.headerHeight + 1
        for(let i = 1; i < this.buffer[sheetIndex].data.length; i++){
            for(let j = 0; j < this.buffer[sheetIndex].data[i].length; j++){
                if(this.buffer[sheetIndex].data[i][j][0] == firstTicker){
                    lastRow++
                }else{ break }
            }
        }

        return [firstRes, `='${sheetName}'!${colLetter}${firstRow}:${colLetter}${lastRow}`]
    }
    CIQ_formula_inc(args){
        //let indicator = args[0]
        let startField = args[1]
        let reps = args[2]
        let dateField = args[3]

        let arr = this.increase([startField, reps])
        let res = []
        for(let i = 0; i < arr.length; i++){
            res.push([`=@CIQ(${arr[i][0]}, "IQ_MARKETCAP", ${dateField}, "EUR")`])
        }
        return res
    }


    // HELPER FUNCTIONS
    splitBy(s, c){
        s = s.split(c)
        for(let i = s.length - 1; i > 0; i--){
            let openBs = s[i].match(/\(/g)
            let openBsLength = openBs ? openBs.length : 0
            let closeBs = s[i].match(/\)/g)
            let closeBsLength = closeBs ? closeBs.length : 0

            if(openBsLength < closeBsLength){
                s[i-1] = s[i-1] + c + s[i]
                s.splice(i, 1)
            }
        }
        return s
    }
    insertAtIndex(array, index, elements){
        let res = []
        for(let i = 0; i < array.length + elements.length; i++){
            let elementsIndex = i - index - 1
            if(i <= index){ res.push(array[i]) }
            else if(elementsIndex >= 0 && elementsIndex < elements.length){ res.push(elements[elementsIndex]) }
            else{ res.push(array[i - elements.length]) }
        }
        return res
    }
}

export default ETP
