var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:!_vm.cfi.tabledata.data ? 'mb-4' : ''},[_c('v-stepper-step',{staticClass:"my-0 py-0",attrs:{"complete":_vm.cfi.stepper > 1,"step":"1"}},[_vm._v(" Inspect current table "),(_vm.cfi.uploadProgress)?_c('v-progress-linear',{attrs:{"size":"25","color":"offblack","value":_vm.cfi.uploadProgress}}):_vm._e()],1),(_vm.cfi.tabledata.data != undefined)?_c('v-stepper-content',{staticClass:"ma-1 pa-1",attrs:{"step":"1"}},[_c('v-row',{staticClass:"my-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","md":"6","xs":"6"}},[_c('v-btn',{staticClass:"ml-3 mb-2",attrs:{"x-small":"","disabled":!!_vm.cfi.uploadProgress},on:{"click":function($event){return _vm.uploadData()}}},[_vm._v(" Upload staging ")])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6","md":"6","xs":"6"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6"},'v-icon',attrs,false),on),[_vm._v(" mdi-menu ")])]}}],null,false,4214632695)},[_c('v-list',{attrs:{"color":"offwhite","width":"200","dense":""}},[_c('v-list-item',{attrs:{"disabled":!!_vm.cfi.uploadProgress},on:{"click":function($event){return _vm.appendNewRows()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Append")])],1)],1),(
                  _vm.cfi.tabledata.data.filter((a) => a['PartitionKey'] == 'latest')
                  .length > 0
              )?_c('v-list-item',{attrs:{"disabled":!!_vm.cfi.uploadProgress},on:{"click":function($event){return _vm.removePartition('latest')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Remove Prod")])],1)],1):_vm._e(),(
                _vm.cfi.tabledata.data.filter((a) => a['PartitionKey'] == 'staging')
                .length > 0
              )?_c('v-list-item',{attrs:{"disabled":!!_vm.cfi.uploadProgress},on:{"click":function($event){return _vm.removePartition('staging')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Remove staging")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":!!_vm.cfi.uploadProgress},on:{"click":function($event){return _vm.pasteSheet(_vm.cfi.tabledata, _vm.cfi.tabledata.name + '_inspect')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Paste to Sheet")])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.cfi.successMessage)),_c('br')]),(_vm.cfi.tabledata.data != undefined)?_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"ma-0 px-6"},[(_vm.colsMismatchInExcel)?_c('div',[_vm._v(" Cols not in excel: "),_c('br'),_vm._l((Object.keys(
            _vm.cfi.tabledata.data[0]
            ).filter(
            (a) => !Object.keys(_vm.cfi.tabledata.excel[0]).includes(a)
            )),function(item,index){return _c('v-chip',{key:index,staticClass:"mr-2",attrs:{"x-small":"","label":""}},[_vm._v(_vm._s(item)+" ")])})],2):_vm._e(),(_vm.colsMismatchInDatabase)?_c('div',[_vm._v(" Cols not in database: "),_c('br'),_vm._l((Object.keys(
            _vm.cfi.tabledata.excel[0]
            ).filter(
            (a) => !Object.keys(_vm.cfi.tabledata.data[0]).includes(a)
            )),function(item,index){return _c('v-chip',{key:index,staticClass:"mr-2",attrs:{"x-small":"","label":""}},[_vm._v(" "+_vm._s(item)+" ")])})],2):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"float-right"},[_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){_vm.cfi.stepper = 2
        _vm.cfi.successMessage = ''
        _vm.checkData(_vm.cfi.filterCol)}}},[_vm._v(" Next ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"text":"","x-small":""},on:{"click":function($event){_vm.cfi.openOverlay = false
        _vm.cfi.tabledata = {}
        _vm.cfi.validateRowsMessage = ''}}},[_vm._v(" Cancel ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }