var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mt-0 pt-0"},[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.openSubJobsLocal = !_vm.openSubJobs}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-left-thick ")])],1)],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"5"}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.paste(_vm.jobs.subJobs, 'Jobs list')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-btn',{attrs:{"disabled":_vm.jobs.loadingTablesDeletion > 0,"small":"","icon":""},on:{"click":function($event){_vm.modal.isVisible = 1}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"5"}},[(_vm.jobs.loadingTablesDeletion > 0)?_c('v-progress-circular',{attrs:{"size":"15","color":"offblack","value":_vm.jobs.loadingTablesDeletion}}):_vm._e()],1)],1),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.jobs.subJobs.filter( (a) => a.status == 'finished' && a.function != 'ep_pipeline_unlevbeta' && a.function != 'ep_pipeline_multiples' ).length)+" "),_c('v-btn',{attrs:{"disabled":_vm.jobs.loadingTablesDeletion > 0,"small":"","icon":""},on:{"click":function($event){_vm.deleteTables(
                _vm.jobs.subJobs
                  .filter(
                    (a) =>
                      a.status == 'finished' &&
                      a.function != ('ep_pipeline_unlevbeta' && 'ep_pipeline_multiples')
                  )
                  .reduce((acc, value) => {
                    if (
                      !acc.includes(value.tableid) &&
                      value.tableid != ''
                    ) {
                      acc.push(value.tableid)
                    }
                    return acc
                  }, [])
              )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1),_c('v-list-item-subtitle',[_vm._v("Finished")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.jobs.subJobs.filter((a) => a.status == 'running').length)+" "),_c('v-btn',{attrs:{"disabled":_vm.jobs.loadingTablesDeletion > 0,"small":"","icon":""},on:{"click":function($event){_vm.deleteTables(
                _vm.jobs.subJobs
                  .filter((a) => a.status == 'running')
                  .reduce((acc, value) => {
                    if (
                      !acc.includes(value.tableid) &&
                      value.tableid != ''
                    ) {
                      acc.push(value.tableid)
                    }
                    return acc
                  }, [])
              )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1),_c('v-list-item-subtitle',[_vm._v("Running")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.jobs.subJobs.filter((a) => a.status == 'failed').length)+" "),_c('v-btn',{attrs:{"disabled":_vm.jobs.loadingTablesDeletion > 0,"small":"","icon":""},on:{"click":function($event){_vm.deleteTables(
                _vm.jobs.subJobs
                  .filter((a) => a.status == 'failed')
                  .reduce((acc, value) => {
                    if (
                      !acc.includes(value.tableid) &&
                      value.tableid != ''
                    ) {
                      acc.push(value.tableid)
                    }
                    return acc
                  }, [])
              )}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1),_c('v-list-item-subtitle',[_vm._v("Failed")])],1)],1),_vm._l((_vm.jobs.subJobs.slice(_vm.jobpage * 10 - 10, _vm.jobpage * 10)),function(job,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-caption",domProps:{"textContent":_vm._s(job.function)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(job.status)+" - "+_vm._s(job.last_updated.substring(0, 10))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(job.message)+" ")])],1)],1)}),_c('pagination',{attrs:{"isPagination":_vm.jobs.subJobs.length > 10,"noOfPages":Math.ceil(_vm.jobs.subJobs.length / 10)},model:{value:(_vm.jobpage),callback:function ($$v) {_vm.jobpage=$$v},expression:"jobpage"}})],2),_c('confirmation-modal',{attrs:{"modal":_vm.modal},on:{"close":function($event){_vm.modal.isVisible = 0},"delete":function($event){_vm.modal.isVisible = 0; _vm.deleteTables(
      _vm.jobs.subJobs
        .filter((a) => a.function != 'ep_pipeline_unlevbeta' && a.function != 'ep_pipeline_multiples')
        .reduce((acc, value) => {
          if (!acc.includes(value.tableid) && value.tableid.includes('TEMP')) {
            acc.push(value.tableid)
          }
          return acc
        }, []), 'subJobs'
    )}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }