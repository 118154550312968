class PasteCharts {
    constructor() {        
        this.buffer
        this.tabColor = '#2E2E38'
        this.fontColor = '#F6F6FA'
        this.backgroundColor = '#2E2E38'

        this.xFactor = 80 / 1.666
        this.yFactor = 24 / 1.666

        this.chartSettings = [
            // Unlevered Beta
            {
                data: {
                    startColumn: 27,
                    column: 0,
                    sourceSheetName: 'Unlevered Beta',
                    sourceSheet: null,
                    sourceColumn: 3,
                    dateColumn: 2,
                    filterColumn: null,
                    filterValue: null,
                    filterColumn2: null,
                    filterValue2: null,
                    chartData: {}
                },
                chart:{
                    title: 'Unlevered Beta',
                    top: 6,
                    left: 1,
                    height: 25,
                    width: 19,
                }
            }, 
            // DE Ratio
            {
                data: {
                    startColumn: 44,
                    column: 0,
                    sourceSheetName: 'Unlevered Beta',
                    sourceSheet: null,
                    sourceColumn: 6,
                    dateColumn: 2,
                    filterColumn: null,
                    filterValue: null,
                    filterColumn2: null,
                    filterValue2: null,
                    chartData: {}
                },
                chart:{
                    title: 'DE Ratio',
                    top: 36,
                    left: 1,
                    height: 25,
                    width: 19,
                }
            },
            // Multiples
            {
                data: {
                    startColumn: 27,
                    column: 0,
                    sourceSheetName: 'Calculate Multiple',
                    sourceSheet: null,
                    sourceColumn: 10,
                    dateColumn: 2,
                    filterColumn: 6,
                    filterValue: 'LTM',
                    filterColumn2: 7,
                    filterValue2: 'EV/IQ_EBIT',
                    chartData: {}
                },
                chart:{
                    title: 'Multiples',
                    top: 6,
                    left: 1,
                    height: 25,
                    width: 19,
                }
            }
        ]
    }

    async paste(type, buffer){
        this.buffer = buffer

        // find the source sheet in the buffer
        for(let i = 0; i < this.chartSettings.length; i++){
            for(let j = 0; j < this.buffer.length; j++){
                if(this.buffer[j].metadata.sheetname.includes(this.chartSettings[i].data.sourceSheetName)){
                    this.chartSettings[i].data.sourceSheet = this.buffer[j]
                }
            }
        }

        // create sheet
        await window.Excel.run(async (context) => {
            let randomSheet = this.buffer[0]
            let sheet = context.workbook.worksheets.add('CHART ' + randomSheet.metadata.id)
            sheet.position = 1
            await context.sync()

            sheet.showGridlines = false
            sheet.tabColor = this.tabColor

            await context.sync()
        })

        if(type == 'betas'){
            await this.pasteData(0)
            await this.pasteChart(0)

            await this.pasteData(1)
            await this.pasteChart(1)
        }
        if(type == 'multiples'){
            await this.pasteDataV2(2)
            await this.pasteChart(2)
        }
        
        // style sheet
        await window.Excel.run(async (context) => {
            let randomSheet = this.buffer[0]
            let sheet = context.workbook.worksheets.getItem('CHART ' + randomSheet.metadata.id)

            let usedRange = sheet.getUsedRange()
            usedRange.format.autofitColumns()

            let v
            if(type == 'betas') v = 0
            else if(type == 'multiples') v = 2
            let backgroundRange = sheet.getRange('1:' + Math.max(this.chartSettings[v].data.chartData.dates.length + 1, 100))
            backgroundRange.format.fill.color = this.backgroundColor
        })

        await this.pasteHeader()
    }

    async pasteHeader(){
        await window.Excel.run(async (context) => {
            let randomSheet = this.buffer[0]
            let sheet = context.workbook.worksheets.getItem('CHART ' + randomSheet.metadata.id)
            
            let headerRange1 = sheet.getRange('A1:A1')
            headerRange1.values = [['Strategy and Transactions']]
            headerRange1.format.font.color = this.fontColor
            headerRange1.format.font.size = 9

            let headerRange2 = sheet.getRange('A3:A3')
            headerRange2.values = [['Unlevered Beta Chart & DE Ratio Chart']]
            headerRange2.format.font.color = this.fontColor
            headerRange2.format.font.bold = true

            await context.sync()
        })
    }

    async pasteDataV2(chartIndex){
        let dataHeaderRow = 2
        let dataStartColumn = this.chartSettings[chartIndex].data.startColumn
        this.chartSettings[chartIndex].data.column = this.chartSettings[chartIndex].data.startColumn
        let column = this.chartSettings[chartIndex].data.column
        let chartData = {}

        await window.Excel.run(async (context) => {
            let sourceSheet = this.chartSettings[chartIndex].data.sourceSheet
            let sheet = context.workbook.worksheets.getItem('CHART ' + sourceSheet.metadata.id)

            let sourceSheetStartColumn = 'A'
            let sourceSheetStartRow = 7
            let sourceSheetLastColumn = this.getColumnLetter(sourceSheet.data[0][0].length)
            let sourceSheetLastRow = 6
            for(let i = 0; i < sourceSheet.data.length; i++){
                sourceSheetLastRow += sourceSheet.data[i].length
            }
            let firstCell = sourceSheetStartColumn + sourceSheetStartRow
            let lastCell = sourceSheetLastColumn + sourceSheetLastRow
            let tickerCol = 'A'
            let valDateCol = 'C'
            let periodRelCol = 'G'
            let labelCol = 'H'
            let periodRelOptionsCell = 'C6'
            let labelOptionsCell = 'B6'

            //calculate data
            chartData = {
                dates: [['Date']],
                tickers: [],
                indices: [[['Average']], [['Median']], [['25% Quantile']], [['75% Quantile']]]
            }            

            // get unique dates
            let uniqueDates = []
            for(let i = 1; i < sourceSheet.data.length; i++){
                for(let j = 0; j < sourceSheet.data[i].length; j++){
                    let dateIsInList = false
                    for(let k = 0; k < uniqueDates.length; k++){
                        if(uniqueDates[k] == sourceSheet.data[i][j][2])
                            dateIsInList = true
                    }
                    if(!dateIsInList && sourceSheet.data[i][j][2] != null && sourceSheet.data[i][j][2] != 'Invalid Date' && sourceSheet.data[i][j][2] != 'null'){
                        uniqueDates.push(sourceSheet.data[i][j][2])
                    }
                }
            }
            // sort dates
            uniqueDates.sort(function(a, b){
                let aARR = a.split('-')
                let bARR = b.split('-')

                let yDIFF = aARR[0] - bARR[0]
                if(yDIFF > 0)
                    return 1
                else if (yDIFF < 0)
                    return -1
                else{
                    let mDIFF = aARR[1] - bARR[1]
                    if(mDIFF > 0)
                        return 1
                    else if (mDIFF < 0)
                        return -1
                    else{
                        let dDIFF = aARR[2] - bARR[2]
                        if(dDIFF > 0)
                            return 1
                        else if (dDIFF < 0)
                            return -1
                        else
                            return 0
                    }
                }
            })
            for(let i = 0; i < uniqueDates.length; i++){
                chartData.dates.push([uniqueDates[i]])
            }

            // add tickers
            for(let i = 1; i < sourceSheet.data.length; i++){
                chartData.tickers.push([[sourceSheet.data[i][0][0]]])
                for(let j = 1; j < chartData.dates.length; j++){
                    let dateCell = this.getColumnLetter(dataStartColumn) + (dataHeaderRow + j)
                    let tickerCell = this.getColumnLetter(dataStartColumn + i) + dataHeaderRow

                    let sheetReference = (sourceSheet.metadata.sheetname + ' ' + sourceSheet.metadata.id).substring(0, 30)
                    let dateReference = 
                    `=IFERROR(
                        VALUE(
                            INDEX(
                                '${sheetReference}'!${firstCell}:${lastCell},
                                MATCH(
                                    1,
                                        (${tickerCell}='${sheetReference}'!${tickerCol}${sourceSheetStartRow}:${tickerCol}${sourceSheetLastRow})*
                                        (${dateCell}='${sheetReference}'!${valDateCol}${sourceSheetStartRow}:${valDateCol}${sourceSheetLastRow})*
                                        (${periodRelOptionsCell}='${sheetReference}'!${periodRelCol}${sourceSheetStartRow}:${periodRelCol}${sourceSheetLastRow})*
                                        (${labelOptionsCell}='${sheetReference}'!${labelCol}${sourceSheetStartRow}:${labelCol}${sourceSheetLastRow}),
                                    0
                                ),
                                MATCH("value",'${sheetReference}'!${firstCell}:${sourceSheetLastColumn}${sourceSheetStartRow},0)
                            )&""
                        )
                        ,""
                    )`
                    //chartData.tickers[chartData.tickers.length - 1].push([dateCell + ', ' + tickerCell])
                    chartData.tickers[chartData.tickers.length - 1].push([dateReference])
                }
            }
            for(let i = 0; i < chartData.indices.length; i++){
                for(let j = 1; j < chartData.dates.length; j++){
                    let rangeStart = this.getColumnLetter(dataStartColumn + 1) + (dataHeaderRow + j)
                    let rangeEnd = this.getColumnLetter(dataStartColumn + chartData.tickers.length) + (dataHeaderRow + j)
                    let formula
                    switch(i){
                        case 0:
                            formula = `=AVERAGE(${rangeStart}:${rangeEnd})`
                            break
                        case 1:
                            formula = `=MEDIAN(${rangeStart}:${rangeEnd})`
                            break
                        case 2:
                            formula = `=PERCENTILE(${rangeStart}:${rangeEnd},0.25)`
                            break
                        case 3:
                            formula = `=PERCENTILE(${rangeStart}:${rangeEnd},0.75)`
                            break
                    }
                    chartData.indices[i].push([formula])
                }
            }

            // paste data
            let titleRange = sheet.getRange(this.getColumnLetter(column) + 1 + ':' + this.getColumnLetter(column) + 1)
            titleRange.values = this.chartSettings[chartIndex].chart.title + ' Data'
            titleRange.format.font.color = this.fontColor
            titleRange.format.font.bold = true

            let dateRange = sheet.getRange(this.getColumnLetter(column) + 2 + ":" + this.getColumnLetter(column) + (chartData.dates.length + 1))
            dateRange.values = chartData.dates
            dateRange.format.font.color = this.fontColor

            for(let i = 0; i < chartData.tickers.length; i++){
                column++
                let tickerRange = sheet.getRange(this.getColumnLetter(column) + 2 + ":" + this.getColumnLetter(column) + (chartData.tickers[i].length + 1))
                tickerRange.values = chartData.tickers[i]
                tickerRange.format.font.color = this.fontColor
            }
            for(let i = 0; i < chartData.indices.length; i++){
                column++
                let indicesRange = sheet.getRange(this.getColumnLetter(column) + 2 + ":" + this.getColumnLetter(column) + (chartData.indices[i].length + 1))
                indicesRange.values = chartData.indices[i]
                indicesRange.format.font.color = this.fontColor
            }

            // dropdown options
            let ddoColumn = column + 4
            let dropDownOptionsRange = sheet.getRange(this.getColumnLetter(ddoColumn) + 1)
            dropDownOptionsRange.values = [['Dropdown Options']]
            dropDownOptionsRange.format.font.color = this.fontColor
            dropDownOptionsRange.format.font.bold = true

            let dropDownOptionsRange_PeriodRel_column = ddoColumn
            let dropDownOptionsRange_PeriodRel = sheet.getRange(this.getColumnLetter(dropDownOptionsRange_PeriodRel_column) + 2 + ":" + this.getColumnLetter(dropDownOptionsRange_PeriodRel_column) + 6)
            let dropDownOptionsRange_PeriodRel_values = [['PeriodRel'], ['LTM'], ['FY+0'], ['FY+1'], ['FY+2']]
            dropDownOptionsRange_PeriodRel.values = dropDownOptionsRange_PeriodRel_values
            dropDownOptionsRange_PeriodRel.format.font.color = this.fontColor
            let dropDownCell_PeriodRel = sheet.getRange(periodRelOptionsCell)
            let dropDownCell_PeriodRel_listRule = {
                list: {
                    inCellDropDown: true,
                    source: '=' + this.getColumnLetter(dropDownOptionsRange_PeriodRel_column) + 3 + ":" + this.getColumnLetter(dropDownOptionsRange_PeriodRel_column) + 6
                }
            }
            dropDownCell_PeriodRel.values = [dropDownOptionsRange_PeriodRel_values[1]]
            dropDownCell_PeriodRel.dataValidation.rule = dropDownCell_PeriodRel_listRule
            dropDownCell_PeriodRel.format.font.color = this.fontColor
            let ddPr_titleRange = sheet.getRange('C5')
            ddPr_titleRange.values = [['PeriodRel']]
            ddPr_titleRange.format.font.color = this.fontColor

            ddoColumn++
            let dropDownOptionsRange_Label_column = ddoColumn
            let dropDownOptionsRange_Label = sheet.getRange(this.getColumnLetter(dropDownOptionsRange_Label_column) + 2 + ":" + this.getColumnLetter(dropDownOptionsRange_Label_column) + 10)
            let dropDownOptionsRange_Label_values = [['Label'], ['EV/IQ_EBIT'], ['(IQ_REVENUE/IQ_REVENUE_PrevPeriod-1)*100'], ['IQ_EBIT/IQ_REVENUE*100'], ['IQ_EBITDA/IQ_REVENUE*100'], ['IQ_MARKETCAP/IQ_NI'], ['EV/IQ_REVENUE'], ['EV/IQ_EBITDA'], ['IQ_MARKETCAP/IQ_NI']]
            dropDownOptionsRange_Label.values = dropDownOptionsRange_Label_values
            dropDownOptionsRange_Label.format.font.color = this.fontColor
            let dropDownCell_Label = sheet.getRange(labelOptionsCell)
            let dropDownCell_Label_listRule = {
                list: {
                    inCellDropDown: true,
                    source: '=' + this.getColumnLetter(dropDownOptionsRange_Label_column) + 3 + ":" + this.getColumnLetter(dropDownOptionsRange_Label_column) + 10
                }
            }
            dropDownCell_Label.values = [dropDownOptionsRange_Label_values[1]]
            dropDownCell_Label.dataValidation.rule = dropDownCell_Label_listRule
            dropDownCell_Label.format.font.color = this.fontColor
            let ddL_titleRange = sheet.getRange('B5')
            ddL_titleRange.values = [['Label']]
            ddL_titleRange.format.font.color = this.fontColor

            await context.sync()
        })
        this.chartSettings[chartIndex].data.chartData = chartData
        this.chartSettings[chartIndex].data.column = column
    }

    async pasteData(chartIndex){
        this.chartSettings[chartIndex].data.column = this.chartSettings[chartIndex].data.startColumn
        let column = this.chartSettings[chartIndex].data.column
        let sourceColumn = this.chartSettings[chartIndex].data.sourceColumn
        let dateColumn = this.chartSettings[chartIndex].data.dateColumn
        let filterColumn = this.chartSettings[chartIndex].data.filterColumn
        let filterValue = this.chartSettings[chartIndex].data.filterValue
        let filterColumn2 = this.chartSettings[chartIndex].data.filterColumn2
        let filterValue2 = this.chartSettings[chartIndex].data.filterValue2
        let chartData = {}

        await window.Excel.run(async (context) => {
            let sourceSheet = this.chartSettings[chartIndex].data.sourceSheet
            let sheet = context.workbook.worksheets.getItem('CHART ' + sourceSheet.metadata.id)

            //calculate data
            chartData = {
                dates: [['Date']],
                tickers: [],
                indices: [[['Average']], [['Median']], [['25% Quantile']], [['75% Quantile']]]
            }
            for(let i = 0; i < sourceSheet.data[1].length; i++){
                if((filterColumn && sourceSheet.data[1][i][filterColumn] == filterValue) || !filterColumn){
                    if((filterColumn2 && sourceSheet.data[1][i][filterColumn2] == filterValue2) || !filterColumn2){
                        let dateFormatted = String(sourceSheet.data[1][i][dateColumn]).substring(0,10)
                        chartData.dates.push([dateFormatted])
                    }
                }
            }
            for(let i = 1; i < sourceSheet.data.length; i++){
                chartData.tickers.push([[sourceSheet.data[i][0][0]]])
                for(let j = 0; j < sourceSheet.data[i].length; j++){
                    if((filterColumn && sourceSheet.data[i][j][filterColumn] == filterValue) || !filterColumn){
                        if((filterColumn2 && sourceSheet.data[i][j][filterColumn2] == filterValue2) || !filterColumn2){
                            chartData.tickers[chartData.tickers.length - 1].push([sourceSheet.data[i][j][sourceColumn]])
                        }
                    }
                }
            }
   
            // average
            for(let i = 1; i < chartData.dates.length; i++){
                let dateSum = 0
                for (let j = 0; j < chartData.tickers.length; j++) {
                    // Check if chartData.tickers[j][i][0] exists
                    if (chartData.tickers[j][i] && chartData.tickers[j][i].length > 0) {
                        dateSum += chartData.tickers[j][i][0];
                    } else {
                        dateSum += 0; // Add NaN if it doesn't exist
                    }
                }
                let dateAvg = dateSum / chartData.tickers.length
                chartData.indices[0].push([dateAvg])
            }
            // median and quantiles
            for(let i = 1; i < chartData.dates.length; i++){
                let sortArray = []
                for (let j = 0; j < chartData.tickers.length; j++) {
                    if (chartData.tickers[j][i] && chartData.tickers[j][i].length > 0) {
                        sortArray.push(chartData.tickers[j][i][0]);
                    }
                }
                sortArray.sort(function(a,b){
                    return a-b
                })

                chartData.indices[1].push([this.Quantile(sortArray, 0.5)])
                chartData.indices[2].push([this.Quantile(sortArray, 0.25)])
                chartData.indices[3].push([this.Quantile(sortArray, 0.75)])
            }

            // paste data
            let titleRange = sheet.getRange(this.getColumnLetter(column) + 1 + ':' + this.getColumnLetter(column) + 1)
            titleRange.values = this.chartSettings[chartIndex].chart.title + ' Data'
            titleRange.format.font.color = this.fontColor
            titleRange.format.font.bold = true

            let dateRange = sheet.getRange(this.getColumnLetter(column) + 2 + ":" + this.getColumnLetter(column) + (chartData.dates.length + 1))
            dateRange.values = chartData.dates
            dateRange.format.font.color = this.fontColor

            for(let i = 0; i < chartData.tickers.length; i++){
                column++
                let tickerRange = sheet.getRange(this.getColumnLetter(column) + 2 + ":" + this.getColumnLetter(column) + (chartData.tickers[i].length + 1))
                tickerRange.values = chartData.tickers[i]
                tickerRange.format.font.color = this.fontColor
            }
            for(let i = 0; i < chartData.indices.length; i++){
                column++
                let indicesRange = sheet.getRange(this.getColumnLetter(column) + 2 + ":" + this.getColumnLetter(column) + (chartData.indices[i].length + 1))
                indicesRange.values = chartData.indices[i]
                indicesRange.format.font.color = this.fontColor
            }

            await context.sync()
        })
        this.chartSettings[chartIndex].data.chartData = chartData
        this.chartSettings[chartIndex].data.column = column
    }

    async pasteChart(chartIndex){
        let startColumn = this.chartSettings[chartIndex].data.startColumn
        let column = this.chartSettings[chartIndex].data.column
        let chartData = this.chartSettings[chartIndex].data.chartData

        let top = this.chartSettings[chartIndex].chart.top
        let left = this.chartSettings[chartIndex].chart.left
        let height = this.chartSettings[chartIndex].chart.height
        let width = this.chartSettings[chartIndex].chart.width

        // standard chart
        await window.Excel.run(async (context) => {
            let sourceSheet = this.chartSettings[chartIndex].data.sourceSheet
            let sheet = context.workbook.worksheets.getItem('CHART ' + sourceSheet.metadata.id)

            // paste graph
            let dataRange = sheet.getRange(this.getColumnLetter(startColumn) + 2 + ':' + this.getColumnLetter(column) + (chartData.dates.length + 1))
            let chart = sheet.charts.add(
                window.Excel.ChartType.line, 
                dataRange, 
                window.Excel.ChartSeriesBy.column)

            chart.load(['format/font/color', 'legend/format/font/color', 'series/items', 'axes/categoryAxis/majorUnit'])
            await context.sync()

            chart.load(['axes/categoryAxis', 'axes/valueAxis'])
            await context.sync()
            
            for(let i = 0; i < chart.series.items.length; i++){
                if(i < chartData.tickers.length)
                chart.series.items[i].filtered = true
            }

            chart.title.text = this.chartSettings[chartIndex].chart.title
            chart.title.format.font.color = this.fontColor
            chart.title.format.font.bold = true

            chart.top = this.chartSettings[chartIndex].chart.top * this.yFactor
            chart.left = this.chartSettings[chartIndex].chart.left * this.xFactor
            chart.height = this.chartSettings[chartIndex].chart.height * this.yFactor
            chart.width = this.chartSettings[chartIndex].chart.width * this.xFactor
            
            chart.format.fill.setSolidColor(this.backgroundColor)

            chart.legend.format.font.color = this.fontColor
            chart.legend.position = window.Excel.ChartLegendPosition.right

            chart.axes.categoryAxis.format.font.color = this.fontColor
            chart.axes.categoryAxis.majorUnit = 3
            //chart.axes.categoryAxis.format.line.color = '#747480'

            chart.axes.valueAxis.format.font.color = this.fontColor
            chart.axes.valueAxis.majorGridlines.visible = false
            chart.axes.valueAxis.majorGridlines.format.line.color = '#747480'

            // line colors
            let lineColors = ['#3D108A', '#188CE5', '#27ACAA', '#2DB757', '#FF6D00', '#FF4136', '#750E5C',
                                '#724BC3', '#4EBEEB', '#60E6E1', '#57E188', '#FF9831', '#FF736A', '#B14891',
                                '#0A095A', '#155CB4', '#0D7575', '#168736', '#EB4F00', '#B9251C', '#42152D']
            let lines = chart.series.items
            for(let i = 0; i < lines.length; i++){
                let colorIndex = i % lineColors.length
                lines[i].format.line.color = lineColors[colorIndex]
            }
            lines[lines.length - 4].format.line.color = '#747480'
            lines[lines.length - 3].format.line.color = '#C4C4CD'
            lines[lines.length - 2].format.line.color = '#FFE600'
            lines[lines.length - 1].format.line.color = '#FFE600'
            await context.sync()

            let noteColumn = this.getColumnLetter(left + width + 1)
            let noteRange = sheet.getRange(`${noteColumn}${top + 1}:${noteColumn}${top + 2}`)
            noteRange.values = [['To show graphs for single companies'],['click on the chart and select the filter option']]
            noteRange.format.font.color = this.fontColor
            noteRange.format.font.italic = true

            let sourceColumn = this.getColumnLetter(left + 1)
            let sourceRange = sheet.getRange(`${sourceColumn}${top + height + 2}:${sourceColumn}${top + height + 3}`)
            sourceRange.values = [['Source: CapIQ, CFI, EY Analysis'],['Ref: Strategy and Transactions - Betas']]
            sourceRange.format.font.color = this.fontColor
            sourceRange.format.font.italic = true

            await context.sync()
        })
    }

//   async pasteChart(buffer){
//     console.log('buffer:')
//     console.log(buffer)
//     // standard chart
//     await window.Excel.run(async (context) => {
//       //console.log(window.Excel)
//       // this.statusMessage = ''
//       // this.successMessage = ''
//       // this.errorMessage = ''

//       let sourceSheet = buffer[buffer.length - 1]
//       let sheet = context.workbook.worksheets.add('CHART ' + sourceSheet.metadata.id)
//       sheet.position = 1
//       try{
//           await context.sync()
//       }catch{
//           // this.errorMessage = 'A sheet called "CHART" already exists'
//           console.log('A sheet called "CHART" already exists')
//       }

//       // paste data
//       let chartData = {
//         dates: [['Date']],
//         tickers: [],
//         indices: [[['Average']], [['Median']], [['25% Quantile']], [['75% Quantile']]]
//       }
//       for(let i = 0; i < sourceSheet.data[1].length; i++){
//         chartData.dates.push([sourceSheet.data[1][i][2]])
//       }
//       for(let i = 1; i < sourceSheet.data.length; i++){
//         chartData.tickers.push([[sourceSheet.data[i][0][0]]])
//         for(let j = 0; j < sourceSheet.data[i].length; j++){
//           chartData.tickers[chartData.tickers.length - 1].push([sourceSheet.data[i][j][3]])
//         }
//       }
//       // average
//       for(let i = 1; i < chartData.dates.length; i++){
//         let dateSum = 0
//         for(let j = 0; j < chartData.tickers.length; j++){
//           dateSum += chartData.tickers[j][i][0]
//         }
//         let dateAvg = dateSum / chartData.tickers.length
//         chartData.indices[0].push([dateAvg])
//       }
//       // median and quantiles
//       for(let i = 1; i < chartData.dates.length; i++){
//         let sortArray = []
//         for(let j = 0; j < chartData.tickers.length; j++){
//           sortArray.push(chartData.tickers[j][i][0])
//         }
//         sortArray.sort(function(a,b){
//           return a-b
//         })

//         chartData.indices[1].push([this.Quantile(sortArray, 0.5)])
//         chartData.indices[2].push([this.Quantile(sortArray, 0.25)])
//         chartData.indices[3].push([this.Quantile(sortArray, 0.75)])
//       }

//       console.log('chartData')
//       console.log(chartData)
//       let this.fontColor = '#F6F6FA'

//       let startColumn = 27
//       let column = startColumn
//       let dateRange = sheet.getRange(this.getColumnLetter(column) + (1) + ":" + this.getColumnLetter(column) + (chartData.dates.length))
//       dateRange.values = chartData.dates
//       dateRange.format.font.color = this.fontColor

//       for(let i = 0; i < chartData.tickers.length; i++){
//         column++
//         let tickerRange = sheet.getRange(this.getColumnLetter(column) + (1) + ":" + this.getColumnLetter(column) + (chartData.tickers[i].length))
//         tickerRange.values = chartData.tickers[i]
//         tickerRange.format.font.color = this.fontColor
//       }
//       for(let i = 0; i < chartData.indices.length; i++){
//         column++
//         let indicesRange = sheet.getRange(this.getColumnLetter(column) + (1) + ":" + this.getColumnLetter(column) + (chartData.indices[i].length))
//         indicesRange.values = chartData.indices[i]
//         indicesRange.format.font.color = this.fontColor
//       }

//       // paste graph
//       sheet.showGridlines = false
//       sheet.tabColor = '#2E2E38'

//       let dataRange = sheet.getRange(this.getColumnLetter(startColumn) + (1) + ':' + this.getColumnLetter(column) + (sourceSheet.data[1].length + 1))
//       let chart = sheet.charts.add(
//         window.Excel.ChartType.line, 
//         dataRange, 
//         window.Excel.ChartSeriesBy.column)

//       chart.load(['format/font/color', 'legend/format/font/color', 'series/items', 'axes/categoryAxis/majorUnit'])
//       await context.sync()

//       console.log('CHART')
//       console.log(chart)
//       console.log(chart.axes.categoryAxis.majorUnit)

//       // chart.series.items[0].load(['filtered'])
//       // await context.sync()
//       // console.log(chart.series.items[0].filtered)
      
//       for(let i = 0; i < chart.series.items.length; i++){
//         if(i < chartData.tickers.length)
//           chart.series.items[i].filtered = true
//       }

//       chart.title.text = 'Unlevered Beta'
//       chart.title.format.font.color = '#F6F6FA'
//       chart.title.format.font.bold = true

//       chart.top = (6 * 24) / 1.666
//       chart.left = 80 / 1.666
//       chart.height = (29 * 24) / 1.666
//       chart.width = 1760 / 1.666
      
//       chart.format.fill.setSolidColor('#2E2E38')

//       chart.legend.format.font.color = '#F6F6FA'
//       chart.legend.position = window.Excel.ChartLegendPosition.right

//       chart.axes.categoryAxis.format.font.color = '#F6F6FA'
//       chart.axes.categoryAxis.majorUnit = 3
//       //chart.axes.categoryAxis.format.line.color = '#747480'

//       chart.axes.valueAxis.format.font.color = '#F6F6FA'
//       chart.axes.valueAxis.majorGridlines.visible = false
//       chart.axes.valueAxis.majorGridlines.format.line.color = '#747480'

//       // line colors
//       let lineColors = ['#3D108A', '#188CE5', '#27ACAA', '#2DB757', '#FF6D00', '#FF4136', '#750E5C',
//                         '#724BC3', '#4EBEEB', '#60E6E1', '#57E188', '#FF9831', '#FF736A', '#B14891',
//                         '#0A095A', '#155CB4', '#0D7575', '#168736', '#EB4F00', '#B9251C', '#42152D']
//       let lines = chart.series.items
//       for(let i = 0; i < lines.length; i++){
//         let colorIndex = i % lineColors.length
//         lines[i].format.line.color = lineColors[colorIndex]
//       }
//       lines[lines.length - 4].format.line.color = '#747480'
//       lines[lines.length - 3].format.line.color = '#C4C4CD'
//       lines[lines.length - 2].format.line.color = '#FFE600'
//       lines[lines.length - 1].format.line.color = '#FFE600'
//       await context.sync()
      
//       let headerRange1 = sheet.getRange('A1:A1')
//       headerRange1.values = [['Strategy and Transactions']]
//       headerRange1.format.font.color = this.fontColor
//       headerRange1.format.font.size = 9

//       let headerRange2 = sheet.getRange('A3:A3')
//       headerRange2.values = [['Unlevered Beta Chart']]
//       headerRange2.format.font.color = this.fontColor
//       headerRange2.format.font.bold = true

//       let noteRange = sheet.getRange('X7:X8')
//       noteRange.values = [['To show graphs for single companies'],['click on the chart and select the filter option']]
//       noteRange.format.font.color = this.fontColor
//       noteRange.format.font.italic = true

//       let sourceRange = sheet.getRange('B37:B38')
//       sourceRange.values = [['Source: CapIQ, CFI, EY Analysis'],['Ref: Strategy and Transactions - Betas']]
//       sourceRange.format.font.color = this.fontColor
//       sourceRange.format.font.italic = true

//       let usedRange = sheet.getUsedRange()
//       usedRange.format.autofitColumns()

//       let backgroundRange = sheet.getRange('1:' + chartData.dates.length)
//       backgroundRange.format.fill.color = '#2E2E38'

//       await context.sync()
//     })
//     // pivot chart
//     // await window.Excel.run(async (context) => {
//     //   let sourceSheet = buffer[buffer.length - 1]
//     //   let sheetName = 'P-CHART ' + sourceSheet.metadata.id
//     //   let sheet = context.workbook.worksheets.add(sheetName)
//     //   sheet.position = 1
//     //   sheet.showGridlines = false
//     //   sheet.tabColor = '#2E2E38'
//     //   try{
//     //       await context.sync()
//     //   }catch{
//     //       // this.errorMessage = 'A sheet called "CHART" already exists'
//     //       console.log('A sheet called "P-CHART" already exists')
//     //   }

//     //   let lastRow = headerHeight + 1
//     //   for(let i = 1; i < sourceSheet.data.length; i++){
//     //     lastRow += sourceSheet.data[i].length
//     //   }
//     //   let source = `'` + sourceSheet.metadata.sheetname + ` ` + sourceSheet.metadata.id + `'` +  `!A` + (headerHeight + 1) + `:D` + lastRow
//     //   let destination = `'` + sheetName + `'` +  `!B2`
//     //   let pivotTable = context.workbook.pivotTables.add('Unlevered Beta', source, destination)

//     //   pivotTable.columnHierarchies.add(pivotTable.hierarchies.getItem('Ticker'))
//     //   pivotTable.rowHierarchies.add(pivotTable.hierarchies.getItem('Qualifying Date'))
//     //   let betaUnleveredData = pivotTable.dataHierarchies.add(pivotTable.hierarchies.getItem('Beta Unlevered'))
//     //   betaUnleveredData.summarizeBy = window.Excel.AggregationFunction.average

//     //   await context.sync()

//     //   let dataRange = sheet.getRange('B2:B2') //sheet.getRange('B4:L160')
//     //   let chart = sheet.charts.add(
//     //       window.Excel.ChartType.line, 
//     //       dataRange, 
//     //       window.Excel.ChartSeriesBy.column)

//     //   chart.title.text = 'Unlevered Beta'
//     //   chart.top = 24 / 1.666
//     //   chart.left = (20*80) / 1.666
//     //   chart.height = 864 / 1.666
//     //   chart.width = 1760 / 1.666
    
//     //   await context.sync()
//     // })
//   }

  getColumnLetter(colNum) {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')
    let rounds = 0
    let position = colNum
    while (position > 26) {
      position = position - 26
      rounds = rounds + 1
    }
    let letter = ''
    if (rounds) {
      letter = alphabet[rounds - 1] + alphabet[position - 1]
    } else {
      letter = alphabet[position - 1]
    }
    return letter
  }

  Quantile(data, q) {
    let pos = ((data.length) - 1) * q
    let base = Math.floor(pos)
    let rest = pos - base
    if( (data[base+1]!==undefined) ) {
      return data[base] + rest * (data[base+1] - data[base])
    } else {
      return data[base]
    }
  }
}

export default PasteCharts

