import axios from 'axios'

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BACKEND,
  headers: {
    'Access-Control-Allow-Origin': 'https://localhost:3000, https://cfi.ey.com,  https://cfi-dev.ey.com, https://login.microsoftonline.com/, https://cfi-addin.ey.com, https://cfi-addin-dev.ey.com'
  }
})

/*
HTTP.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status && error.response.status === 401) {
        console.log("You are not authorized");
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
});
*/

