<template>
  <div>
    <v-row class="mt-0 pt-0">
      <v-col cols="2" class="mt-0 pt-0">
        <v-btn icon small @click="openSubJobsLocal = !openSubJobs">
          <v-icon small> mdi-arrow-left-thick </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="5" class="mt-0 pt-0">
        <v-btn icon small @click="paste(jobs.subJobs, 'Jobs list')">
          <v-icon small> mdi-download </v-icon>
        </v-btn>
        <v-btn
          :disabled="jobs.loadingTablesDeletion > 0"
          small
          icon
          @click="modal.isVisible = 1"
        >
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="5" class="mt-0 pt-0">
        <v-progress-circular
          v-if="jobs.loadingTablesDeletion > 0"
          size="15"
          color="offblack"
          :value="jobs.loadingTablesDeletion"
        />
      </v-col>
    </v-row>
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{
              jobs.subJobs.filter(
                (a) =>
                  a.status == 'finished' &&
                  a.function != 'ep_pipeline_unlevbeta' && a.function != 'ep_pipeline_multiples'
              ).length
            }}
            <v-btn
              :disabled="jobs.loadingTablesDeletion > 0"
              small
              icon
              @click="
                deleteTables(
                  jobs.subJobs
                    .filter(
                      (a) =>
                        a.status == 'finished' &&
                        a.function != ('ep_pipeline_unlevbeta' && 'ep_pipeline_multiples')
                    )
                    .reduce((acc, value) => {
                      if (
                        !acc.includes(value.tableid) &&
                        value.tableid != ''
                      ) {
                        acc.push(value.tableid)
                      }
                      return acc
                    }, [])
                )
              "
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>Finished</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.subJobs.filter((a) => a.status == 'running').length }}
            <v-btn
              :disabled="jobs.loadingTablesDeletion > 0"
              small
              icon
              @click="
                deleteTables(
                  jobs.subJobs
                    .filter((a) => a.status == 'running')
                    .reduce((acc, value) => {
                      if (
                        !acc.includes(value.tableid) &&
                        value.tableid != ''
                      ) {
                        acc.push(value.tableid)
                      }
                      return acc
                    }, [])
                )
              "
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>Running</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.subJobs.filter((a) => a.status == 'failed').length }}
            <v-btn
              :disabled="jobs.loadingTablesDeletion > 0"
              small
              icon
              @click="
                deleteTables(
                  jobs.subJobs
                    .filter((a) => a.status == 'failed')
                    .reduce((acc, value) => {
                      if (
                        !acc.includes(value.tableid) &&
                        value.tableid != ''
                      ) {
                        acc.push(value.tableid)
                      }
                      return acc
                    }, [])
                )
              "
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </v-list-item-title>
          <v-list-item-subtitle>Failed</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(job, i) in jobs.subJobs.slice(jobpage * 10 - 10, jobpage * 10)"
        :key="i"
      >
        <v-list-item-content>
          <v-list-item-title
            class="text-caption"
            v-text="job.function"
          />
          <v-list-item-subtitle>
            {{ job.status }} - {{ job.last_updated.substring(0, 10) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ job.message }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <pagination
        :isPagination="jobs.subJobs.length > 10"
        :noOfPages="Math.ceil(jobs.subJobs.length / 10)"
        v-model="jobpage"
      />
    </v-list>
    <confirmation-modal
      @close="modal.isVisible = 0"
      @delete="modal.isVisible = 0; deleteTables(
        jobs.subJobs
          .filter((a) => a.function != 'ep_pipeline_unlevbeta' && a.function != 'ep_pipeline_multiples')
          .reduce((acc, value) => {
            if (!acc.includes(value.tableid) && value.tableid.includes('TEMP')) {
              acc.push(value.tableid)
            }
            return acc
          }, []), 'subJobs'
      )"
      :modal="modal"
    />
  </div>
</template>

<script>
import ConfirmationModal from '../Common/ConfirmationModal.vue'
import Pagination from './../Common/Pagination.vue'
export default {
  name: 'JobSubMenu',
  components: {
    confirmationModal: ConfirmationModal,
    pagination: Pagination
  },
  props: {
    openSubJobs: {
      type: Boolean,
      required: true
    },
    jobs: {
      type: Object,
      required: true
    },
    excel: {
      type: Object,
      required: true
    },
    cfi: {
      type: Object,
      required: true
    },
    templates: {
      type: Object,
      required: true
    }
  },
  model: {
      prop: 'openSubJobs',
      event: 'openSubJobsChange'
  },
  computed: {
    openSubJobsLocal: {
      get: function() {
          return this.openSubJobs
      },
      set: function(value) {
          this.$emit('openSubJobsChange', value)
      }
    }
  },
  data: () => ({
    jobpage: 1,
    modal: {
      isVisible: 0,
      heading: 'Confirm Delete',
      content: 'Please confirm if you want to proceed with deleting Temp tables',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Delete', event: 'delete', color: 'error' },
      ]
    }
  }),
  methods: {
    async paste(data, sheetname) {
      const metadata = {
        sheetname
      }
      await this.cfi.writeToBuffer(
        data,
        metadata,
      )
      await this.excel.pasteSheets(this.cfi.buffer, this.cfi.header, this.$root.snackbar)
      this.cfi.buffer = []
      //await this.getData.pasteSheet(data, sheetname, this.$root.snackbar)
    },
    async deleteTables(param) {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.jobs.deleteTables(param)
    },
  }
}
</script>
