<template>
  <div>
    <v-list-item three-line>
      <v-list-item-content
        v-if="
          sheets.filter((a) => tickerTables.includes(a.name)).length == 0
        "
      >
        <v-list-item-title>2. Ticker Set / tickerInputs</v-list-item-title>
        <v-list-item-subtitle>
          Screening template via <v-icon x-small> mdi-download </v-icon>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          Table template or read sheet
          <v-icon x-small> mdi-plus-box </v-icon>
        </v-list-item-subtitle>
        <v-progress-linear
          v-if="cfi.uploadSheets.tickerInputs.reading"
          size="25"
          color="yellow"
          :indeterminate="cfi.uploadSheets.tickerInputs.reading"
        />
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-list-item-title>2. Ticker Set / tickerInputs</v-list-item-title>
        <v-list-item-subtitle>
          Click <v-icon x-small> mdi-cloud-upload </v-icon> to upload
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{
            sheets.filter((a) => tickerTables.includes(a.name))[0].excel
              .length
          }}
          Tickers
        </v-list-item-subtitle>
        <v-progress-linear
          v-if="cfi.uploadSheets.tickerInputs.reading"
          size="25"
          color="yellow"
          :indeterminate="cfi.uploadSheets.tickerInputs.reading"
        />
      </v-list-item-content>
      <v-list-item-icon>
        <v-btn
          color="offblack"
          icon
          @click="pasteTable(templates.templateTables['screening'], 'screening')"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn
          color="offblack"
          icon
          @click="readSheets(tickerTables, false)"
        >
          <v-icon>mdi-plus-box</v-icon>
        </v-btn>
      </v-list-item-icon>
      <v-list-item-icon>
        <v-btn
          v-if="cfi.loaderId !== tickerTables[0]"
          color="offblack"
          :disabled="
            sheets.filter((a) => tickerTables.includes(a.name)).length == 0
          "
          icon
          @click="appendNewRows()">
          <!-- uploadData(sheet.name, sheet.excel) -->
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-progress-circular
          v-else
          size="25"
          color="yellow"
          :value="cfi.uploadProgress"
        />
      </v-list-item-icon>
    </v-list-item>
    <error-message :errorDetails="cfi.errorDetails[tickerTables[0]]" :info="cfi.loaderId !== tickerTables[0]" />
  </div>
</template>

<script>
import ErrorMessage from './../ErrorMessage.vue'
import Templates from '../../functions/templates'

export default {
  name: 'TickerSet',
  components: {
    errorMessage: ErrorMessage
  },
  props: {
    sheets: {
      type: Array,
      required: true
    },
    cfi: {
      type: Object,
      required: true
    },
    pasteTable: {
      type: Function,
      required: true
    },
    readSheets: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    tickerTables: ['tickerInputs'],
    templates: new Templates(),
  }),
  methods: {
    async appendNewRows() {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      this.cfi.appendNewRows(
        this.tickerTables[0],
        this.sheets.filter((a) => this.tickerTables.includes(a.name))[0].excel,
        '',
        2000
      )
    }
  }
}
</script>