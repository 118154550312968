<template>
  <v-card flat tile class="pa-0">
    <v-card-title class="text-subtitle-2">
      {{ cfi.tabledata.name }} <v-spacer />
      <v-btn
        icon
        small
        @click="
          cfi.openOverlay = false
          cfi.tabledata = {}
          cfi.stepper = 1
          cfi.validateRowsMessage = ''
        "
      >
        <v-icon color="offblack" small> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle
      v-if="cfi.tabledata.data != undefined"
      class="text-caption"
    >
      <v-chip v-if="cfi.tabledata.data.length > 0" class="mr-2" label x-small>
      {{ cfi.tabledata.data.length }} Rows
      </v-chip>
      <v-chip v-if="cfi.tabledata.data.length > 0" class="mr-2" label x-small>
      {{ Object.keys(cfi.tabledata.data[0]).length }} Cols
      </v-chip>
      <v-chip
        v-if="cfi.tabledata.stag.length > 0"
        class="mr-2"
        label
        x-small
        text-color="orangedark"
        color="orangelight"
      >
        {{ cfi.tabledata.stag.length }} Staged
      </v-chip>
      <v-menu
        offset-y
        open-on-hover
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="pl-1" small v-on="on" v-bind="attrs">
            mdi-information
          </v-icon>
        </template>
        <div class="px-2 py-2 offwhite">
          <span>Two rows cannot be deleted and are referred to as sample rows.</span>
        </div>
      </v-menu>
    </v-card-subtitle>
    <v-card-text class="mx-0 pa-0">
      <v-stepper v-model="cfi.stepper" vertical flat class="my-0 py-0">
        <inspectTable
          :cfi="cfi"
          :checkData="checkData"
          :excel="excel"
          :templates="templates"
        />
        <compareStaging
          :tableupdate="tableupdate"
          :cfi="cfi"
          :checkData="checkData"
        />
        <updateProd
        :tableupdate="tableupdate"
        :cfi="cfi"
        />        
      </v-stepper>
    <v-divider />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import InspectTable from './InspectTable.vue'
import CompareStaging from './CompareStaging.vue'
import UpdateProd from './UpdateProd.vue'

export default {
  name: 'VMETableDetail',
  props: {
    cfi: {
      type: Object,
      required: true
    },
    excel: {
      type: Object,
      required: true
    },
    templates: {
      type: Object,
      required: true
    }
  },
  components: {
    inspectTable: InspectTable,
    compareStaging: CompareStaging,
    updateProd: UpdateProd
  },
  data: () => ({
    tableupdate: {
      prod: [],
      stag: [],
      newRows: []
    }
  }),
  methods: {
    checkData(filteredCol) {
      this.tableupdate = {
        prod: [],
        stag: []
      }
      this.cfi.tabledata.data.forEach((item) => {
        switch (item['PartitionKey']) {
          case 'latest':
            this.tableupdate['prod'].push(item)
            break
          case 'staging':
            this.tableupdate['stag'].push(item)
            break
          default:
        }
      })
      if (!filteredCol) {
        this.tableupdate['newRows'] = this.tableupdate.stag.filter(
          (a) =>
            this.tableupdate.prod.findIndex(
              (s) => JSON.stringify(s) == JSON.stringify(a)
            ) == -1
        )
      } else {
        this.tableupdate['newRows'] = this.tableupdate.stag.filter(
          (a) =>
            this.tableupdate.prod.findIndex(
              (s) => s[filteredCol] == a[filteredCol]
            ) == -1
        )
      }
      this.tableupdate['newRows'] &&
        this.tableupdate['newRows'].forEach((item) => {
          for (const key in item) {
            if (
              item[key].length == 24 &&
              (String(key).includes('Date') || String(key).includes('Stand'))
            ) {
              item[key] = item[key].substring(0, 10)
            }
          }
        })
      console.log(this.tableupdate['newRows'])
    },
  }
}
</script>
