<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-badge bottom dot :color="badgecolor" offset-x="17" offset-y="17"> -->
      <v-avatar
        style="-webkit-app-region: no-drag"
        color="gray02"
        size="40"
        v-bind="attrs"
        class="ma-2"
        v-on="on"
      >
        <img :src="userImage" />
        <!-- <v-icon color="offwhite">mdi-account</v-icon> -->
        <!-- <span class="offblack--text subtitle">{{ initials }}</span> -->
      </v-avatar>
      <!-- </v-badge> -->
    </template>
    <v-list color="offwhite" width="200" dense>
      <v-list-item>
        <v-list-item-content
          v-if="this.$msal.isAuthenticated() && user.profile.displayName != ''"
        >
          <v-list-item-title>{{ user.profile.displayName }}</v-list-item-title>
          <v-list-item-subtitle v-if="!!user.profile.jobTitle">
            {{ user.profile.jobTitle }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="!!user.me.department"
            @click="pasteToken()"
          >
            {{ user.me.department }}
          </v-list-item-subtitle>
          <v-list-item-subtitle @click="testToken()">
            {{ user.me.country }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title>Not signed in</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-subheader>Dashboards</v-subheader>
      <v-list-item
        link
        href="https://app.powerbi.com/groups/c7e5cddb-415a-49e0-a8c9-7163cda41cda/reports/3532f695-e14b-461f-939c-98296459a291?ctid=5b973f99-77df-4beb-b27d-aa0c70b8482c&pbi_source=linkShare"
      >
        <v-list-item-content>
          <v-list-item-title>IndustryBeta</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        href="https://app.powerbi.com/groups/c7e5cddb-415a-49e0-a8c9-7163cda41cda/reports/f7672081-aa34-456d-9493-e2ee2dd92aa5?ctid=5b973f99-77df-4beb-b27d-aa0c70b8482c&pbi_source=linkShare"
      >
        <v-list-item-content>
          <v-list-item-title>IndustryWACC</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item
        link
        href="https://teams.microsoft.com/l/team/19%3ahi_dPEw-1fC32wFwXC06nouFiFsQaRh6rQDI02Mohro1%40thread.tacv2/conversations?groupId=ddc3aa39-c303-414d-9c0d-ffe81c568ed9&tenantId=5b973f99-77df-4beb-b27d-aa0c70b8482c"
      >
        <v-list-item-content>
          <v-list-item-title>CFI Teams</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="this.$msal.isAuthenticated()" @click="$msal.signOut()">
        <v-list-item-title>Sign out</v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="$msal.signIn()">
        <v-list-item-title>Sign In</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { HTTP } from './../functions/http-common'
import { msalMixin } from 'vue-msal'

export default {
  name: 'Auth',
  mixins: [msalMixin],
  //Importing the mixin locally (omit the following line if you are using the 'framework.globalMixin' option)
  data() {
    return {
      searchTerm: '',
      drawer: false,
      dialog: false,
      dialogLatency: false,
      userInfo: '',
      contentreply: 'nothing',
      manager: {
        profile: '',
        image: ''
      },
      statusColorWs: 'gray02',
      statusColorRest: 'gray02',
      badgecolor: 'gray02',
      connection: null,
      profileImg: '',
      accTokenCopy: '',
      canCopy: false,
      initials: 'EY',
      items: [
        { title: 'Discover', icon: 'dashboard', route: '/' },
        { divider: true },
        { header: 'Modules' },
        { title: 'Get Data', icon: 'apartment', route: '/capiq' },
        { title: 'PyME', icon: 'insert_chart', route: '/pyme' }
      ],
      userImage: ''
    }
  },
  computed: {
    user() {
      var userInfo = {}
      console.log('Fetch User Info')
      if (this.msal.isAuthenticated) {
        console.log('User is Authenticated', this.msal.isAuthenticated)
        // Note that the dollar sign ($) is missing from this.msal
        userInfo = {
          ...this.msal.user,
          photoUrl: '',
          me: {
            department: '',
            country: ''
          },
          manager: {
            id: ''
          },
          name: 'Hi, sign in...',
          userName: '',
          profile: {
            jobTitle: '',
            officeLocation: '',
            businessPhones: [],
            displayName: '',
            givenName: '',
            surname: '',
            preferredLanguage: '',
            id: ''
          }
        }
        if (this.msal.graph && this.msal.graph.profile && this.msal.graph.me) {
          userInfo.profile = this.msal.graph.profile
          userInfo.manager = this.msal.graph.manager
          userInfo.me = this.msal.graph.me

          // 'data:image/jpeg;base64,' + btoa(
          // new Uint8Array(this.msal.graph.photo)
          // .reduce((data, byte) => data + String.fromCharCode(byte), '')
          // );
        }
      } else {
        userInfo = {
          photoUrl: '',
          me: {
            department: '',
            country: ''
          },
          manager: {
            id: ''
          },
          name: 'Hi, sign in...',
          userName: '',
          profile: {
            jobTitle: '',
            officeLocation: '',
            businessPhones: [],
            displayName: '',
            givenName: '',
            surname: '',
            preferredLanguage: '',
            id: ''
          }
        }
      }
      return userInfo
    }
  },
  mounted() {
    // this.signIn()
    // this.getContent();
    // this.getInfo();
    this.$msal
      .msGraph([
        '/me',
        {
          url: '/me/photo/$value',
          responseType: 'blob',
          id: 'photo',
          force: true
        }
      ])
      .then(
        (response) => {
          this.msal.graph.photo = new Blob([response.photo.body])
          // this.msal.graph.photo = response.blob()
          console.log(response)
          if (this.msal.graph.photo instanceof Blob) {
            this.user.photo = this.msal.graph.photo
            try {
              const urlCreator = window.URL || window.webkitURL
              const imageUrl = urlCreator.createObjectURL(this.user.photo)
              this.user.photoUrl = imageUrl
              this.userImage = 'data:image/jpeg;base64,' + response.photo.body
              console.log('Set profile image')
            } catch {
              console.log('Could not set profile image')
            }
          }
        },
        (error) => {
          console.log(error)
        }
      )
  },
  methods: {
    async getContent() {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      this.accTokenCopy = this.$msal.data.accessToken
      await HTTP.get('/user/me', config).then(
        (response) => {
          if (response.data.userid !== undefined) {
            this.contentreply = response.data
            console.log(response.data)
            const { roles, me } = this.contentreply
            this.msal.roles = roles
            // localStorage.setItem("BetaPerm", JSON.stringify(this.contentreply.roles));
            this.badgecolor =
              me.department !== undefined
                ? me.department == this.user.me.department
                  ? 'green'
                  : 'redlight'
                : 'gray02'
          }
        },
        (error) => {
          console.log(error)
        }
      )
    },
    pasteToken() {
      window.Excel.run(async (context) => {
        var rangeAddress = 'A1:B4'
        var range = context.workbook.worksheets
          .getActiveWorksheet()
          .getRange(rangeAddress)
        range.load('values')
        await context.sync()
        if (range.values.findIndex((element) => element[0] != '') == -1) {
          if (range.values.findIndex((element) => element[1] != '') == -1) {
            console.log('pasting')
            range.values = [
              ['User', this.user.profile.displayName],
              ['Department', this.user.me.department],
              ['Token', this.$msal.data.accessToken],
              ['Backend', this.$backend]
            ]
          }
        }
        await context.sync()
      })
    },
    signIn() {
      this.$msal.signIn().catch((error) => {
        console.log(error)
      })
      // console.log(OfficeRuntime.auth.getAccessToken( { allowSignInPrompt: true }))
    },
    async testToken() {
      let accessToken = await window.Office.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: true
      })
      console.log(accessToken)
    },
    getInfo() {
      const defaultStr = 'Hi, sign in...'
      if (!this.msal.isAuthenticated) {
        this.userInfo = defaultStr
      }
      const splitStr = this.userInfo.replace('.', ' ').toLowerCase().split(' ')
      const updatedStr = splitStr.map((item) => item.charAt(0).toUpperCase())
      if (this.user.name === defaultStr) {
        this.initials = 'EY'
      } else {
        this.initials = updatedStr.join('')
      }
    }
  }
  // watch: {
  //   async user() {
  //     const managerId = this.user.manager.id;
  //     this.manager.profile = await this.$msal.msGraph(
  //       "/users/" +
  //         managerId +
  //         "?$select=displayName,jobTitle,businessPhones,department,country"
  //     );
  //     // console.log("fetch manager image")
  //     // this.manager.image = await this.$msal.msGraph('/users/'+ managerId +'/photo/$value')
  //     // const urlCreatorManager = window.URL || window.webkitURL;
  //     // const imageUrlManager = urlCreatorManager.createObjectURL(this.manager.image);
  //     // this.manager.image = imageUrlManager;
  //   },
  // },
}
</script>
