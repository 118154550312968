<template>
  <v-dialog v-model="modal.isVisible" persistnt max-width="290" justify="center" :retain-focus="false">
    <v-card>
      <v-card-title class="headline"> {{modal.heading}} </v-card-title>
      <v-card-text> {{ modal.content }}</v-card-text>
      <v-card-text v-if="modal.error" class="red--text caption">{{ modal.error}}</v-card-text>

      <!-- BETA -->
      <v-container v-if="modal.isBetaPipeline" >
        <v-card-title class="text-h6 mt-n4"> Data Sheets </v-card-title>
        <v-card flat class="mt-n6 mx-4">
          <v-checkbox v-if="modal.metaSheetAvailable" v-model="modal.cbs.betas.dataSheets.meta" :disabled="modal.cbs.betas.templates.beta > 0" label="Meta" class="mx-2 pt-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.dataSheets.stockPrices" label="Stock Prices" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.dataSheets.leveredBeta" label="Levered Beta" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.dataSheets.deRatioKPIs" label="DE Ratio KPIs" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.dataSheets.creditSpread" label="Credit Spread" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.dataSheets.deRatio" label="DE Ratio" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.dataSheets.debtBeta" label="Debt Beta" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.dataSheets.unleveredBeta" :disabled="(modal.cbs.betas.templates.beta > 0) || (modal.cbs.betas.templates.chart > 0)" label="Unlevered Beta" class="mx-2"></v-checkbox>
        </v-card>
        <v-card-title class="text-h6 mt-n4"> Templates </v-card-title>
        <v-card flat class="mt-n6 mx-4">
          <v-checkbox v-model="modal.cbs.betas.templates.beta" label="Beta" class="mx-2 pt-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.betas.templates.chart" label="Chart" class="mx-2"></v-checkbox>
        </v-card>
      </v-container>

      <!-- MULTIPLES -->
      <v-container v-if="modal.isMultiplesPipeline" >
        <v-card-title class="text-h6 mt-n4"> Data Sheets </v-card-title>
        <v-card flat class="mt-n6 mx-4">
          <v-checkbox v-if="modal.metaSheetAvailable" v-model="modal.cbs.multiples.dataSheets.meta" label="Meta" class="mx-2 pt-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.multiples.dataSheets.multipleKPIs" label="Multiple KPIs" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.multiples.dataSheets.calculateTimeline" label="Calculate Timeline" class="mx-2 mb-n8"></v-checkbox>
          <v-checkbox v-model="modal.cbs.multiples.dataSheets.calculateMultiple" :disabled="modal.cbs.multiples.templates.chart > 0" label="Calculate Multiple" class="mx-2"></v-checkbox>
        </v-card>
        <v-card-title class="text-h6 mt-n4"> Templates </v-card-title>
        <v-card flat class="mt-n6 mx-4">
          <v-checkbox v-model="modal.cbs.multiples.templates.chart" label="Chart" class="mx-2"></v-checkbox>
        </v-card>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-for="btn in modal.buttons" :key="btn.name" text :color="btn.color" @click="$emit(btn.event)" style="font-size: 0.6rem"> {{btn.name}} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { msalMixin } from 'vue-msal'
export default {
  name: 'ConfirmationModal',
  mixins: [msalMixin],
  props: {
    modal: {
      required: true,
      type: Object
    }
  },
  // data: () => ({
  //     meta: 1
  // }),
}
</script>
