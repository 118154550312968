<template>
    <v-card tile>
        <v-row class="mt-0 pt-0">
            <v-col cols="9" class="mt-0 pt-0">
            <v-card-title class="text-subtitle-2 text-uppercase">
                Beta Template
            </v-card-title>
            <v-card-subtitle class="mb-n4"> Admin </v-card-subtitle>
            </v-col>
    
            <v-col cols="3" class="mt-2">
            <v-btn v-if="hidden" icon @click="hidden = !hidden">
                <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            </v-col>
        </v-row>
        <v-card-text class="my-2" :hidden="hidden">
            <div class="text-caption gray01--text">
            Copy template to clipboard or paste an existing one
            <br>
            </div>
            <v-text-field
                v-model="sheetName"
                label="Sheet Name"
            ></v-text-field>
            <!-- <v-text-field
            v-model="tableName"
            label="Table Name"
            ></v-text-field>
            <v-textarea
                label="Filters"
                v-model="filters"
                hint="Please enter filters in a comma seperated format"
                rows="2"
            ></v-textarea>
            -->
            <p class="black--text caption my-0" v-if="statusMessage">{{statusMessage}}</p> 
            <p class="green--text caption my-0" v-if="successMessage">{{successMessage}}</p> 
            <p class="red--text caption my-0" v-if="errorMessage">{{errorMessage}}</p> 
        </v-card-text>
        <div :hidden="hidden">
            <v-card-actions>
            <v-row class="mx-2" justify="end">
            <!-- scan -->
            <v-btn
                small
                :disabled="!sheetName"
                @click="scan"
            >
                Copy
            </v-btn>

            <!-- paste -->
            <v-btn
                small
                :disabled="false"
                @click="paste"
                class="ml-2"
            >
                Paste
            </v-btn>
            </v-row>
            </v-card-actions>
            <v-col v-if="progress.show" cols="12">
            <v-progress-linear indeterminate color="yellow accent-4" height="4" />
            </v-col>
        </div>
        <div :hidden="hidden">
            <v-card-actions class="mx-2">
            <v-row class="mb-1">
                <v-col cols="9" xs="9" />
                <v-col cols="3" xs="3">
                <v-btn v-if="!hidden" icon @click="hidden = !hidden">
                    <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                </v-col>
            </v-row>
            </v-card-actions>
        </div>
    </v-card>
</template>
    
<script>
import { msalMixin } from 'vue-msal'
import BetaTemplates from '../functions/betatemplates'

export default {
    name: 'Inspection',
    mixins: [msalMixin],
    data: () => ({
        hidden: true,
        betatemplates: new BetaTemplates(),
        sheetName: '',
        statusMessage: '',
        successMessage: '',
        errorMessage: '',
        progress: {
            value: 0,
            query: false,
            show: false,
            interval: 0
        },
        filters: ''
    }),
    props: [
        
    ], 
    methods: {
        async scan() {
            await window.Excel.run(async (context) => {
                let sheet = context.workbook.worksheets.getItem(this.sheetName)

                this.statusMessage = ''
                this.successMessage = ''
                this.errorMessage = ''

                try{
                    sheet.load(['showGridlines', 'tabColor'])
                    await context.sync()
                    this.statusMessage = 'Copying...'
                }catch{
                    this.errorMessage = 'Template not found'
                }

                let sheetData = {
                    showGridlines: sheet.showGridlines,
                    tabColor: sheet.tabColor,
                    cells: [],
                    columns: [],
                    rows: []
                }

                let usedRange = sheet.getUsedRange()
                usedRange.load(['rowCount', 'columnCount',])
                await context.sync()

                for(let i = 0; i < usedRange.rowCount; i++){
                    sheetData.cells.push([])

                    // store row data
                    let rowNumber = i + 1
                    let row = sheet.getRange(rowNumber + ':' + rowNumber)
                    row.load(['address', 'height'])
                    await context.sync()
                    sheetData.rows.push(row.height * 1.666) // height is given in pixels / 1.666

                    for(let j = 0; j < usedRange.columnCount; j++){

                    // store column data
                    if(i == 0){
                        let columnLetter = this.getColumnLetter(j + 1)
                        let column = sheet.getRange(columnLetter + ':' + columnLetter)
                        column.load(['address', 'width'])
                        await context.sync()
                        sheetData.columns.push(column.width * 1.666 ) // width is given in pixels / 1.666
                    }

                    let cell = usedRange.getCell(i, j)
                    cell.load([ // content
                                'formulas', 
                                // format
                                'format/autoIndent',
                                'format/borders',
                                'format/fill',
                                'format/font',
                                'format/horizontalAlignment',
                                'format/indentLevel',
                                'format/protection',
                                'format/readingOrder',
                                'format/shrinkToFit',
                                'format/textOrientation',
                                'format/useStandardHeight',
                                'format/useStandardWidth',
                                'format/verticalAlignment',
                                'format/wrapText',
                                'numberFormat',
                                'dataValidation'
                                ])
                    await context.sync()

                    let cellData = {
                        formula: cell.formulas[0][0],
                        format: {
                            autoIndent: cell.format.autoIndent,
                            borders: cell.format.borders,
                            fill: cell.format.fill,
                            font: cell.format.font,
                            horizontalAlignment: cell.format.horizontalAlignment,
                            indentLevel: cell.format.indentLevel,
                            protection: cell.format.protection,
                            readingOrder: cell.format.readingOrder,
                            shrinkToFit: cell.format.shrinkToFit,
                            textOrientation: cell.format.textOrientation,
                            useStandardHeight: cell.format.useStandardHeight,
                            useStandardWidth: cell.format.useStandardWidth,
                            verticalAlignment: cell.format.verticalAlignment,
                            wrapText: cell.format.wrapText,
                        },
                        numberFormat: cell.numberFormat,
                        dataValidation: cell.dataValidation
                    }
                    sheetData.cells[i].push(cellData)
                    }
                }

                //console.log(sheetData)
                let sheetDataString = JSON.stringify(sheetData)
                navigator.clipboard.writeText(sheetDataString)
                //console.log('copy done')
                this.statusMessage = ''
                this.successMessage = 'Template copied to clipboard'
            })
        },
        async paste() {
            //console.log('paste template')
            let sheetData = this.betatemplates.beta_template_1
            //console.log(sheetData)

            await window.Excel.run(async (context) => {
                this.statusMessage = ''
                this.successMessage = ''
                this.errorMessage = ''

                let array = sheetData.cells
                let sheet = context.workbook.worksheets.add('template')
                sheet.position = 1
                try{
                    await context.sync()
                }catch{
                    this.errorMessage = 'A sheet called "Template" already exists'
                }

                // PASTE SHEET DATA
                sheet.showGridlines = sheetData.showGridlines
                sheet.tabColor = sheetData.tabColor

                // load the cell borders before looping through cells for speedup
                let cellBordersArray = []
                for(let i = 0; i < array.length; i++){
                    cellBordersArray.push([])
                    for(let j = 0; j < array[i].length; j++){
                    let cellAddress = this.getColumnLetter(j+1) + (i+1) + ':' + this.getColumnLetter(j+1) + (i+1)
                    let cell = sheet.getRange(cellAddress)
                    cell.load(['format/borders/items'])
                    cellBordersArray[i].push(cell)
                    }
                }
                await context.sync()

                for(let i = 0; i < array.length; i++){
                    for(let j = 0; j < array[i].length; j++){
                    // VALUES
                        let cellAddress = this.getColumnLetter(j+1) + (i+1) + ':' + this.getColumnLetter(j+1) + (i+1)
                        let cell = sheet.getRange(cellAddress)
                        cell.values = [[array[i][j].formula]]

                    // FORMAT
                        // borders
                        if(array[i][j].format.borders){
                        for(let k = 0; k < array[i][j].format.borders.items.length; k++){
                            if(array[i][j].format.borders.items[k].style != 'None'){
                            // set style after weight and color, otherwise a border will always be drawn
                            cellBordersArray[i][j].format.borders.items[k].color = array[i][j].format.borders.items[k].color
                            cellBordersArray[i][j].format.borders.items[k].weight = array[i][j].format.borders.items[k].weight
                            cellBordersArray[i][j].format.borders.items[k].tintAndShade = array[i][j].format.borders.items[k].tintAndShade
                            cellBordersArray[i][j].format.borders.items[k].style = array[i][j].format.borders.items[k].style 
                            }
                        }
                        }
                        
                        // fill
                        if(array[i][j].format.fill){
                        if(array[i][j].format.fill.color != '#FFFFFF'){
                            cell.format.fill.color = array[i][j].format.fill.color
                        }
                        if(array[i][j].format.fill.pattern){
                            cell.format.fill.pattern = array[i][j].format.fill.pattern
                            cell.format.fill.patternColor = array[i][j].format.fill.patternColor
                            cell.format.fill.patternTintAndShade = array[i][j].format.fill.patternTintAndShade
                        }
                        cell.format.fill.tintAndShade = array[i][j].format.fill.tintAndShade
                        }

                        // font
                        if(array[i][j].format.font){
                        if(array[i][j].formula){
                            cell.format.font.bold = array[i][j].format.font.bold
                            cell.format.font.color = array[i][j].format.font.color
                            cell.format.font.italic = array[i][j].format.font.italic
                            cell.format.font.name = array[i][j].format.font.name
                            cell.format.font.size = array[i][j].format.font.size
                            cell.format.font.strikethrough = array[i][j].format.font.strikethrough
                            cell.format.font.subscript = array[i][j].format.font.subscript
                            cell.format.font.superscript = array[i][j].format.font.superscript
                            cell.format.font.tintAndShade = array[i][j].format.font.tintAndShade
                            cell.format.font.underline = array[i][j].format.font.underline
                        }
                        }
                        cell.format.horizontalAlignment = array[i][j].format.horizontalAlignment ? array[i][j].format.horizontalAlignment : 'General'
                        cell.format.verticalAlignment = array[i][j].format.verticalAlignment ? array[i][j].format.verticalAlignment : 'Bottom'
                        cell.format.wrapText = array[i][j].format.wrapText ? array[i][j].format.wrapText : false
                        cell.numberFormat = array[i][j].numberFormat ? array[i][j].numberFormat : 'General'

                        // data validation
                        cell.dataValidation.rule = array[i][j].dataValidation ? array[i][j].dataValidation.rule : null


                    //   dont paste unused stuff for better performance

                    //   //protection
                    //   cell.format.protection.formulaHidden = array[i][j].format.protection.formulaHidden
                    //   cell.format.protection.locked = array[i][j].format.protection.locked

                    //   cell.format.autoIndent = array[i][j].format.autoIndent
                        cell.format.indentLevel = array[i][j].format.indentLevel
                    //   cell.format.readingOrder = array[i][j].format.readingOrder
                    //   cell.format.shrinkToFit = array[i][j].format.shrinkToFit
                    //   cell.format.textOrientation = array[i][j].format.textOrientation
                    //   cell.format.useStandardHeight = array[i][j].format.useStandardHeight
                    //   cell.format.useStandardWidth = array[i][j].format.useStandardWidth
                    }
                }
                //let usedRange = sheet.getUsedRange()
                //usedRange.format.autofitColumns()
                await context.sync()

                for(let c = 0; c < sheetData.columns.length; c++){
                    let letter = this.getColumnLetter(c + 1)
                    let column = sheet.getRange(letter + ':' + letter)
                    column.format.columnWidth = sheetData.columns[c] / 1.666
                }
                for(let r = 0; r < sheetData.rows.length; r++){
                    let row = sheet.getRange((r + 1) + ':' + (r + 1))
                    row.format.rowHeight = sheetData.rows[r] / 1.666
                }
                await context.sync()
            })
        },

        // helper functions
        getColumnLetter(colNum) {
            const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')
            let rounds = 0
            let position = colNum
            while (position > 26) {
            position = position - 26
            rounds = rounds + 1
            }
            let letter = ''
            if (rounds) {
            letter = alphabet[rounds - 1] + alphabet[position - 1]
            } else {
            letter = alphabet[position - 1]
            }
            return letter
        }
    }
}
</script>
    