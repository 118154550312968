import Vue from 'vue'
import App from './App.vue'
import msal from 'vue-msal'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
// Vue.prototype.$backend = 'https://tasxindustrybetaexcel-backend-dev.azurewebsites.net'
Vue.prototype.$backend = process.env.VUE_APP_BACKEND
Vue.prototype.$frontend = process.env.VUE_APP_REDIRECT
Vue.prototype.$isDevelopment = process.env.VUE_APP_BACKEND !== 'https://cfi.ey.com'

Vue.use(msal, {
  auth: {
    validateAuthority: true,
    // requireAuthOnInitialize: true,
    clientId: process.env.VUE_APP_CLIENTID,
    redirectUri: process.env.VUE_APP_REDIRECT,
    authority: process.env.VUE_APP_AUTHORITY,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage', // needed to avoid "login required" error
    storeAuthStateInCookie: true // recommended to avoid certain IE/Edge issues
  },
  request: {
    // scopes: ["AzureAD:Delegated:User.Read","AzureAD:Delegated:User.ReadBasic.All","ExchangeOnline:Application:User.ReadBasic.All","ExchangeOnline:Delegated:User.ReadBasic.All","Graph:Delegated:Directory.AccessAsUser.All","Graph:Delegated:User.ReadBasic.All","Graph:Delegated:Files.Read","Graph:Delegated:User.Read","SharePointOnline:Delegated:MyFiles.Read","SharePointOnline:Delegated:MyFiles.Write"]
    scopes: ['User.Read', 'User.ReadBasic.All']
    // scopes: ["https://uscptasemiade01.centralus.kusto.windows.net/.default"]
  },
  // Configuration options
  graph: {
    callAfterInit: true,
    endpoints: {
      // people: "/me/people",
      // organization: "/organization",
      me: '/me?$select=companyName,department,country',
      manager: '/me/manager',
      profile: '/me'
      // photo: { url: "/me/photo/$value", responseType: "blob", force: true },
    }
  }
})

//create v-can directive
Vue.directive('can', {
  bind: function (el, binding, vnode) {
    if (
      !JSON.parse(localStorage.getItem('permissions')).includes(
        binding.expression.replace(/'/g, '').replace(/"/g, '')
      )
    ) {
      vnode.elm.style.display = 'none'
    }
  }
})

window.Office.initialize = () => {
  new Vue({
    vuetify,
    render: (h) => h(App)
  }).$mount('#app')
}
