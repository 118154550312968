var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.sheets.filter((a) =>
      _vm.updateTablesList.includes(a.name)
    )),function(sheet,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){_vm.cfi.openOverlay = true
      _vm.cfi.tabledata = sheet
      _vm.cfi.filterCol = _vm.updateTables[sheet.name]
      _vm.cfi.successMessage = ''}}},[_c('v-list-item-content',[(sheet.data != undefined)?_c('v-list-item-title',{staticClass:"text-caption",domProps:{"textContent":_vm._s(sheet.name)}}):_c('v-list-item-title',{staticClass:"text-caption gray01--text",domProps:{"textContent":_vm._s(sheet.name)}}),(sheet.data != undefined)?_c('v-list-item-subtitle',[_c('v-chip',{attrs:{"label":"","x-small":"","text-color":sheet.excel.length - sheet.data.length >= 0
              ? 'greendark'
              : 'reddark',"color":sheet.excel.length - sheet.data.length >= 0
              ? 'greenbg'
              : 'redbg'}},[_vm._v(" "+_vm._s(sheet.excel.length - sheet.data.length > 0 ? '+' : '')+_vm._s(sheet.excel.length - sheet.data.length)+" Rows ")]),_c('v-chip',{staticClass:"mx-2",attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(Object.keys(sheet.excel[0]).length)+"/"+_vm._s(Object.keys(sheet.data[0]).length)+" Cols ")]),(sheet.stag.length > 0)?_c('v-chip',{attrs:{"label":"","x-small":"","text-color":"orangedark","color":"orangelight"}},[_vm._v(" S ")]):_vm._e()],1):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }