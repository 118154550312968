<template>
  <div>
    <v-stepper-step :complete="cfi.stepper > 3" step="3" class="my-0 py-0">
      Update Prod
    </v-stepper-step>
    <v-stepper-content class="ma-1 pa-1" step="3">
      <v-row class="my-2 mx-6">
        <v-col class="ma-0">
          <div>
            {{
              tableupdate.stag != undefined
              ? tableupdate.stag.length
              : '-'
            }}
            Staging rows <br />
            {{
              tableupdate.prod != undefined
              ? tableupdate.prod.length
              : '-'
            }}
            Production rows <br />
            {{
              tableupdate.newRows != undefined
              ? tableupdate.newRows.length
              : '-'
            }}
            new Rows
          </div>
        </v-col>
      </v-row>
      <v-row class="my-2 mx-6">
        <v-col class="ma-0">
          <div v-html="cfi.validateRowsMessage"></div>
        </v-col>
      </v-row>
      <v-row class="my-2 mx-6">
        <v-col class="ma-0">
          <v-btn
            x-small
            :disabled="!!cfi.uploadProgress"
            @click="appendNewRows()"
          >
            Append new Rows
          </v-btn>
        </v-col>
      </v-row>
      <v-btn class="ml-6 float-right" text x-small @click="cfi.stepper = 2">
        Back
      </v-btn>
    </v-stepper-content>
  </div>
</template>

<script>

export default {
  name: 'UpdateProd',
  props: {
    cfi: {
      type: Object,
      required: true
    },
    tableupdate: {
      type: Object,
      required: true
    }
  },
  methods: {
    async appendNewRows() {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      this.cfi.appendNewRows(this.cfi.tabledata.name, this.tableupdate.newRows, 'update')
    }
  } 
}
</script>