<template>
  <v-card tile>
    <v-row class="mt-0 pt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Start Pipeline
        </v-card-title>
        <v-card-subtitle class="mb-n4"> Admin </v-card-subtitle>
      </v-col>

      <v-col cols="3" class="mt-2">
        <v-btn v-if="hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn v-if="!hidden" icon small @click="update()">
          <v-icon small> mdi-reload </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text v-if="!openOverlay" class="px-0" :hidden="hidden">
      <v-row class="mx-2">
        <v-col cols="12">
          <v-container class="mx-0 px-0">
            Chose the ticker set from table tickerInputs
          </v-container>
          <v-text-field
            v-model="tickerSet"
            dense
            label="set"
            append-outer-icon="mdi-send"
            @click:append-outer="askTickers(tickerSet)"
          />
          <v-container v-if="tickers.length > 0" class="ma-0 pa-0">
            {{ tickers.length }} tickers
          </v-container>
          <v-container v-else class="ma-0 pa-0">
            {{ tickerMessage }}
          </v-container>
        </v-col>
      </v-row>
      <v-expansion-panels flat accordion class="mt-2">
        <v-expansion-panel>
          <v-expansion-panel-header> Betas </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-chip @click="setScenario('monthly', 'beta')" class="mx-2" label small>
                Monthly
              </v-chip>
              <v-chip @click="setScenario('esg', 'beta')" class="mx-2" label small>
                ESG
              </v-chip>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="req.end" dense label="end" />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="req.duration"
                  dense
                  label="duration"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="req.frequency"
                  dense
                  label="freq"
                  :items="freqOptions"
                  :item-text="freqOptions.text"
                  :item-value="freqOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="req.regression_method"
                  dense
                  label="method"
                  :items="regMeOptions"
                  :item-text="regMeOptions.text"
                  :item-value="regMeOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="req.regression_horizon"
                  dense
                  label="horizon"
                  :items="regHoOptions"
                  :item-text="regHoOptions.text"
                  :item-value="regHoOptions.value"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="req.performance"
                  dense
                  label="performance"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="req.international"
                  dense
                  label="international"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="req.adjusted_beta"
                  dense
                  label="adjusted"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="req.financial_services"
                  dense
                  label="fin ser"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="req.pensions"
                  dense
                  label="pensions"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="req.net_cash"
                  dense
                  label="net cash"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="req.risk_of_debt_beta"
                  dense
                  label="debt beta"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="req.risk_of_tax_shield"
                  dense
                  label="tax shield"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="req.caption" dense label="caption" />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="req.num_tickers_per_job"
                  dense
                  label="tickers per job"
                  type="number"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="req.queue_id"
                  dense
                  label="queue"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row class="mx-2">
              <v-btn
                small
                outlined
                :disabled="tickerSet == ''"
                :loading="sendLoading"
                @click="sendBetaPipeline(req)"
              >
                send
              </v-btn>
              <v-progress-circular
                v-if="loadingTablesDeletion > 0"
                size="15"
                color="offblack"
                :value="loadingTablesDeletion"
              />
            </v-row>
            <v-row v-if="jobAsync.jobid != ''" class="mx-2">
              {{ jobAsync }}
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header> Multiples </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-chip @click="setScenario('monthly', 'multiple')" class="mx-2" label small>
                Monthly
              </v-chip>
              <v-chip @click="setScenario('esg', 'multiple')" class="mx-2" label small>
                ESG
              </v-chip>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="reqMult.end" dense label="end" />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="reqMult.duration"
                  dense
                  label="duration"
                  type="number"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="reqMult.diameter"
                  dense
                  label="diameter"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="reqMult.financial_services"
                  dense
                  label="fin ser"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="reqMult.pensions"
                  dense
                  label="pensions"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="reqMult.minority_preferred"
                  dense
                  label="minority"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="reqMult.reported"
                  dense
                  label="reported"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="reqMult.frequency"
                  dense
                  label="freq"
                  :items="freqOptions"
                  :item-text="freqOptions.text"
                  :item-value="freqOptions.value"
                />
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="reqMult.net_cash"
                  dense
                  label="net cash"
                  :items="boolOptions"
                  :item-text="boolOptions.text"
                  :item-value="boolOptions.value"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="reqMult.caption" dense label="caption" />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="reqMult.num_tickers_per_job"
                  dense
                  label="tickers per job"
                  type="number"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model.number="reqMult.queue_id"
                  dense
                  label="queue"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-row class="mx-2">
              <v-btn
                small
                outlined
                :disabled="tickerSet == ''"
                :loading="sendLoading"
                @click="sendMultiplesPipeline(reqMult)"
              >
                send
              </v-btn>
              <v-progress-circular
                v-if="loadingTablesDeletion > 0"
                size="15"
                color="offblack"
                :value="loadingTablesDeletion"
              />
            </v-row>
            <v-row v-if="jobAsync.jobid != ''" class="mx-2">
              {{ jobAsync }}
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-text v-if="openOverlay" :hidden="hidden" />

    <div :hidden="hidden">
      <v-card-actions class="mx-2">
        <v-row class="mb-1">
          <v-col cols="9" xs="9" />
          <v-col cols="3" xs="3">
            <v-btn v-if="!hidden" icon @click="hidden = !hidden">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { msalMixin } from 'vue-msal'
import { HTTP } from './../functions/http-common'
import Templates from '../functions/templates'

export default {
  name: 'Pipeline',
  mixins: [msalMixin],
  data: () => ({
    hidden: true,
    templates: new Templates(),
    alphabet: 'abcdefghijklmnopqrstuvwxyz',
    openOverlay: false,
    tickerSet: '',
    tickers: [],
    tickerMessage: '',
    sendLoading: false,
    jobAsync: '',
    sheduleTbl: '',
    loadingTablesDeletion: 0,
    betaTables: [
      'Step1DownloadStockPrices',
      'Step2CalculateLeveredBeta',
      'Step3DownloadDEratioKPIs',
      'Step4DownloadCreditSpreadKPIs',
      'Step5CalculateDERatio',
      'Step6CalculateDebtBeta',
      'Step7CalculateUnleveredBeta'
    ],
    multipleTables: ['Step1DownloadMultiplesKPIs', 'Step2CalculateMultiples'],
    req: {
      tickers: {
        retrieve: 'tickerInputs',
        filter: { set: '' },
        column: "ticker"
      },
      end: '2022-04-30',
      valdates_span: 3,
      frequency: ['W', 'M'],
      performance: 'False',
      international: 'False',
      regression_horizon: [2, 5],
      regression_method: 'Discrete',
      adjusted_beta: ['True', 'False'],
      financial_services: ['True', 'False'],
      pensions: 'True',
      net_cash: 'False',
      risk_of_debt_beta: ['True', 'False'],
      risk_of_tax_shield: ['True', 'False'],
      index_by_headquarter: true,
      use_total_debt: ['True', 'False'],
      include_lease_liability: 'True',

      num_tickers_per_job: 10,  
      caption: '',
      queue_id: 1
    },
    reqMult: {
      tickers: {
        retrieve: 'tickerInputs',
        filter: { set: '' },
        column: "ticker"
      },
      end: '2022-04-30',
      valdates_span: 3,
      diameter: 3,

      frequency: 'W',
      net_cash: 'True',
      reported: ['True', 'False'],

      financial_services: ['True', 'False'],
      pensions: ['True', 'False'],
      minority_preferred: ['True', 'False'],

      num_tickers_per_job: 10,
      caption: '',
      queue_id: 1
    },
    boolOptions: [
      { text: 'True', value: 'True' },
      { text: 'False', value: 'False' },
      { text: 'Both', value: ['True', 'False'] }
    ],
    selectFrequency: [
      { text: 'Weekly', value: 'w', disabled: false, default: true }
    ],
    freqOptions: [
      { text: 'M', value: 'M' },
      { text: 'W', value: 'W' },
      { text: 'Both', value: ['W', 'M'] }
    ],
    regHoOptions: [
      { text: 2, value: 2 },
      { text: 5, value: 5 },
      { text: 'Both', value: [2, 5] }
    ],
    regMeOptions: [
      { text: 'Discrete', value: 'Discrete' },
      { text: 'Continous', value: 'Continous' },
      { text: 'Both', value: ['Discrete', 'Continous'] }
    ]
  }),
  mounted() {
    // setting pipeline end date to last day of last month
    let d = new Date()
    d.setDate(1)
    d.setHours(-1)
    this.req.end = d.toISOString().slice(0, 10)
    this.reqMult.end = d.toISOString().slice(0, 10)
  },
  methods: {
    async sendMultiplesPipeline(req) {
      this.sendLoading = true
      // console.log(new TextEncoder().encode(JSON.stringify(req)).length/ 1024, "kb")
      req.tickers.filter.set = this.tickerSet
      console.log(req)
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await HTTP
        .post(
          '/pipeline/start/multiple/',
          req,
          config
        )
        .then(
          (response) => {
            console.log(response)
            this.jobAsync = response.data.message
            this.sendLoading = false
          },
          (error) => {
            console.log(error)
            this.sendLoading = false
          }
        )
    },
    async sendBetaPipeline(req) {
      this.sendLoading = true
      // console.log(new TextEncoder().encode(JSON.stringify(req)).length/ 1024, "kb")
      req.tickers.filter.set = this.tickerSet
      console.log(req)
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await HTTP
        .post(
          '/pipeline/start/unleveredbeta/',
          req,
          config
        )
        .then(
          (response) => {
            console.log(response)
            this.jobAsync = response.data.message
            this.sendLoading = false
          },
          (error) => {
            console.log(error)
            this.sendLoading = false
          }
        )
    },
    async deleteTables(tables) {
      this.loadingTablesDeletion = 1
      console.log(tables)
      for (const table in tables) {
        await this.$msal.acquireToken()
        const config = {
          headers: {
            Authorization: `Bearer ${this.$msal.data.accessToken}`
          },
          params: { delete_jobstatus: 'True' }
        }
        console.log(this.$backend + '/table/delete/' + tables[table])
        await HTTP
          .get('/table/delete/' + tables[table], config)
          .then(
            (response) => {
              this.loadingTablesDeletion = ((table + 1) / tables.length) * 100
              console.log(response.status, response.data.message)
            },
            (error) => {
              console.log(error.message)
            }
          )
      }
      this.loadingTablesDeletion = 0
    },
    setScenario(scenarioName, pipeline) {
      if(pipeline == 'beta') {
        Object.assign(this.req, this.templates.betaRuns[scenarioName])
      }
      if(pipeline == 'multiple') {
        Object.assign(this.reqMult, this.templates.multipleRuns[scenarioName])
      }
    },
    async askTickers(set) {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await HTTP
        .post(
          '/table/retrievebyrecords/tickerInputs',
          { filter: { set: [set] } },
          config
        )
        .then(
          (response) => {
            this.tickers = response.data.reduce((acc, value) => {
              if (!acc.includes(value.ticker)) {
                acc.push(value.ticker)
              }
              this.req.caption = set + '_b1'
              this.reqMult.caption = set + '_m1'
              return acc
            }, [])
            // this.req.tickers = response.data.reduce((acc, value) => { if (!acc.includes(value.ticker)) {acc.push(value.ticker);}return acc;}, [])
            this.req.num_jobs = Math.ceil(this.tickers.length / 12)
            if (!this.tickers.length) {
              this.tickerMessage = 'set not found'
            }
          },
          (error) => {
            this.tickerMessage = 'set not found'
            console.log(error)
          }
        )
    }
  }
}
</script>
