<template>
  <v-list
    v-if="jobs.trackingme[jobs.profileMail] != undefined"
    class="px-2"
    dense
  >
    <v-subheader>
        My Jobs <v-spacer />
        <v-btn
          color="offwhite"
          @click="filterMyJobs=!filterMyJobs"
          x-small
        >
          {{ filterMyJobs ? 'running' : 'finished' }}
        </v-btn>
    </v-subheader>
    <v-list-item
        v-for="(job, i) in jobs.trackingme[jobs.profileMail].filter(
        (a) => a.progress < 1 == filterMyJobs
        )"
        :key="i"
        link
    >
        <v-list-item-content @click="updatePipelineInfo(job.pipeline_job.caption)">
        <v-list-item-title class="text-caption" v-if="!job.message">
            {{ job.pipeline_job.function.replace('Pipeline.run(', '').replace(')', '') }}
            <span class="text-caption">{{ job.pipeline_job.caption }}</span>
        </v-list-item-title>
        <v-list-item-title v-else>
          {{ job.message }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="job.progress < 1" width="90vw">
            <v-progress-linear :value="job.progress * 100" />
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="!job.message">
            {{ new Date(job.pipeline_job.last_updated + 'Z').toLocaleString('de-DE') }} {{ (job.progress * 100).toFixed(0) }}%
        </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon  v-if="!job.message">
          <v-btn
            small
            icon
            @click="
             modal.isVisible = 1
             selectedTableId = job.pipeline_job.caption
            ">
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </v-list-item-icon>
        <confirmation-modal
          @close="modal.isVisible = 0"
          @delete="modal.isVisible = 0; deleteJob()"
          @deleteAll="modal.isVisible = 0; deleteAllJob()"
          :modal="modal"
        />
    </v-list-item>
  </v-list>
</template>

<script>
import ConfirmationModal from '../Common/ConfirmationModal.vue'
export default {
  name: 'SelfTracking',
  props: {
    jobs: {
      type: Object,
      required: true
    },
    openOverlay: {
      type: Boolean,
      required: true
    }
  },
  components: {
    confirmationModal: ConfirmationModal,
  },
  data: () => ({
    filterMyJobs: true,
    selectedTableId: '',
    modal: {
      isVisible: 0,
      heading: 'Confirm Delete',
      content: 'Please confirm if you want to proceed with delete functionality',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Delete', event: 'delete', color: 'error' },
        // { name: 'Delete All', event: 'deleteAll', color: 'error' }
      ]
    }
  }),
   methods: {
    async updatePipelineInfo(tableId) {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.$emit('on-update-overlay', true)
      this.jobs.pipelineFocus.name = tableId
      this.jobs.getPipelineInfo(tableId)
    },
    async deleteJob() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.jobs.deleteJob(this.selectedTableId, this.openOverlay)
    },
    async deleteAllJob() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.jobs.deleteAllJob(this.selectedTableId, this.openOverlay)
    }
   }
}
</script>
