import { HTTP } from './http-common'
import Templates from '../functions/templates'
import Excel from '../functions/excel'

class CFI {
  constructor() {
    this.backend = process.env.VUE_APP_BACKEND
    this.token = ''
    this.templates = new Templates()
    this.excel = new Excel()
    this.uploading = true
    this.config = {}
    this.errorDetails= {
      tickerInputs: {},
      //tickermeta: {},
      SupplementaryInfos: {}
    }
    this.successMessage = ''
    this.uploadProgress = 0
    this.loaderId= ''
    this.tabledata= {}
    this.filterCol = ''
    this.stepper = 1
    this.openOverlay = false
    this.modal= {
      isVisible: false,
      selectedId: 1,
      heading: 'Confirm Delete',
      content: '',
      error: '',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Confirm Delete', event: 'delete', color: 'error' }
      ]
    },
    this.uploadSheets= {
      SupplementaryInfos: { reading: false, range: '', len: 0 },
      //tickermeta: { reading: false, range: '', len: 0 },
      tickersmeta: { reading: false, len: 0 },
      tickerInputs: { reading: false }
    },
    this.validateRowsMessage = '',
    this.buffer = [],
    this.downloadLoading = false,
    this.emptyTableDetails = [],
    this.content = [[''], [''], ['Corporate Finance Insights'], [''], [''], ['']],
    this.stepsCompleted = [],
    this.progress = 0,
    this.snackbar = '',
    this.header = {}
  }

  _setToken() {
    this.config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }
  }

  async upload(data, tableName) {
    // data needs to be an array of dicts. Each dict represents one row.
    this.uploading = true
    this._setToken()
    let body = {
      table: tableName,
      data,
      orient: 'records'
    }
    await HTTP.post('/table/upload', body, this.config).then(
      (response) => {
        console.log(response)
      })
      .catch(({response}) => {
        console.log('data', data)
        if (response && response.status) {
          const { status } = response
            this.errorDetails[tableName][status] = this.errorDetails[tableName][status] ? this.errorDetails[tableName][status] + data.length : data.length
        } else {
          this.errorDetails[tableName]['Other'] = this.errorDetails[tableName]['Other'] ? this.errorDetails[tableName]['Other'] + data.length : data.length
        }
      }
    )
    this.uploading = false
  }

  decomposeRange(range) {
    // decomposes the range as string to [col, colNum, col, colNum]
    let rangeArray = range.match(/[a-zA-Z]+|[0-9]+/g)
    rangeArray[1] = Number(rangeArray[1])
    rangeArray[3] = Number(rangeArray[3])
    return rangeArray
  }

  async readTableData(range, sheetName, types = {}) {
    // types is a dict with key column name and value of Int or Float
    // String is default
    let rangeArray = this.decomposeRange(range)
    let data = []
    await window.Excel.run(async (context) => {
      let sheet = context.workbook.worksheets.getItem(sheetName)
      await context.sync()
      // header range starts at A1
      let headerRange = sheet
        .getRange('A1:' + rangeArray[2] + '1')
        .load('values')
        .load('text')
      let contentRange = sheet.getRange(range).load('values').load('text')
      await context.sync()
      let header = Object.values(headerRange.text[0])
      contentRange.text.forEach((rowElement) => {
        let row = {}
        rowElement.forEach((colElement, colNum) => {
          if (Object.keys(types).includes(header[colNum])) {
            if (types[header[colNum]] == 'Int') {
              row[header[colNum]] = Number(colElement)
            } else if (types[header[colNum]] == 'Float') {
              row[header[colNum]] = parseFloat(colElement)
            }
          } else {
            row[header[colNum]] = String(colElement)
          }
        })
        data.push(row)
      })
    })
    return data
  }

  rangeChunking(range, rowSize) {
    let rangeArray = this.decomposeRange(range)
    let chunks = Math.ceil((rangeArray[3] - 1) / rowSize) // -1 to substract header row
    let chunkArray = []
    for (let i = 0; i < chunks; i++) {
      let maxRow = Math.min(i * rowSize + rowSize + 1, rangeArray[3])
      chunkArray.push(
        rangeArray[0] +
          String(i * rowSize + 2) +
          ':' +
          rangeArray[2] +
          String(maxRow)
      )
    }
    return chunkArray
  }

  async uploadTable(range, sheetName, rows) {
    let chunks = this.rangeChunking(range, rows)
    this.errorDetails[sheetName] = {};
    this.loaderId = sheetName
    this.uploadProgress = 0.01 // show uploading progress icon
    for (const [index, chunk] of chunks.entries()) {
      let data = await this.readTableData(chunk, sheetName)
      await this.upload(data, sheetName)
      this.uploadProgress = ((index + 1) / chunks.length) * 100
    }
    await this.getTableEntries([sheetName])
    this.loaderId = ''
    this.uploadProgress = 0
  }

  async appendNewRows(name, newRows, isMessage = '', rows= 200) {
    newRows.forEach((a) => (a['PartitionKey'] = 'latest'))
    await this.uploadData(name, newRows, rows)
    await this.removePartition(name, 'staging')
    if (isMessage === 'append') this.successMessage = "Successfully completed Append action"
    else if (isMessage === 'update') await this.validateEntries(name)
    else { console.log('New rows appended')}
  }

  async uploadData(name, data, rows= 200, isMessage = false) {
    this.loaderId = name
    this.uploadProgress = 1
    this.errorDetails[name] = {}
    this._setToken()
    const rounds = Math.ceil(data.length / rows)
    for (let i = 0; i < rounds; i++) {
      let dataList = data.slice(i * rows, i * rows + rows)
      await this.upload(dataList, name)
      this.uploadProgress = ((i + 1) / rounds) * 100
    }
    this.uploadProgress = 100
    await HTTP.get('/table/retrievebyrecords/' + name, this.config).then(
      (response) => {
        response.data.map((entry) => {
          delete entry['RowKey']
          delete entry['etag']
          delete entry['Timestamp']
          delete entry['_IndCol']
          if (entry['PartitionKey'] == 'sample') {
            entry['PartitionKey'] = 'latest'
          }
        })
        this.tabledata['data'] = response.data
        this.tabledata['stag'] = response.data.filter(
          (a) => a['PartitionKey'] == 'staging'
        )
        if (isMessage) this.successMessage = "Successfully Uploaded on stage"
      }, (error) => { 
        console.log(error)
      })
    this.uploadProgress = 0
    this.loaderId = ''
  }
  async removePartition(table, PartitionKey, isMessage = false) {
    this._setToken()
    const body = {
      table_name: table,
      partition_key: PartitionKey
    }
    await HTTP.post('/table/clear', body, this.config).then(
      (response) => {
        console.log(response)
        this.modal.isVisible = false;
        if(isMessage) {
          this.successMessage = PartitionKey === 'staging' ? 'Successfully removed Staging data' : 'Successfully removed Production data'
        }
      },
      (error) => {
        console.log(error)
        this.modal.error = "Unable to delete, Please contact dev support!"
      }
    )
    await HTTP
    .get('/table/retrievebyrecords/' + table, this.config)
    .then(
      (response) => {
        response.data.map((entry) => {
          delete entry['RowKey']
          delete entry['etag']
          delete entry['Timestamp']
          delete entry['_IndCol']
          if (entry['PartitionKey'] == 'sample') {
            entry['PartitionKey'] = 'latest'
          }
        })
        this.tabledata['data'] = response.data
        this.tabledata['stag'] = response.data.filter(
          (a) => a['PartitionKey'] == 'staging'
        )
      },
      (error) => {
        console.log(error)
      }
    )
    await this.getTableEntries([table])
  }
  openModal(value) {
    this.modal.selectedId = value
    this.modal.isVisible = true
    switch (value) {
      case 1:
        this.modal.content = 'Are you sure you want to delete Industry Tables'
        break
      case 2:
        this.modal.content = 'Are you sure you want to delete KPI Tables'
        break
      case 3:
        this.modal.content = 'Are you sure you want to delete Meta Tables'
        break
      default:
    }
  }
  async getTableEntries(tableNames = [
    'tickersmeta',
    //'tickermeta',
     'SupplementaryInfos']) {
    this._setToken()
    tableNames.forEach(async (tableId) => {
      await HTTP
      .get('/table/stats/' + tableId, this.config)
      .then(
      (response) => {
        if (response && response.data) {
          this.uploadSheets = {
            ...this.uploadSheets,
            [tableId] : {
              ...this.uploadSheets[tableId],
              len: response.data.length
            }
          }
        }
      })
    })
  }
  async validateEntries(sheetName) {
    let difference = 0;
    let DBEntries = 0;
    const excelEntries = this.tabledata['data'].length
    this._setToken()
    await HTTP
    .get('/table/stats/' + sheetName, this.config)
    .then(
    (response) => {
      if (response && response.data && response.data.length) {
        DBEntries = response.data.length
        difference = excelEntries - DBEntries
      }
    })
    if (difference !==0) {
      this.validateRowsMessage = `Excel Entries: ${excelEntries} <br /> DB Entries: ${DBEntries} <br /> Mismatch found for ${difference} entries`
    } else {
      this.validateRowsMessage = `Excel Entries: ${excelEntries} <br /> DB Entries: ${DBEntries} <br /> No Mismatch found`
    }
  }

    async downloadTable(table, tickers, filterColumns, suffix, config, tableType= '', con) {
      this.downloadLoading = true
      
      let metadata = {};
      const sheetname = tableType === 'beta' ? this.templates.betaStepDict[table.substring(0, 5)] : this.templates.multipleStepDict[table.substring(0, 5)]
      const tableHeader = {
        [sheetname]: []
      }
      let body = {
        filter: {},
      };
      const noOfIterations = Math.ceil(tickers.length/5);
      for (const [key, value] of Object.entries(filterColumns)) {
        body.filter[key] = value
      }
      for(let iteration = 0; iteration < noOfIterations; iteration++) {
        const tickerlist = [];
        const PartitionKeyList = [];
        tickers.slice(iteration*5, (iteration*5)+5).forEach(ticker => {
          tickerlist.push(ticker);
        })
        if (tickerlist.length) body.filter['ticker'] = tickerlist;
        if (PartitionKeyList.length) body.filter['PartitionKey'] = PartitionKeyList;
        let tableName = table
        // if (table != 'tickersmeta'){ tableName = table + suffix}
        await HTTP
          .post('/table/retrievebyrecords/' + tableName, body, config)
          .then(
            async (response) => {
              const d = new Date()
              let resp = []
              // check Json in case of backend errors in parsing data
              if (Array.isArray(response.data)) {
                resp = response.data
              } else {
                resp = JSON.parse(response.data.replaceAll('NaN', '"0"'))
              }
              // check if data is parsed
              if (response.data.length === 0) {
                this.emptyTableDetails.push({tableName: table});
                console.log('no data: ', table)
              } else {
                metadata = {
                  sheetname,
                  id: suffix,
                  date: d.toISOString(),
                  scenario: con,
                }
                tableHeader[sheetname] = [...tableHeader[sheetname], ...resp]
              }
            },
            (error) => {
              this.downloadLoading = false
              // this.snackbar.showSnackbar(
              //   {
              //     message:  'API call failed',
              //     color: 'error',
              //     icon: 'mdi-alert'
              //   }
              // )
              console.log('error: ' + error)
            }
          )
      }
      this.downloadLoading && await this.writeToBuffer(
        tableHeader[sheetname],
        metadata
      )
      return
    }

    async downloadJobTable(caption, config, type, cbs) {
      let tickers = []
      const d = new Date()
      this.content[4] = [
        'ID: ' + caption,
        'Date: '.concat(d.toISOString())
      ]
      await HTTP
        .post('/pipeline/getstatus/' + caption, 
        {
          filter: {
            userid: config.user
          },
          include_joblist: "True"
        },
        config)
        .then((response) => {
          for (let tick in response.data[caption].joblist.valid_tickers) {
            if (!tickers.includes(response.data[caption].joblist.valid_tickers[tick])) {
              tickers = tickers.concat(JSON.parse(response.data[caption].joblist.valid_tickers[tick]))
            }
          }
        }, (error) => {
          console.log(error)
        })

      let i = 1
      let j = 1
      switch(type) {
        case 'Pipeline.run(unleveredbeta)': 
          for (let tbl in Object.values(this.templates.betaStepTables)) {
            let downloadSheet = false

            if(i == 1 && cbs.betas.dataSheets.stockPrices)
              downloadSheet = true
            else if(i == 2 && cbs.betas.dataSheets.leveredBeta)
              downloadSheet = true
            else if(i == 3 && cbs.betas.dataSheets.deRatioKPIs)
              downloadSheet = true
            else if(i == 4 && cbs.betas.dataSheets.creditSpread)
              downloadSheet = true
            else if(i == 5 && cbs.betas.dataSheets.deRatio)
              downloadSheet = true
            else if(i == 6 && cbs.betas.dataSheets.debtBeta)
              downloadSheet = true
            else if(i == 7 && cbs.betas.dataSheets.unleveredBeta)
              downloadSheet = true

            if(downloadSheet){
              await this.downloadTable(
                Object.values(this.templates.betaStepTables)[tbl] + '_' + caption,
                tickers,
                {},
                ' ' + caption,
                config,
                'beta'
              )
            }
            i++
          }
          return 1
        case 'Pipeline.run(multiple)':
          for (let tbl in Object.values(this.templates.multipleStepTables)) {
            let downloadSheet = false

            if(j == 1 && cbs.multiples.dataSheets.multipleKPIs)
              downloadSheet = true
            if(j == 2 && cbs.multiples.dataSheets.calculateTimeline)
              downloadSheet = true
            if(j == 3 && cbs.multiples.dataSheets.calculateMultiple)
              downloadSheet = true

            if(downloadSheet){
              await this.downloadTable(
                Object.values(this.templates.multipleStepTables)[tbl] + '_' + caption,
                tickers,
                {},
                ' ' + caption,
                config,
                ''
              )
            }
            j++
          }
          return 1
      } 
    }

    async downloadMultiples(tickers, scenarios, suffix, tableSuffix = '', config, checkBoxes) {
      console.log(scenarios)
      let con = ''
      let uniqueSteps = [];
      const tableType = 'multiple'
      this.emptyTableDetails = []
      Object.keys(scenarios).forEach(steps => {
        Object.keys(scenarios[steps]).forEach(key => {
          if(!uniqueSteps.includes(key)) {
            con += key + ':' + scenarios[steps][key][1] + ', '
          }
          uniqueSteps.push(key);
        })
      })
      const d = new Date()
      this.content[4] = ['ID: ' + suffix, 'Date: '.concat(d.toISOString())]
      this.content[5] = [con]
      this.stepsCompleted = []
      this.progress = 0
      // Step 0: Download Meta
      if(checkBoxes.multiples.dataSheets.meta){
        await this.downloadTable('tickersmeta', tickers, {}, suffix, config, tableType, [con])
      }
      
      // Step 1: Download Multiples KPIs
      if(checkBoxes.multiples.dataSheets.multipleKPIs){
        await this.downloadTable(
          'Step1DownloadMultiplesKPIs' + tableSuffix,
          tickers,
          scenarios['Step1'],
          suffix,
          config,
          tableType,
          [con]
        )
      }
      this.progress = 1 / 3

      // Step 2: Calculate Timeline
      if(checkBoxes.multiples.dataSheets.calculateTimeline){
        await this.downloadTable(
          'Step2CalculateTimeline' + tableSuffix,
          tickers,
          scenarios['Step2'],
          suffix,
          config,
          tableType,
          [con]
        )
      }
      this.progress = 2 / 3

      // Step 3: Calculate Multiples
      if(checkBoxes.multiples.dataSheets.calculateMultiple){
        await this.downloadTable(
          'Step3CalculateMultiples' + tableSuffix,
          tickers,
          scenarios['Step3'],
          suffix,
          config,
          tableType,
          [con]
        )
      }

      this.emptyTableDetails && this.emptyTableDetails.length && this.snackbar.showSnackbar(
        {
          message:  `${this.emptyTableDetails.map(item => item.tableName).join(', ')} : No Data Found`,
          color: 'error',
          icon: 'mdi-alert'
        }
      )
      return (this.progress = 1)
    }

  async downloadBeta(tickers, scenarios, suffix, tableSuffix = '', config, checkBoxes) {
    // helper function
    // await this.deleteSheets() // This is too general and dangerous for production!
    let con = ''
    const tableType = 'beta'
    this.emptyTableDetails = []
    Object.keys(scenarios['Step7']).forEach(function (key) {
      // console.log(key, scenarios["Step7"][key]);
      con = con + key + ': ' + scenarios['Step7'][key] + ', '
    })
    const d = new Date()
    this.content[4] = ['ID: ' + suffix, 'Date: '.concat(d.toISOString())]
    this.content[5] = [con]
    this.stepsCompleted = []
    // var accessToken = "";
    // // ToDo: possibility to change
    // this.$msal.acquireToken().then((response) => {
    //   accessToken = response.accessToken;
    // });
    // const config = {
    //   headers: { Authorization: `Bearer ${this.$msal.accessToken}` },
    // };

    this.progress = 0

    // Step 0: Download stock prices
    if(checkBoxes.betas.dataSheets.meta){
      await this.downloadTable('tickersmeta', tickers, {}, suffix, config, tableType, [con])
    }
    
    // Step 1: Download stock prices
    if(checkBoxes.betas.dataSheets.stockPrices){
      await this.downloadTable(
        'Step1DownloadStockPrices' + tableSuffix,
        tickers,
        scenarios['Step1'],
        suffix,
        config,
        tableType,
        [con]
      )
    }
    this.progress = 1 / 7

    // Step 2: Calculate Levered Beta
    if(checkBoxes.betas.dataSheets.leveredBeta){
      await this.downloadTable(
        'Step2CalculateLeveredBeta' + tableSuffix,
        tickers,
        scenarios['Step2'],
        suffix,
        config,
        tableType,
        [con]
      )
    }
    this.progress = 2 / 7

    // Step 3: Download DE Ratio KPIs
    if(checkBoxes.betas.dataSheets.deRatioKPIs){
      await this.downloadTable(
        'Step3DownloadDEratioKPIs' + tableSuffix,
        tickers,
        scenarios['Step3'],
        suffix,
        config,
        tableType,
        [con]
      )
    }
    this.progress = 3 / 7

    // Step 4: Download Credit spread KPIs
    if(checkBoxes.betas.dataSheets.creditSpread){
      await this.downloadTable(
        'Step4DownloadCreditSpreadKPIs' + tableSuffix,
        tickers,
        scenarios['Step4'],
        suffix,
        config,
        tableType,
        [con]
      )
    }
    this.progress = 4 / 7

    // Step 5: Calculate DE Ratio
    if(checkBoxes.betas.dataSheets.deRatio){
      await this.downloadTable(
        'Step5CalculateDERatio' + tableSuffix,
        tickers,
        scenarios['Step5'],
        suffix,
        config,
        tableType,
        [con]
      )
    }
    this.progress = 5 / 7

    // Step 6: Calculate Debt Beta
    if(checkBoxes.betas.dataSheets.debtBeta){
      await this.downloadTable(
        'Step6CalculateDebtBeta' + tableSuffix,
        tickers,
        scenarios['Step6'],
        suffix,
        config,
        tableType,
        [con]
      )
    }
    this.progress = 6 / 7

    // Step 7: Unlevered Beta
    if(checkBoxes.betas.dataSheets.unleveredBeta){
      await this.downloadTable(
        'Step7CalculateUnleveredBeta' + tableSuffix,
        tickers,
        scenarios['Step7'],
        suffix,
        config,
        tableType,
        [con]
      )
    }
    this.emptyTableDetails && this.emptyTableDetails.length && this.snackbar.showSnackbar(
      {
        message:  `${this.emptyTableDetails.map(item => item.tableName).join(', ')} : No Data Found`,
        color: 'error',
        icon: 'mdi-alert'
      }
    )
    return (this.progress = 1)
  }

  getRowNumber(chunk, row, workSheet){
    let rowNumber = 0
    for(let chunkIndex = 0; chunkIndex <= chunk; chunkIndex++){
      if(chunkIndex == chunk){ rowNumber += row }
      else{ rowNumber += workSheet[chunkIndex].length }
    }
    return rowNumber
  }

  getRowCount(sheet){
    let rowCount = 0
    for(let chunkIndex = 0; chunkIndex < sheet.length; chunkIndex++){
      rowCount += sheet[chunkIndex].length
    }
    return rowCount
  }

  async writeToBuffer(inputData, metadata){
    const sheetName = metadata.sheetname

    // fix metadata
    metadata.id = metadata.id.replace(/\s/g, '')

    // append head row to buffer
    const index = this.buffer.length
    if(!this.buffer[index]){
      this.buffer[index] = {metadata, data: []}
      this.buffer[index].metadata.sheetname = sheetName
      this.buffer[index].data.push([Object.keys(inputData[0])])
    }

    // add the data below the head row
    let data = this.buffer[index].data
      // figure out in wich column the ticker is
      let tc = 0
      let keys = Object.keys(inputData[0])
      for(tc = 0; tc < keys.length; tc++){
        if(keys[tc] == 'ticker')
          break
      }
    for(let i = 0; i < inputData.length; i++){
      // does a chunk with this ticker already exist?
      let tickerFound = false
      for(let j = 0; j < data.length; j++){
        if(data[j][0][tc] == Object.values(inputData[i])[tc]){
          tickerFound = true
          data[j].push(Object.values(inputData[i]))
        }
      }
      if(!tickerFound){
        data.push([Object.values(inputData[i])])
      }
    }

    let sheet = this.buffer[this.buffer.length - 1]
    //const { datasheetHeader_dark: headerTemplate , columnsToTrans: ctt } = this.templates
    let ctt = this.templates.columnsToTrans
    this.header.rows = new Array(this.templates.datasheetHeader_dark.rows.length)

    // generate dataOrdered array
    let dataOrdered = new Array(sheet.data.length)
    for(let i = 0; i < dataOrdered.length; i++){
      dataOrdered[i] = new Array(sheet.data[i].length)
      for(let j = 0; j < dataOrdered[i].length; j++){
        dataOrdered[i][j] = new Array(ctt.length)
      }
    }
    // reorder the columns and generate reorderMap
    for(let i = 0; i < sheet.data[0][0].length; i++){
      let headerIsInCTT = false
      for(let j = 0; j < ctt.length; j++){
        if(sheet.data[0][0][i] == ctt[j].field){
          headerIsInCTT = true
          for(let chunkIndex = 0; chunkIndex < dataOrdered.length; chunkIndex++){
            for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
              dataOrdered[chunkIndex][rowIndex][j] = sheet.data[chunkIndex][rowIndex][i]
            }
          }
        }
      }
      // append columns not found in CTT to the end
      if (!headerIsInCTT){ 
        for(let chunkIndex = 0; chunkIndex < dataOrdered.length; chunkIndex++){
          for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
            dataOrdered[chunkIndex][rowIndex].push(sheet.data[chunkIndex][rowIndex][i])
          }
        }
      }
    }

    // find meta sheet
    let metaSheet
    for(let i = 0; i < this.buffer.length; i++){
      if(String(this.buffer[i].metadata.sheetname).includes('Meta')){
        metaSheet = this.buffer[i]
      }
    }
    // delete empty spots or insert new columns from ctt
    for(let i = dataOrdered[0][0].length - 1; i >= 0; i--){
      if(dataOrdered[0][0][i] == null){
        if(ctt[i].formula.key){
          dataOrdered[0][0][i] = ctt[i].field
          if(metaSheet){
            for(let chunkIndex = 1; chunkIndex < dataOrdered.length; chunkIndex++){
              for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
                // get column and row of the current cell
                let column = this.excel.getColumnLetter(i+1)
                let row = this.header.rows.length + 1 + this.getRowNumber(chunkIndex, rowIndex, dataOrdered)

                // get important info about the meta sheet
                let refSheet = metaSheet.metadata.sheetname + ' ' + metaSheet.metadata.id
                let refSheetLastColumn = this.excel.getColumnLetter(metaSheet.data[0][0].length)
                let keyColumn
                if(ctt[i].formula.key){
                  for(let j = 0; j < ctt.length; j++){
                    if(ctt[j].field == ctt[i].formula.key){
                      keyColumn = this.excel.getColumnLetter(j+1)
                    }
                  }
                }
                let f1 = `=INDEX('${refSheet}'!A${this.header.rows.length+1}:${refSheetLastColumn}${this.header.rows.length+1+this.getRowCount(this.buffer[0].data)-1}, `
                let f2 =  `MATCH(${keyColumn}${row},'${refSheet}'!${keyColumn}${this.header.rows.length+1}:${keyColumn}${this.header.rows.length+1+this.getRowCount(this.buffer[0].data)-1},0), `
                let f3 =  `MATCH(${column}${this.header.rows.length+1},'${refSheet}'!A${this.header.rows.length+1}:${refSheetLastColumn}${this.header.rows.length+1},0))`
                dataOrdered[chunkIndex][rowIndex][i] = f1 + '\n' + f2 + '\n' + f3
              }
            }
          }else{
            for(let chunkIndex = 1; chunkIndex < dataOrdered.length; chunkIndex++){
              for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
                dataOrdered[chunkIndex][rowIndex][i] = '-'
              }
            }
          }
        }else{
          for(let chunkIndex = 0; chunkIndex < dataOrdered.length; chunkIndex++){
            for(let rowIndex = 0; rowIndex < dataOrdered[chunkIndex].length; rowIndex++){
              // delete column
              dataOrdered[chunkIndex][rowIndex].splice(i, 1)
            }
          }
        }
      }
    }

    // special formating for multiples data
    let valdateColumn
    for(let i = 0; i < dataOrdered[0][0].length; i++){
      if(dataOrdered[0][0][i] == 'ValDate')
        valdateColumn = i
    }
    for(let i = 1; i < dataOrdered.length; i++){
      for(let j = 0; j < dataOrdered[i].length; j++){
        //dataOrdered[i][j][valdateColumn] = new Date(dataOrdered[i][j][valdateColumn] + 'Z').toLocaleDateString('de-DE')
        //dataOrdered[i][j][valdateColumn] = String(dataOrdered[i][j][valdateColumn]).substring(0, 10)
        if(dataOrdered[i][j][valdateColumn] != null && dataOrdered[i][j][valdateColumn] != ''){
          let d = new Date(dataOrdered[i][j][valdateColumn])
          dataOrdered[i][j][valdateColumn] = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
        }
      }
    }

    this.buffer[this.buffer.length - 1].data = dataOrdered
  }
}

export default CFI