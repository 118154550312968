<template>
    <div v-if="Object.keys(errorDetails).length">
      <span class="red--text caption pl-4">Something went wrong!!!</span>
      <v-menu
        offset-y
        open-on-hover
        top
        v-if="info"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="pl-1" small v-on="on" v-bind="attrs" style="vertical-align: middle">
            mdi-information
          </v-icon>
        </template>
        <v-list >
          <v-list-item :key="key" v-for="[key, value] in Object.entries(errorDetails)">
            <v-list-item-title>{{`${key} : ${value} Rows Effected`}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
</template>
  
  <script>
  import { msalMixin } from 'vue-msal'
  export default {
    name: 'ErrorMessage',
    mixins: [msalMixin],
    props: {
      errorDetails: {
        type: Object,
        required: true
      },
      info: {
        type: Boolean,
        required: true
      }
    },
  }
  </script>
  