import { HTTP } from './http-common'
import humanizeDuration from 'humanize-duration'

class Jobs {
  constructor() {
    this.token = '',
    this.profileMail = ''
    this.loadingTablesDeletion = 0,
    this.pipelineFocus= {
      name: '',
      type: '',
      jobPage: 0,
      status: {
        failed: 0,
        running: 0,
        finished: 0,
        retried: 0
      },
      content: [],
      reduced: {},
      outputTables: []
    },
    this.pipeTypes= ['unleveredbeta', 'multiple'],
    this.pipelineError= '',
    this.tracking = {},
    this.trackingme= { me: [] },
    this.progress = false,
    this.subJobs = [],
    this.cleanupJobs = [],
    this.loader = {
      getCleanupJobs: 0,
      getSubJobs: 0
    }
  }

  async getPipelineStatus(caption) {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }
    await HTTP
      .get('/pipeline/getstatus/' + caption, config)
      .then(
        (response) => {
          return response.data
        },
        (error) => {
          console.log(error)
        }
      )
  }

  async getPipelineInfo(caption) {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }
    await HTTP
      .post('/pipeline/getstatus/' + caption,
      {
        "include_joblist": "True"
      },
      config)
      .then(
        (response) => {
          this.pipelineError = ''
          this.pipelineFocus.outputTables = []
          ;(this.pipelineFocus.status = {
            failed: 0,
            running: 0,
            finished: 0,
            retried: 0
          }),
            (this.pipelineFocus.jobPage = 0)
          this.pipelineFocus.content = response.data[caption]
          this.pipelineFocus.content.elapsed_seconds = humanizeDuration(response.data[caption].elapsed_seconds * 1000)
          this.pipelineFocus.content.joblist = Object.values(response.data[caption].joblist.message)
          this.jobs.pipelineFocus.name = caption
        },
        (error) => {
          this.pipelineError = 'not found'
          console.log(error)
        }
      )
  }

  sort(data) {
    const sortedList = data.sort((a,b) => Date.parse(b.last_updated) - Date.parse(a.last_updated))
    return sortedList
  }

  async getMyPipelines() {
    let me = {}
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }
    await HTTP
      .post(
        '/pipeline/getstatus/',
        {
          filter: {
            userid: this.profileMail
          }
        },
        config
      )
      .then((response) => {
        me[this.profileMail] =  Object.values(JSON.parse(response.data.replaceAll(NaN, ' ' + null)))
      }, (error) => {
        me[this.profileMail] = Object.values(JSON.parse(error.response.data.replaceAll(NaN, ' ' + null)))
      })
      this.trackingme = me
  }

  async getUserPipelines(user) {
    let me = {}
    Object.assign(me, this.tracking)
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }
    await HTTP
      .post(
        '/pipeline/getstatus/',
        {
          filter: {
            userid: user
          }
        },
        config
      )
      .then((response) => {
        me[user] =  Object.values(JSON.parse(response.data.replaceAll(NaN, ' ' + null)))
      }, (error) => {
        me[user] = Object.values(JSON.parse(error.response.data.replaceAll(NaN, ' ' + null)))
      })
      this.tracking= me
      console.log(this.tracking)
  }

  async getPipelines() {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    }
    await HTTP
      .post(
        '/table/retrievebyrecords/jobstatus',
        {
          filter: {
            function: ['Pipeline.run(unleveredbeta)', 'Pipeline.run(multiple)']
          }
        },
        config
      )
      .then(
        (response) => {
          const sortedList = (response && response.data && this.sort(response.data)) || [];
          this.tracking = sortedList.reduce((acc, value) => {
            // Group initialization
            if (
              acc[value.userid] == undefined &&
              value.userid != this.profileMail
            ) {
              acc[value.userid] = []
            }
            // Grouping
            if (value.userid != this.profileMail) {
              acc[value.userid].push(value)
            }
            return acc
          }, {})
        },
        (error) => {
          console.log(error)
        }
      )
  }
  update(openOverlay) {
    if (openOverlay) {
        this.getPipelineInfo(this.pipelineFocus.name)
    } else {
        this.getMyPipelines()
        this.getPipelines()
    }
  }

  async deleteTables(tables, isSubJobs = '') {
    this.loadingTablesDeletion = 0.1
    console.log(tables)
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      params: { delete_jobstatus: 'True' }
    }
    await HTTP
      .post('/table/delete',
      {
        tables
      },
      config)
      .then(
        (response) => {
          console.log(response.status, response.data.message)
        },
        (error) => {
          console.log(error.message)
        }
    )
    if (isSubJobs == 'subJobs') this.getSubJobs(this.profileMail)
    else if (isSubJobs === 'jobCleanUp') this.getJobsForCleanUp()
    this.loadingTablesDeletion = 0
  }
  
  async deleteAllJob(tableid, openOverlay) {
      this.progress = true
      let deleteTables = [tableid]
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
      await HTTP
        .get('/table/retrievebyrecords/' + tableid, config)
        .then(
          (response) => {
            deleteTables = deleteTables.concat(
              response.data.reduce((acc, value) => {
                if (
                  !acc.includes(value.temp_tableid) &&
                  value.temp_tableid != ''
                ) {
                  acc.push(value.temp_tableid)
                }
                return acc
              }, [])
            )
            deleteTables = deleteTables.concat(
              response.data.reduce((acc, value) => {
                if (
                  !acc.includes(value.output_tableid) &&
                  value.output_tableid != ''
                ) {
                  acc.push(value.output_tableid)
                }
                return acc
              }, [])
            )
          },
          (error) => {
            console.log(error)
          }
        )
      await this.deleteTables(deleteTables)
      this.progress = false
      return this.update(openOverlay)
    }

  async deleteJob(tableid, openOverlay) {
    this.progress = true
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`
      },
      params: { delete_jobstatus: 'True' }
    }
    await HTTP
      .post('/pipeline/delete/' + tableid,
      {
        filter: {
          userid: this.profileMail
        }
      },config)
      .then(
        (response) => {
          console.log(response.status, response.data.message)
        },
        (error) => {
          console.log(error.message)
        }
      )
    this.progress = false
    return this.update(openOverlay)
  }

  async getSubJobs(userid) {
    this.loader.getSubJobs = 1
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
      await HTTP
        .post(
          '/table/retrievebyrecords/jobstatus',
          {
            filter: {
              userid: userid,
              status: ['running', 'failed', 'finished']
            }
          },
          config
        )
        .then(
          (response) => {
            const sortedList = (response && response.data && this.sort(response.data)) || []
            this.subJobs = sortedList
            this.loader.getSubJobs = 0
          },
          (error) => {
            this.loader.getSubJobs = 0
            console.log(error)
          }
        )
    }
    async getJobsForCleanUp() {
      this.loader.getCleanupJobs = 1
      const config = {
        headers: {
          Authorization: `Bearer ${this.token}`
        }
      }
      await HTTP
        .get(
          '/table/retrievebyrecords/jobstatus',
          config
        )
        .then(
          (response) => {
            const sortedList = (response && response.data && this.sort(response.data)) || []
            this.cleanupJobs = sortedList
            this.loader.getCleanupJobs = 0
          },
          (error) => {
            this.loader.getCleanupJobs = 0
            console.log(error)
          }
        )
    }
}

export default Jobs