<template>
  <v-card tile>
    <v-row class="mt-0 pt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Connection
        </v-card-title>
        <v-card-subtitle class="mb-n4"> Admin </v-card-subtitle>
      </v-col>

      <v-col cols="3" class="mt-2">
        <v-btn v-if="hidden" icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn v-if="!hidden" icon small @click="update()">
          <v-icon small> mdi-reload </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text class="px-0" :hidden="hidden">
      <v-card
          class="mb-n4"
          width="100vw"
          flat
        >
        <v-card-text class="mb-4">
          <v-row class="ml-4">
            <div class="mt-2">
              Backend: {{ $backend }}
            </div>
          </v-row>
          <v-row class="ml-4">
            <div class="mt-2">
              Frontend: {{ $frontend }}
            </div>
          </v-row>
          <v-row class="ml-4">
            <div class="mt-2">
              Token: 
              <v-btn
                class="ma-2"
                x-small
                color="gray01"
                outlined
                @click="pasteToken()"
              >
              Paste Sheet
            </v-btn>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-text class="px-0" :hidden="hidden">
      <v-list dense>
        <v-list-item v-if="pipelines.length > 0">
          <v-list-item-content>
            <v-list-item-title v-text="pipelines.length" />
            <v-list-item-subtitle>Pipelines</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>
              {{
                (
                  pipelineDetails.filter((a) => a.error).length /
                  pipelines.length
                ).toFixed(2) * 100
              }}%
            </v-list-item-title>
            <v-list-item-subtitle>o/w crashed</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>
              {{
                (
                  pipelineDetails.filter((a) => a.retries > 0).length /
                  pipelines.length
                ).toFixed(2) * 100
              }}%
            </v-list-item-title>
            <v-list-item-subtitle>o/w retried</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="pipelines.length > 0">
          <v-list-item-content>
            <v-list-item-title
              v-text="pipelineDetails.filter((a) => a.error).length"
            />
            <v-list-item-subtitle>Crashed</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>
              {{
                (
                  pipelineDetails.filter((a) => a.failed > 0).length /
                  pipelineDetails.filter((a) => a.error).length
                ).toFixed(2) * 100
              }}%
            </v-list-item-title>
            <v-list-item-subtitle>o/w failed</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>
              {{
                (
                  pipelineDetails.filter((a) => a.timedOut > 0).length /
                  pipelineDetails.filter((a) => a.error).length
                ).toFixed(2) * 100
              }}%
            </v-list-item-title>
            <v-list-item-subtitle>o/w timed out</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <div :hidden="hidden">
      <v-card-actions class="mx-2">
        <v-row class="mb-1">
          <v-col cols="9" xs="9" />
          <v-col cols="3" xs="3">
            <v-btn v-if="!hidden" icon @click="hidden = !hidden">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { msalMixin } from 'vue-msal'
import { HTTP } from './../functions/http-common'

export default {
  name: 'JobStatistics',
  mixins: [msalMixin],
  data: () => ({
    hidden: true,
    alphabet: 'abcdefghijklmnopqrstuvwxyz',
    steps: ['unleveredbeta', 'multiples'],
    pipelines: [],
    pipelineDetails: [],
    users: []
  }),
  methods: {
    async copyText() {
      await this.$msal.acquireToken()
        /*
        Code is not working, need to find other appraoch.
        if (navigator.clipboard) {
        navigator.clipboard.writeText(this.$msal.data.accessToken)
          .then(() => {
            console.log('Text is on the clipboard.');
            this.message = 'Code copied to clipboard.';
          })
        .catch(e => {
          console.error(e);
          this.message = 'Sorry, unable to copy to clipboard.'
        });
      }*/
    },
    update() {
      this.getJobs()
    },
    async getDetailsJobs(joblist) {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      for (const table_temp in joblist) {
        HTTP
          .post(
            '/table/retrievebyrecords/' +
              joblist[table_temp]['tableid'],
            config
          )
          .then(
            (response) => {
              let entry = {
                id: joblist[table_temp]['tableid'],
                jobs: response.data.filter((a) => this.steps.includes(a.step))
                  .length,
                failed: response.data.filter((a) => a.status == 'failed')
                  .length,
                timedOut: response.data.filter((a) => a.status == 'timed out')
                  .length,
                num_tries: response.data
                  .filter((a) => this.steps.includes(a.step))
                  .reduce((a, b) => a + b.num_tries, 0),
                retries: entry['num_tries'] - entry['jobs'],
                error: entry['failed'] > 0 || entry['timedOut'] > 0,
                pipeline: joblist[table_temp]['function']
              }
              this.pipelineDetails.push(entry)
            },
            (error) => {
              console.log(error)
            }
          )
      }
    },
    async getJobs() {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await HTTP
        .post(
          '/table/retrievebyrecords/jobstatus',
          {
            filter: {
              function: ['ep_pipeline_unlevbeta', 'ep_pipeline_multiples']
            }
          },
          config
        )
        .then(
          (response) => {
            this.pipelines = response.data
          },
          (error) => {
            console.log(error)
          }
        )
      await this.getDetailsJobs(this.pipelines)
    },
    pasteToken() {
      window.Excel.run(async (context) => {
        let rangeAddress = 'A1:B1'
        let range = context.workbook.worksheets
          .getActiveWorksheet()
          .getRange(rangeAddress)
        range.load('values')
        await context.sync()
        if (range.values.findIndex((element) => element[0] != '') == -1) {
          if (range.values.findIndex((element) => element[1] != '') == -1) {
            range.values = [
              ['Token', this.$msal.data.accessToken]
            ]
          }
        }
        await context.sync()
      })
    },
  }
}
</script>
