<template>
  <v-card tile class="mb-6">
    <!-- Card Head -->
    <v-row class="mt-0 pt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-card-title class="text-subtitle-2 text-uppercase">
          Multiples
        </v-card-title>
        <v-card-subtitle class="mb-n4"> Equity Markets </v-card-subtitle>
      </v-col>

      <v-col cols="3" class="mt-2">
        <v-btn
          v-if="!hidden"
          color="offblack"
          icon
          small
          @click="download = !download"
        >
          <v-tooltip v-if="download" left nudge-bottom="50">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-download-circle </v-icon>
            </template>
            <span
              >Click to switch to calculation. <br />
              Now: Multiples will be downloaded from the database, as used in
              Power BI.</span
            >
          </v-tooltip>
          <v-tooltip v-else left nudge-bottom="50">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-calculator-variant </v-icon>
            </template>
            <span
              >Click to switch to download. <br />
              Now: Multiples will be calculated.</span
            >
          </v-tooltip>
        </v-btn>
        <v-btn v-else icon @click="hidden = !hidden">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-card-text class="px-0" :hidden="hidden">
      <!-- <v-row class="mx-4">
        <p class="text-caption">1. Select your settings.</p>
      </v-row> -->
      <v-row class="mx-4 pa-0">
        <p class="text-body-2">
          Do you want to download or calculate Multiples
        </p>
        <v-switch v-model="download" class="my-0 mx-2" color="gray01" dense>
          <template v-slot:label>
            <div class="text-body-2">
              {{ download ? 'Download' : 'Calculation' }}
            </div>
          </template>
        </v-switch>
      </v-row>
      <v-expansion-panels flat accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <v-row no-gutters>
              <v-col cols="8"> Net Debt </v-col>
              <v-col cols="4" class="text--secondary">
                <v-fade-transition leave-absolute>
                  <span v-if="open" key="0" />
                  <v-icon v-else key="1" small color="green">
                    mdi-check
                  </v-icon>
                </v-fade-transition>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-select
                  v-model="finServices"
                  :items="selectFinServices"
                  :item-text="selectFinServices.text"
                  :item-value="selectFinServices.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Financial Services</div>
                  </template>
                </v-select>
                <v-select
                  v-model="pension"
                  :items="selectPension"
                  :item-text="selectPension.text"
                  :item-value="selectPension.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item dense v-bind="attrs" v-on="on">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ item.text }}</span>
                            <v-spacer />
                            <v-chip
                              v-if="item.default"
                              color="gray01"
                              text-color="offwhite"
                              label
                              small
                            >
                              default
                            </v-chip>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:label>
                    <div class="text-caption">Pensions</div>
                  </template>
                </v-select>
              <v-select
                v-model="net_cash"
                :items="selectNet_Cash"
                :item-text="selectNet_Cash.text"
                :item-value="selectNet_Cash.value"
                dense
                class="text-body-2"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item dense v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ item.text }}</span>
                          <v-spacer />
                          <v-chip
                            v-if="item.default"
                            color="gray01"
                            text-color="offwhite"
                            label
                            small
                          >
                            default
                          </v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  <div class="text-caption">Net Cash</div>
                </template>
              </v-select>
              <v-select
                v-model="include_lease_liability"
                :items="selectLease_Liability"
                :item-text="selectLease_Liability.text"
                :item-value="selectLease_Liability.value"
                dense
                class="text-body-2"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item dense v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ item.text }}</span>
                          <v-spacer />
                          <v-chip
                            v-if="item.default"
                            color="gray01"
                            text-color="offwhite"
                            label
                            small
                          >
                            default
                          </v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  <div class="text-caption">Lease Liability</div>
                </template>
              </v-select>
              <v-select
                v-model="use_total_debt"
                :items="selectDebt_Approach"
                :item-text="selectDebt_Approach.text"
                :item-value="selectDebt_Approach.value"
                dense
                class="text-body-2"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item dense v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ item.text }}</span>
                          <v-spacer />
                          <v-chip
                            v-if="item.default"
                            color="gray01"
                            text-color="offwhite"
                            label
                            small
                          >
                            default
                          </v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  <div class="text-caption">Debt Approach</div>
                </template>
              </v-select>
              <v-select
                v-model="minority"
                :items="selectMinority"
                :item-text="selectMinority.text"
                :item-value="selectMinority.value"
                dense
                class="text-body-2"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item dense v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ item.text }}</span>
                          <v-spacer />
                          <v-chip
                            v-if="item.default"
                            color="gray01"
                            text-color="offwhite"
                            label
                            small
                          >
                            default
                          </v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  <div class="text-caption">
                    Minority Interest & Preferred Equity
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="8"> Timeline </v-col>
                <v-col
                  v-if="date != '' || download != false"
                  cols="4"
                  class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                    <span v-if="open" key="0" />
                    <v-icon v-else key="1" small color="green">
                      mdi-check
                    </v-icon>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <!-- <v-checkbox v-model="download" :disabled="disableDownload" dense>
                <template v-slot:label>
                  <div class="text-body-2">Download from Database</div>
                </template>
              </v-checkbox> -->
                <v-menu
                  v-if="!download"
                  ref="menu"
                  v-model="menu"
                  :disabled="download"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :disabled="download"
                      label="Qualifying Date"
                      prepend-icon="mdi-calendar"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    color="offblack"
                    first-day-of-week="1"
                    full-width
                    dense
                    no-title
                    scrollable
                  >
                    <v-spacer />
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-alert
                  v-if="new Date(date).getDay() != 5 && date != ''"
                  dense
                  outlined
                  dismissible
                  type="error"
                  class="text-caption"
                >
                  Qualifying Date will be set to last Friday before {{ date }}.
                </v-alert>
                <v-select
                  v-if="!download"
                  v-model="valdates_span"
                  :items="selectValdatesSpan"
                  :item-text="selectValdatesSpan.text"
                  :item-value="selectValdatesSpan.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:label>
                    <div class="text-caption">Historical Multiples</div>
                  </template>
                </v-select>
                <v-select
                  v-if="!download"
                  v-model="diameter"
                  :items="selectDiameter"
                  :item-text="selectDiameter.text"
                  :item-value="selectDiameter.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:label>
                    <div class="text-caption">Diameter</div>
                  </template>
                </v-select>
                <v-select
                  v-model="frequency"
                  :items="selectFrequency"
                  :item-text="selectFrequency.text"
                  :item-value="selectFrequency.value"
                  dense
                  class="text-body-2"
                >
                  <template v-slot:label>
                    <div class="text-caption">Frequency</div>
                  </template>
                </v-select>
                <v-select
                v-model="calendarised"
                :items="selectCalendarised"
                :item-text="selectCalendarised.text"
                :item-value="selectCalendarised.value"
                dense
                class="text-body-2"
              >
                <template v-slot:label>
                  <div class="text-caption">Calendarization</div>
                </template>
              </v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <add-tickers v-model="tickers" />
    </v-card-text>
    <div :hidden="hidden">
      <v-card-actions class="mx-2">
        <v-row class="mb-1">
          <!-- <v-col cols="6" xs="6">
            <v-btn small @click="readValues()">Select Tickers</v-btn>
          </v-col>
          <v-col cols="6" xs="6" align="end">
            <v-btn small depressed plain text tile disabled>
              <span v-if="tickers.length === 0">{{ tickers.length }} tickers</span>
              <span v-else class="primary--text">{{ tickers.length }} tickers</span>
            </v-btn>
          </v-col> -->
          <v-col cols="4" xs="4">
            <v-btn
              v-if="download"
              small
              :disabled="disabledSend"
              @click="modal.isVisible = 1; modal.isMultiplesPipeline = 1"
            >
              Download
            </v-btn>
            <v-btn
              v-else
              small
              :loading="computeAlert"
              :disabled="disabledSend || computeAlert"
              @click="computeMultiples()"
            >
              Compute
              <template v-slot:loader>
                <v-icon v-if="computeId != ''" small class="ml-2" color="green">
                  mdi-check
                </v-icon>
                <v-progress-circular v-else :width="3" indeterminate />
              </template>
            </v-btn>
          </v-col>
          <v-col cols="5" xs="5">
            <v-chip v-if="computeAlert" label small>
              {{ computeId }}
            </v-chip>
          </v-col>
          <v-col cols="3" xs="3">
            <v-btn v-if="!hidden" icon @click="hidden = !hidden">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </v-col>
          <!-- <v-col cols="3" xs="3">
          <v-btn small text @click="chartbuilding()"><v-icon>mdi-chart-line</v-icon></v-btn>
          </v-col>   -->
        </v-row>
      </v-card-actions>
    </div>
    <v-col v-if="progress.show" cols="12">
      <v-progress-linear indeterminate color="yellow accent-4" height="4" />
    </v-col>

    <h2 :hidden=true>{{ updateCheckBoxes }}</h2> <!-- required to call the computed function -->
    <confirmation-modal
      @close="modal.isVisible = 0"
      @download="downloadMultiples(); modal.isVisible = 0"
      :modal="modal"
    />
  </v-card>
</template>

<script>
import Excel from '../functions/excel'
import Templates from '../functions/templates'
import { v4 as uuidv4 } from 'uuid'
import { msalMixin } from 'vue-msal'
import { HTTP } from './../functions/http-common'
import AddTickers from './Common/AddTickers.vue'
import CFI from '../functions/cfi'
import ConfirmationModal from './Common/ConfirmationModal.vue'

export default {
  name: 'Multiples',
  components: {
    addTickers: AddTickers,
    confirmationModal: ConfirmationModal
  },
  mixins: [msalMixin],
  data: () => ({
    excel: new Excel(),
    cfi: new CFI(),
    templates: new Templates(),
    tickers: [],
    menu: false,
    hidden: true,
    computeAlert: false,
    alert: false,
    alertMessage: '',
    date: '',
    computeId: '',
    progress: {
      value: 0,
      query: false,
      show: false,
      interval: 0
    },
    download: undefined,
    disableDownload: false,
    frequency: 'M',
    finServices: 'False',
    pension: 'True',
    minority: 'True',
    kpis_for_multiples_new: '',
    calendarised: 'True',
    net_cash: 'True',
    include_lease_liability: 'True',
    use_total_debt: 'False',
    diameter: 1,
    scenario: {},
    valdates_span: 1,
    selectValdatesSpan: [
      { text: 'no history', value: 0, disabled: false, default: false },
      { text: '1 Years', value: 1, disabled: false, default: true },
      { text: '2 Years', value: 2, disabled: false, default: false },
      { text: '3 Years', value: 3, disabled: false, default: false },
      { text: '4 Years', value: 4, disabled: false, default: false }
    ],
    disableOptions: [
      { selectFrequency: 'W' },
      { selectPension: 'False' },
      { selectNet_Cash: 'False'},
      { selectLease_Liability: 'False' },
      // { selectDebt_Approach: 'True' },
      { selectMinority: 'False' },
    ],
    selectKPIs: [
      { text: '', value: '', disabled: false, default: false },
      { text: '', value: '', disabled: false, default: false }
    ],
    selectCalendarised: [
      { text: 'Calendar Years', value: 'True', disabled: false, default: false },
      { text: 'Fiscal Years', value: 'False', disabled: false, default: false }
    ],
    selectNet_Cash: [
      { text: 'Allow', value: 'True', disabled: false, default: true },
      { text: 'Don`t Allow', value: 'False', disabled: false, default: false }
    ],
    selectLease_Liability: [
      { text: 'include in Net Debt', value: 'True', disabled: false, default: true },
      { text: 'exclude in Net Debt', value: 'False', disabled: false, default: false }
    ],
    selectDebt_Approach: [
      { text: 'Total Debt', value: 'True', disabled: false, default: false },
      { text: 'Net Debt', value: 'False', disabled: false, default: true }
    ],
    selectDiameter: [
      { text: '1 Year', value: 1, disabled: false, default: false },
      { text: '2 Years', value: 2, disabled: false, default: false },
      { text: '3 Years', value: 3, disabled: false, default: false },
      { text: '4 Years', value: 4, disabled: false, default: false }
    ],
    selectFrequency: [
      { text: 'Weekly', value: 'W', disabled: false, default: false },
      { text: 'Monthly', value: 'M', disabled: false, default: true }
    ],
    selectFinServices: [
      {
        text: 'include in Net Debt',
        value: 'True',
        disabled: false,
        default: false
      },
      {
        text: 'exclude in Net Debt',
        value: 'False',
        disabled: false,
        default: true
      }
    ],
    selectPension: [
      {
        text: 'include in Net Debt',
        value: 'True',
        disabled: false,
        default: true
      },
      {
        text: 'exclude in Net Debt',
        value: 'False',
        disabled: false,
        default: false
      }
    ],
    selectMinority: [
      { text: 'Add to Enterprise Value', value: 'True', disabled: false, default: true },
      { text: 'Do not add', value: 'False', disabled: false, default: false }
    ],
    modal: {
      isVisible: 0,
      isBetaPipeline: 0,
      isMultiplesPipeline: 1,
      metaSheetAvailable: 1,
      cbs: {
        betas: {
          dataSheets: {
            meta: 0,
            stockPrices: 0,
            leveredBeta: 0,
            deRatioKPIs: 0,
            creditSpread: 0,
            deRatio: 0,
            debtBeta: 0,
            unleveredBeta: 0,
          },
          templates: {
            beta: 0,
            chart: 0,
          }
        },
        multiples: {
          dataSheets: {
            meta: 1,
            multipleKPIs: 1,
            calculateTimeline: 1,
            calculateMultiple: 1,
          },
          templates: {
            chart: 1,
          },
        },
      },
      heading: 'Select Tables',
      content: 'Please select the tables you wish to download',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Download', event: 'download', color: '' },
      ],
      job: {}
    }
  }),
  computed: {
    updateCheckBoxes(){
      this.updateCheckBoxes_pt2()
      return null
    },
    multiples() {
        return { status: true }
    },
    disabledSend() {
      return !(this.tickers.length && (this.date || this.download))
    },
  },
  watch: {
    alert(value) {
      if (value) {
        setTimeout(() => {
          this.alert = false
          console.log('hide alert after 2 seconds')
        }, 2000)
      }
    },
    computeAlert(value) {
      if (value) {
        setTimeout(() => {
          this.computeAlert = false
          this.computeId = ''
        }, 5000)
      }
    }, 
    download(dl){
      if(dl){
        this.disableOptions.forEach((option) => {
          let key = Object.keys(option)[0]
          let value = Object.values(option)[0]
          switch (key) {
            case 'selectFrequency':
              this.selectFrequency.find((a) => a.value == value).disabled = true
              break
            case 'selectPension':
              this.selectPension.find((a) => a.value == value).disabled = true
              break
            case 'selectNet_Cash':
              this.selectNet_Cash.find((a) => a.value == value).disabled = true
              break
            case 'selectLease_Liability':
              this.selectLease_Liability.find((a) => a.value == value).disabled = true
              break
            case 'selectDebt_Approach':
              this.selectDebt_Approach.find((a) => a.value == value).disabled = true
              break
            case 'selectMinority':
              this.selectMinority.find((a) => a.value == value).disabled = true
              break
          }
        })
      }
      else {
        this.disableOptions.forEach((option) => {
          let key = Object.keys(option)[0]
          let value = Object.values(option)[0]
          switch (key) {
            case 'selectFrequency':
              this.selectFrequency.find((a) => a.value == value).disabled = false
              break
            case 'selectPension':
              this.selectPension.find((a) => a.value == value).disabled = false
              break
            case 'selectNet_Cash':
              this.selectNet_Cash.find((a) => a.value == value).disabled = false
              break
            case 'selectLease_Liability':
              this.selectLease_Liability.find((a) => a.value == value).disabled = false
              break
            case 'selectDebt_Approach':
              this.selectDebt_Approach.find((a) => a.value == value).disabled = false
              break
            case 'selectMinority':
              this.selectMinority.find((a) => a.value == value).disabled = false
              break
          }
        })
      }
    }
  },
  mounted() {
    this.download = true
  },
  methods: {
    updateCheckBoxes_pt2(){
      if(this.modal.cbs.multiples.templates.chart){
        this.modal.cbs.multiples.dataSheets.calculateMultiple = 1
      }
    },
    async downloadMultiples() {
      this.setScenarios()
      let suffix = ' ' + String(uuidv4()).substring(0, 7)
      this.progress.show = true
      this.cfi.snackbar = this.$root.snackbar
      let tableSuffix = ''
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`,
        }
      }
      let tickerlist = this.excel.getTickerList(this.tickers)
      await this.cfi.downloadMultiples(
        tickerlist,
        this.scenario,
        suffix,
        tableSuffix,
        config,
        this.modal.cbs
      )
      await this.excel.pasteSheets(this.cfi.buffer, this.templates.datasheetHeader_dark, this.modal, this.$root.snackbar)
      this.cfi.buffer = []
      this.progress.show = false
    },
    async computeMultiples() {
      let {
        date,
        valdates_span,
        frequency,
        finServices,
        pension,
        minority,
        calendarised,
        net_cash,
        include_lease_liability,
        use_total_debt,
        diameter
      } = this
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      const suffix = String(uuidv4()).substring(0, 11).replaceAll('-', '')
      let tickerlist = this.excel.getTickerList(this.tickers)
      let parameters = {
        tickers: tickerlist,
        end: date,
        valdates_span,

        frequency,
        calendarised,

        financial_services: finServices,
        pensions: pension,
        minority_preferred: minority,
        net_cash,
        include_lease_liability,
        use_total_debt,
        diameter,

        num_tickers_per_job: 5,
        caption: suffix,
        queue_id: 0
      }
      HTTP
        .post('/pipeline/start/multiple/', parameters, config)
        .then(
          (response) => {
            if (response.data) {
              this.computeAlert = true
              this.computeId = suffix
            }
          },
          (error) => {
            console.log(error)
          }
        )
    },
    setScenarios() {
      const {
        frequency,
        pension,
        minority,
        finServices,
        net_cash,
        include_lease_liability,
        use_total_debt,
      } = this
      this.scenario = {
        Step1: {
          frequency
        },
        Step2:{
          
        },
        Step3: {
          Pensions: [null, pension],
          MinorityPreferred: [null, minority],
          FinancialServices: [null, finServices],
          NetCash: [null, net_cash],
          IncludeLeaseLiability: [null, include_lease_liability],
          UseTotalDebt: [null, use_total_debt]
        }
      }
    },
    resetDefault() {
      this.date = ''
      this.disableDownload = false
      this.finServices = 'not include in Net Debt'
      this.pension = 'include in Net Debt'
      this.multiples = 'False'
      this.valdates_span = 3
    }
  }
}
</script>
