<template>
  <div>
    <v-row class="mt-0 pt-0">
      <v-col cols="2" class="mt-0 pt-0">
        <v-btn icon small @click="openJobCleanupLocal = !openJobCleanup">
          <v-icon small> mdi-arrow-left-thick </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="5" class="mt-0 pt-0">
        <v-btn :disabled="!tableImpacted" icon small @click="paste(filteredList, '(0) Jobs list')">
          <v-icon small> mdi-download </v-icon>
        </v-btn>
        <v-btn
          :disabled="jobs.loadingTablesDeletion > 0 || !tableImpacted"
          small
          icon
          @click="modal.isVisible = 1"
        >
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="5" class="mt-0 pt-0">
        <v-progress-circular
          v-if="jobs.loadingTablesDeletion > 0"
          size="15"
          color="offblack"
          :value="jobs.loadingTablesDeletion"
        />
      </v-col>
    </v-row>
    <v-text-field
      label="Table Name"
      v-model="tableName"
    ></v-text-field>
    <v-text-field
      label="'Last Updated' older than (in days)"
      v-model="days"
    ></v-text-field>
    <v-btn
      color="offwhite"
      class="my-2"
      @click="getFilteredData"
      x-small
    >
      Apply Filters
    </v-btn>
    <div>
      User Impacted: {{ userImpacted }}
    </div>
    <div>
      Tables Impacted: {{ tableImpacted }}
    </div>
    <confirmation-modal
      @close="modal.isVisible = 0"
      @delete="modal.isVisible = 0; deleteTables(); getFilteredData()"
      :modal="modal"
    />
  </div>
</template>

<script>
import ConfirmationModal from '../Common/ConfirmationModal.vue'

export default {
  name: 'JobCleanup',
  components: {
    confirmationModal: ConfirmationModal
  },
  props: {
    openJobCleanup: {
      type: Boolean,
      required: true
    },
    jobs: {
      type: Object,
      required: true
    },
    excel: {
      type: Object,
      required: true
    },
    cfi: {
      type: Object,
      required: true
    },
    templates: {
      type: Object,
      required: true
    }
  },
  model: {
      prop: 'openJobCleanup',
      event: 'openJobCleanupChange'
  },
  computed: {
    openJobCleanupLocal: {
      get: function() {
          return this.openJobCleanup
      },
      set: function(value) {
          this.$emit('openJobCleanupChange', value)
      }
    },
  },
  data: () => ({
    jobpage: 1,
    modal: {
      isVisible: 0,
      heading: 'Confirm Delete',
      content: 'Please confirm if you want to proceed with deleting Temp tables',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Delete', event: 'delete', color: 'error' },
      ]
    },
    tableName: 'TEMP',
    days: 30,
    filteredList: [],
    userImpacted: 0,
    tableImpacted: 0
  }),
  mounted() {
    this.getFilteredData()
  },
  methods: {
    async paste(data, sheetname) {
      if (data.length) {
        const metadata = {
        sheetname: sheetname
      }
      await this.cfi.writeToBuffer(
        data,
        metadata,
      )
      await this.excel.pasteSheets(this.cfi.buffer, this.cfi.header,  this.$root.snackbar)
      this.cfi.buffer = []
      }
    },
    getFilteredData() {
      if (!this.tableName && !this.days) {
          this.filteredList = this.jobs.cleanupJobs
      }
        else if (!this.days){
          this.filteredList = this.jobs.cleanupJobs.filter(item => item.tableid.toLowerCase().includes(this.tableName.toLowerCase()))
        } else {
          const currentDate = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
          this.filteredList = this.jobs.cleanupJobs.filter(item => {
            const diffTime = Math.abs(new Date(currentDate).getTime() - new Date(Date.parse(item.last_updated)).getTime())
            const diffDays = Math.ceil(diffTime/ (1000*60*60*24))          
            return item.tableid.toLowerCase().includes(this.tableName.toLowerCase()) && diffDays > this.days
        })
      }
      this.userImpacted = Array.from(new Set(this.filteredList.map((item) => item.userid))).length
      this.tableImpacted = Array.from(new Set(this.filteredList.map((item) => item.tableid))).length
    },
    async deleteTables() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      this.jobs.deleteTables(
        this.filteredList
          .reduce((acc, value) => {
            acc.push(value.tableid)
            return acc
          }, []), 'jobCleanUp')
    }
  }
}
</script>
