<template>
  <div>
    <v-list-item
      v-for="(sheet, i) in sheets.filter((a) =>
        updateTablesList.includes(a.name)
      )"
      :key="i"
      link
      @click="
        cfi.openOverlay = true
        cfi.tabledata = sheet
        cfi.filterCol = updateTables[sheet.name]
        cfi.successMessage = ''
      "
    >
      <v-list-item-content>
        <v-list-item-title
          v-if="sheet.data != undefined"
          class="text-caption"
          v-text="sheet.name"
        />
        <v-list-item-title
          v-else
          class="text-caption gray01--text"
          v-text="sheet.name"
        />
        <v-list-item-subtitle v-if="sheet.data != undefined">
          <v-chip
            label
            x-small
            :text-color="
              sheet.excel.length - sheet.data.length >= 0
                ? 'greendark'
                : 'reddark'
            "
            :color="
              sheet.excel.length - sheet.data.length >= 0
                ? 'greenbg'
                : 'redbg'
            "
          >
            {{ sheet.excel.length - sheet.data.length > 0 ? '+' : ''
            }}{{ sheet.excel.length - sheet.data.length }} Rows
          </v-chip>
          <v-chip label x-small class="mx-2">
            {{ Object.keys(sheet.excel[0]).length }}/{{
              Object.keys(sheet.data[0]).length
            }}
            Cols
          </v-chip>
          <v-chip
            v-if="sheet.stag.length > 0"
            label
            x-small
            text-color="orangedark"
            color="orangelight"
          >
            S
          </v-chip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>

export default {
  name: 'VMETables',
  props: {
    sheets: {
      type: Array,
      required: true
    },
    updateTablesList: {
      type: Array,
      required: true
    },
    updateTables: {
      type: Object,
      required: true
    },
    cfi: {
      type: Object,
      required: true
    }
  }
}
</script>