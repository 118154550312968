<template>
  <div>
    <v-row class="mt-0 pt-0">
      <v-col cols="2" class="mt-0 pt-0">
        <v-btn
          icon
          small
          @click="
            openOverlayLocal=!openOverlay
            jobs.pipelineFocus= {...jobs.pipelineFocus, content: [], reduced: []}
          "
        >
          <v-icon small> mdi-arrow-left-thick </v-icon>
        </v-btn>
      </v-col>
      <!-- <v-col cols="2" class="mt-0 pt-0">
        <v-btn
          icon
          small
          @click="
            paste(
              jobs.pipelineFocus.content,
              'inspect_' + jobs.pipelineFocus.name.substring(4, 10) + '_1'
            )
          "
        >
          <v-icon small> mdi-download </v-icon>
        </v-btn>
      </v-col> -->
    </v-row>
    <v-list dense>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title
            class="mx-0 text-caption"
            v-if="jobs.pipelineFocus.content"
          >{{ jobs.pipelineFocus.content.pipeline_job.function.replace('Pipeline.run(', '').replace(')', '') }}</v-list-item-title>
          <v-list-item-subtitle
            class="mx-0 text-caption"
          >
            {{jobs.pipelineFocus.name}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ jobs.pipelineFocus.content.elapsed_seconds }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content
          v-if="jobs.pipelineFocus.content"
        >
          <v-list-item-title class="text-caption">
            {{ jobs.pipelineFocus.content.num_overall }}
          </v-list-item-title>
          <v-list-item-subtitle>Total</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.pipelineFocus.content.num_running }}
          </v-list-item-title>
          <v-list-item-subtitle>Now</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.pipelineFocus.content.num_finished }}
          </v-list-item-title>
          <v-list-item-subtitle>Done</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.pipelineFocus.content.num_restarts }}
          </v-list-item-title>
          <v-list-item-subtitle>Retries</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="jobs.pipelineFocus.content">
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.pipelineFocus.content.num_failed }}
          </v-list-item-title>
          <v-list-item-subtitle>Failed</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.pipelineFocus.content.num_finally_failed }}
          </v-list-item-title>
          <v-list-item-subtitle>Finally Failed</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title class="text-caption">
            {{ jobs.pipelineFocus.content.progress * 100}}%
          </v-list-item-title>
          <v-list-item-subtitle>Progress</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- <pagination
      :isPagination="jobs.pipelineFocus.content.joblist.length > 10"
      :noOfPages="Math.ceil(jobs.pipelineFocus.content.joblist.length / 10)"
      v-model="jobs.pipelineFocus.content.joblist"
      /> -->
  </div>
</template>

<script>

// import OutputTable from './OutputTable.vue'
// import JobStatus from './JobStatus.vue'
// import Pagination from './../../Common/Pagination.vue'
export default {
  name: 'PipelineDetails',
  components: {
    // outputTable: OutputTable,
    // jobStatus: JobStatus,
    // pagination: Pagination
  },
  model: {
      prop: 'openOverlay',
      event: 'openOverlayChange'
  },
  computed: {
    openOverlayLocal: {
      get: function() {
          return this.openOverlay
      },
      set: function(value) {
          this.$emit('openOverlayChange', value)
      }
    }
  },
  props: {
    jobs: {
      type: Object,
      required: true
    },
    openOverlay: {
      type: Boolean,
      required: true
    },
    excel: {
      type: Object,
      required: true
    },
    cfi: {
      type: Object,
      required: true
    },
    templates: {
      type: Object,
      required: true
    }
  },
  methods: {
    async paste(data, sheetname) {
      const metadata = {
        sheetname
      }
      await this.cfi.writeToBuffer(
        data.joblist,
        metadata,
      )
      await this.excel.pasteSheets(this.cfi.buffer, this.cfi.header, this.$root.snackbar)
      this.cfi.buffer = []
    },
  }
}
</script>
