<template>
  <div>
    <v-row class="mx-4 mt-2 mr-6">
      <p class="text-body-2">
        Companies <span v-if="tickersLocal.length">({{ tickersLocal.length }})</span>
      </p>
      <v-spacer />
      <p class="text-caption font-weight-medium">
        <v-fade-transition>
          <v-chip v-if="alert" x-small color="error">
            {{ alertMessage }}
          </v-chip>
        </v-fade-transition>
      </p>
      <v-spacer />
      <v-btn v-if="tickersLocal.length" x-small icon @click="readValues()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn v-if="tickersLocal.length" x-small icon @click="tickersLocal = []">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-row>
    <div class="mx-4 d-flex flex-wrap">
      <v-card
        v-for="(ticker, i) in tickersLocal.slice(0, 14)"
        :key="i"
        tile
        class="d-flex align-center"
        style="margin: 1px 1px 1px 1px"
        width="90"
        height="25"
        outlined
        @click="removeTicker(ticker)"
      >
        <v-card-text
          class="pa-0 d-flex justify-center"
          style="font-size: 0.6rem; font-weight: 600"
        >
          {{ ticker }}
        </v-card-text>
      </v-card>
      <v-card
        v-if="tickersLocal.length > 14"
        tile
        class="d-flex align-center"
        style="margin: 1px 1px 1px 1px"
        width="90"
        height="25"
        outlined
      >
        <v-card-text
          class="pa-0 d-flex justify-center"
          style="font-size: 0.8rem; font-weight: 600"
        >
          + {{ tickersLocal.length - 14 }}
        </v-card-text>
      </v-card>
      <v-card v-if="!tickersLocal.length" class="mb-n4" width="100vw" flat>
        <v-card-text class="pa-0 d-flex justify-center">
          <v-row class="d-flex align-center justify-center mx-4">
            <div class="text-center text-caption gray01--text mt-4">
              Select your tickers (Exchange:Company) in spreadsheet and click on
              "ADD TICKERS" below to add them. <br />
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="ma-2"
            x-small
            color="gray01"
            outlined
            @click="readValues()"
          >
            Add Tickers
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { msalMixin } from 'vue-msal'
export default {
  name: 'AddTickers',
  mixins: [msalMixin],
  data: () => ({
    alert: false,
    alertMessage: '',
  }),
  props: {
    tickers: {
      type: Array,
      required: true
    }
  },
  model: {
      prop: 'tickers',
      event: 'tickersChange'
  },
  computed: {
    tickersLocal: {
      get: function() {
          return this.tickers
      },
      set: function(value) {
          this.$emit('tickersChange', value)
      }
    }
  },
  watch: {
    alert(value) {
      if (value) {
        setTimeout(() => {
          this.alert = false
          console.log('hide alert after 2 seconds')
        }, 2000)
      }
    },
  },
  methods: {
    readValues() {
      window.Excel.run(async (context) => {
        const range = context.workbook.getSelectedRange()
        range.load()
        context.sync().then(() => {
        range &&
          range.values &&
          range.values.forEach((item) => {
            item &&
              item.forEach((rangeValue) => {
                const isColon = rangeValue.includes(':')
                const inRange = this.tickersLocal.includes(rangeValue)
                if (
                  rangeValue &&
                  isColon &&
                  !inRange &&
                  this.tickersLocal.length < 100
                ) {
                  this.tickersLocal.push(rangeValue)
                } else if (rangeValue && !isColon) {
                  this.alert = true
                  this.alertMessage = 'Ticker Format'
                } else if (inRange) {
                  this.alert = true
                  this.alertMessage = 'Duplicate'
                }
              })
          })
      }).catch(err => {
        const errorPayload = {
          message:  err,
          color: 'error',
          icon: 'mdi-alert'
        }
        this.$root.snackbar.showSnackbar(errorPayload)
      })
    })
    },
    removeTicker(ticker) {
      const tickerIndex = this.tickersLocal.indexOf(ticker)
      this.tickersLocal.splice(tickerIndex, 1)
    }
  }
}
</script>
