<template>
  <v-app>
    <v-app-bar
      dark
      absolute
      src="./assets/industry_cropped.jpg"
      app
      short
      :flat="downloadMenu"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top, rgba(246,246,250,.4), rgba(46,46,56,.9)"
        ></v-img>
      </template>
      <v-toolbar-title>Corporate Finance Insights</v-toolbar-title>
      <v-spacer></v-spacer>
      <Auth v-if="this.msal.isAuthenticated" />
      <template>
        <v-fab-transition>
          <v-btn
            color="offwhite"
            v-if="this.msal.isAuthenticated"
            @click="
              downloadMenu = !downloadMenu
              getJobs()
            "
            fab
            x-small
            absolute
            bottom
            left
          >
            <v-icon v-if="!downloadMenu" color="offblack">mdi-download</v-icon>
            <v-icon v-else color="offblack">mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
    </v-app-bar>

    <v-main class="offwhite">
      <v-expand-transition>
        <v-card tile v-show="downloadMenu">
          <v-card-title
            ><v-spacer />
            <v-icon small @click="toggleFilter(-1)">mdi-chevron-left</v-icon>
            <v-chip x-small @click="toggleFilter(1)">{{
              jobFilterOptions[jobFilter].option
            }}</v-chip>
            <v-icon small @click="toggleFilter(1)">mdi-chevron-right</v-icon>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-list class="mt-n4" dense>
                  <v-list-item v-if="jobsFiltered.length == 0">
                    <v-list-item-content>
                      <v-list-item-title class="gray01--text text-center"
                        >Nothing...</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="job in jobsFiltered" :key="job.jobid">
                    <v-list-item-content>
                      <!-- <v-list-item-title v-if="job.s != undefined">{{ job.title }}</v-list-item-title> -->
                      <v-list-item-title>
                        <v-tooltip left nudge-bottom="50">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-if="job.num_finally_failed > 0" v-bind="attrs" v-on="on" x-small color="error">{{job.num_finally_failed}}</v-chip>
                          </template>
                          <span>
                            {{ job.num_finally_failed }} out of {{ job.num_overall }}batches finnaly failed. Data might be incomplete!
                          </span>
                        </v-tooltip>
                        {{job.pipeline_job.function.replace('Pipeline.run(', '').replace(')', '') }} <span class="text-caption">{{ job.pipeline_job.caption }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="job.progress != 1.0"
                        width="90vw"
                      >
                        <v-progress-linear :value="job.progress * 100" />
                        {{ new Date(job.pipeline_job.last_updated + 'Z').toLocaleString('de-DE') }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>
                        {{ new Date(job.pipeline_job.last_updated + 'Z').toLocaleString('de-DE') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-btn
                        small
                        :disabled="
                          job.progress != 1.0 && job.progress != 0.0
                        "
                        @click="
                          sheet = !sheet
                          jobInfo = job
                        "
                        icon
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn
                        small
                        v-if="!cfi.downloadLoading"
                        :disabled="
                          job.progress != 1.0
                        "
                        @click="showConfirmationModal(job)"
                        icon
                      >
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>
                      <div class="pt-1 pl-1" v-if="cfi.downloadLoading && selectedTable == job.caption">
                        <v-progress-circular
                          size="15"
                          color="offblack"
                          indeterminate
                        />
                      </div>
                    </v-list-item-icon>

                    <h2 :hidden=true>{{ updateCheckBoxes }}</h2> <!-- required to call the computed function -->
                    <confirmation-modal
                      @close="modal.isVisible = 0"
                      @download="getTable(); modal.isVisible = 0"
                      :modal="modal"
                    />
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expand-transition>
      <v-spacer v-show="!downloadMenu" class="mt-4" />
      <v-container v-if="!this.msal.isAuthenticated">
        <v-row class="my-4 d-flex justify-center offwhite">
          <v-btn @click="signIn()">Sign In</v-btn>
        </v-row>
        <v-row class="my-4 d-flex justify-center offwhite">
          <v-btn text class="gray02--text" @click="href()">Sign Out</v-btn>
        </v-row>
      </v-container>
      <v-container v-else class="offwhite">
        <BetaDoc v-if="beta" />
        <Multiples v-if="multiples" />

        <v-subheader v-if="admin">
          Admin {{$isDevelopment ? ': Development Mode' : ''}}
        </v-subheader>
        <Pipeline v-if="admin" />
        <v-row class="my-2" />
        <UploadData v-if="admin" />
        <v-row class="my-2" />
        <JobTracking v-if="admin" />
        <v-row class="my-2" />
        <JobStatistics v-if="admin" />
        <v-row class="my-2" />
        <Inspection v-if="admin" />
        <v-row class="my-2" />
        <BetaTemplate v-if="admin" />
        <snackbar ref="snackbar" />
      </v-container>
      <div class="text-center">
        <v-bottom-sheet v-model="sheet">
          <v-sheet class="text-center" width="100vw">
            <v-card flat>
              <v-card-text>
                You are about to delete your tables <br />
                <!-- <span class="text-caption font-weight-bold">{{ jobInfo.tableid }}</span> <br>
                <span class="text-caption font-weight-bold">{{ jobInfo.downloadTable }}</span> <br> -->
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="error"
                  @click="
                    sheet = !sheet
                    deleteJob(jobInfo)
                    jobs = []
                  "
                >
                  Delete
                </v-btn>
                <v-btn color="error" @click="sheet = !sheet"> Close </v-btn>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Auth from './components/Auth.vue'
import BetaDoc from './components/BetaDoc.vue'
import Multiples from './components/Multiples.vue'
import UploadData from './components/UploadData/index.vue'
import JobTracking from './components/JobTracking/index.vue'
import JobStatistics from './components/JobStatistics.vue'
import Pipeline from './components/Pipeline.vue'
import Inspection from './components/Inspection.vue'
import BetaTemplate from './components/BetaTemplate.vue'
import Snackbar from './components/Common/Snackbar.vue'
import { msalMixin } from 'vue-msal'
import { HTTP } from './functions/http-common'
import Templates from './functions/templates'
import Excel from './functions/excel'
import CFI from './functions/cfi'
import ConfirmationModal from './components/Common/ConfirmationModal.vue'

export default {
  name: 'App',
  mixins: [msalMixin],
  components: {
    BetaDoc,
    Multiples,
    UploadData,
    JobTracking,
    Pipeline,
    Auth,
    JobStatistics,
    Inspection,
    BetaTemplate,
    snackbar: Snackbar,
    confirmationModal: ConfirmationModal
  },
  data: () => ({
    templates: new Templates(),
    excel: new Excel(),
    cfi: new CFI(),
    downloadMenu: false,
    message: '...',
    userInfo: '',
    completeData: '',
    sheet: false,
    stage: '',
    concatTables: [],
    jobFilter: 0,
    jobFilterOptions: [
      { option: 'today', value: 1 },
      { option: 'last week', value: 7 },
      { option: 'last month', value: 30 }
    ],
    admin: false,
    beta: false,
    multiples: false,
    betaAccess: ['vme', 'tcf', 'td', 'lead_advisory', 'sat_other', 'admin'],
    multiplesAccess: ['vme', 'tcf', 'td', 'lead_advisory', 'sat_other', 'admin'],
    jobInfo: {},
    jobs: [],
    selectedTable: '',
    modal: {
      isVisible: 0,
      isBetaPipeline: 0,
      isMultiplesPipeline: 0,
      metaSheetAvailable: 0,
      cbs: {
        betas: {
          dataSheets: {
            meta: 0,
            stockPrices: 1,
            leveredBeta: 1,
            deRatioKPIs: 1,
            creditSpread: 1,
            deRatio: 1,
            debtBeta: 1,
            unleveredBeta: 1,
          },
          templates: {
            beta: 1,
            chart: 1,
          }
        },
        multiples: {
          dataSheets: {
            meta: 0,
            multipleKPIs: 1,
            calculateTimeline: 1,
            calculateMultiple: 1,
          },
          templates: {
            chart: 1,
          },
        },
      },
      heading: 'Select Tables',
      content: 'Please select the tables you wish to download',
      buttons: [
        { name: 'Cancel', event: 'close', color: '' },
        { name: 'Download', event: 'download', color: '' },
      ],
      job: {}
    },
  }),
  mounted() {
    // log the git commit hash
    // console.log('log the git commit hash')
    // const fs = require('fs')
    // fs.readFile('testfile.txt', 'utf8', (err, data) => {
    //   if(err){console.log(err)}
    //   console.log(data)
    // })
    

    this.getStatus()
    setTimeout(() => {
      this.getJobs()
    }, 2500)
    window.setInterval(() => {
      if (this.downloadMenu) {
        this.getJobs()
      }
    }, 2000)
    this.$root.snackbar = this.$refs.snackbar
  },
  methods: {
    showConfirmationModal(job){
      // console.log('job:')
      // console.log(job)

      this.modal.isVisible = 1
      this.modal.job = job

      if(job.pipeline_job.function == 'Pipeline.run(unleveredbeta)'){
        this.modal.isBetaPipeline = 1
        this.modal.isMultiplesPipeline = 0
      }
      if(job.pipeline_job.function == 'Pipeline.run(multiple)'){
        this.modal.isBetaPipeline = 0
        this.modal.isMultiplesPipeline = 1
        //this.modal.cbs.templates.beta = 0
      }
    },
    toggleFilter(increment) {
      if (this.jobFilter + increment >= this.jobFilterOptions.length) {
        this.jobFilter =
          this.jobFilter + increment - this.jobFilterOptions.length
      } else if (this.jobFilter + increment < 0) {
        this.jobFilter =
          this.jobFilter + increment + this.jobFilterOptions.length
      } else {
        this.jobFilter = this.jobFilter + increment
      }
    },
    href() {
      this.$msal.signOut()
    },
    signIn() {
      // let accessToken = await window.Office.auth.getAccessToken({
      //   allowSignInPrompt: true,
      //   allowConsentPrompt: true,
      //   forMSGraphAccess: true,
      // })
      // console.log(accessToken)
      // let accessToken = await window.OfficeRuntime.auth.getAccessToken();
      // console.log(accessToken)
      // window.Office.context.ui.displayDialogAsync('https://login.microsoftonline.com');
      this.$msal.signIn()
    },
    async getStatus() {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      // console.log(this.$msal.data.graph.profile.userPrincipalName)
      await HTTP.get('/user/me', config).then(
        (response) => {
          console.log(response.data.roles)
          this.admin = response.data.roles.includes('admin')
          this.beta = response.data.roles.some((r) => this.betaAccess.includes(r))
          this.multiples = response.data.roles.some((r) => this.multiplesAccess.includes(r))
        },
        (error) => {
          console.log(error)
        }
      )
    },
    async getJobs() {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await HTTP
        .post(
          '/pipeline/getstatus/',
          {
            filter: {
              userid: this.$msal.data.graph.profile.mail
            }
          },
          config
        )
        .then((response) => {
          let responseData
          try {
            if (typeof response.data === 'string') {
              responseData = JSON.parse(response.data.replace(/NaN/g, 'null'))
            } 
            else if (typeof response.data === 'object') {
              // Handle the case where response.data is neither valid JSON nor a string
              responseData = response.data // Assign a default value or handle the error accordingly
            }
            else{
              responseData = JSON.parse(response.data)
              console.log(typeof response.data)
            }
          } catch (error) {
            console.log(error)
          }
          const joblist = []
          // const captions = Object.keys(responseData)
          for (let job_num in responseData) {
            if (!responseData[job_num].message) {
              joblist.push(responseData[job_num])
            }
          }
          this.jobs = joblist
        }, (error) => {
          let responseData
          try {
            if (typeof error.response.data === 'string') {
              responseData = JSON.parse(error.response.data.replace(/NaN/g, 'null'))
            } 
            else if (typeof error.response.data === 'object') {
              // Handle the case where response.data is neither valid JSON nor a string
              responseData = error.response.data // Assign a default value or handle the error accordingly
            }
            else{
              responseData = JSON.parse(error.response.data)
              console.log(typeof error.response.data)
            }
          } catch (error) {
            console.log(error)
          }
          const joblist = []
          // const captions = Object.keys(responseData)
          for (let job_num in responseData) {
            if (!responseData[job_num].message) {
              joblist.push(responseData[job_num])
            }
            // joblist[job_num]['caption'] = captions[job_num]
          }
          this.jobs = joblist
        })
    },
    async deleteJob(job) {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await HTTP
        .post('/pipeline/delete/' + job.pipeline_job.caption,
        {
          filter: {
            userid: this.$msal.data.graph.profile.mail
          }
        },
        config)
        .then(
          (response) => {
            console.log(response)
          },
          (error) => {
            console.log(error)
          }
        )
    },
    async getTable() {
      console.log('GET TABLE')
      let job = this.modal.job
      let cbs = this.modal.cbs

      await this.$msal.acquireToken()
      const config = {
        user: this.$msal.data.graph.profile.mail,
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      await this.cfi.downloadJobTable(job.pipeline_job.caption, config, job.pipeline_job.function, cbs)
      console.log('BUFFER')
      console.log(this.cfi.buffer)
      await this.excel.pasteSheets(this.cfi.buffer, this.templates.datasheetHeader_dark, this.modal, this.$root.snackbar)
      this.cfi.downloadLoading = false
      this.selectedTable = ''
      this.cfi.buffer = []
    },
    getPermissions() {
      let success = {
        data: [
          'industrybeta.show',
          'inudstrybeta.calculate',
          'costofcapital.show'
        ]
      }
      localStorage.setItem('permissions', JSON.stringify(success.data))
    },
    updateCheckBoxes_pt2(){
      if(this.modal.cbs.betas.templates.beta){
        this.modal.cbs.betas.dataSheets.unleveredBeta = 1
      }
    }
  },
  computed: {
    updateCheckBoxes(){
      this.updateCheckBoxes_pt2()
      return null
    },
    jobsFiltered() {
      const nextMidnight = new Date()
      nextMidnight.setHours(24, 0, 0, 0)
      // console.log('JOBS')
      // console.log(this.jobs)
      const filtered = this.jobs.filter(
        (a) =>
          Math.abs(new Date(a.pipeline_job.last_updated) - nextMidnight) /
            (1000 * 60 * 60 * 24) <
          this.jobFilterOptions[this.jobFilter].value
      )
      return filtered
    },
  }
}
</script>
