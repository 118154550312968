<template>
  <div>
    <v-list-item three-line>
      <v-list-item-content>
        <v-menu
          open-on-hover
          top
          dark
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-title>3. Meta Data / tickersMeta
              <v-icon class="pl-1" small v-on="on" v-bind="attrs" style="vertical-align: middle">
                mdi-information
              </v-icon>
            </v-list-item-title>
          </template>
          <div class="px-2 py-2 offwhite">
            <v-list-item-subtitle>
              Press <v-icon color="offblack" x-small> mdi-compare-horizontal </v-icon> to compare
            </v-list-item-subtitle>
            <v-list-item-subtitle>Compare and send</v-list-item-subtitle>
          </div>
        </v-menu>
        
        <v-list-item-subtitle>
          DB: {{cfi.uploadSheets.tickersmeta.len}}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="metaTickers.length && sheets.length">
          {{
            sheets
              .filter((a) => tickerTables.includes(a.name))[0]
              .excel.reduce((arr, entry) => {
                if (!metaTickers.includes(entry.ticker)) {
                  arr.push(entry.ticker)
                }
                return arr
              }, []).length
          }}
          need Meta Information
        </v-list-item-subtitle>
        <v-progress-linear
          v-if="cfi.uploadSheets.tickersmeta.reading"
          size="25"
          color="yellow"
          :indeterminate="cfi.uploadSheets.tickersmeta.reading"
        />
      </v-list-item-content>
      <v-list-item-icon
        v-if="
          sheets.filter((a) => tickerTables.includes(a.name)).length > 0
        "
      >
        <v-btn color="offblack" icon @click="cfi.openModal(3)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn color="offblack" icon @click="downloadMetaTickers()">
          <!-- uploadData(sheet.name, sheet.excel) -->
          <v-icon>mdi-compare-horizontal</v-icon>
        </v-btn>
      </v-list-item-icon>
      <v-list-item-icon>
        <v-btn
          v-if="!metaLoading"
          color="offblack"
          :disabled="metaTickers.length == 0"
          icon
          @click="
            updateMetaTickers(
              sheets
                .filter((a) => tickerTables.includes(a.name))[0]
                .excel.reduce((arr, entry) => {
                  if (!metaTickers.includes(entry.ticker)) {
                    arr.push(entry.ticker)
                  }
                  return arr
                }, [])
            )
          "
        >
          <!-- uploadData(sheet.name, sheet.excel) -->
          <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-progress-circular
          v-else
          size="25"
          color="yellow"
          :value="metaprogress"
        />
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script>
import { HTTP } from '../../functions/http-common'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'MetaData',
  data: () => ({
    metaLoading: false,
    metaTickers: [],
    tickerTables: ['tickerInputs'],
    metaprogress: 0
  }),
  props: {
    sheets: {
      type: Array,
      required: true
    },
    cfi: {
      type: Object,
      required: true
    } 
  },
  methods: {
    async updateMetaTickers(tickers) {
      this.metaLoading = true
      let body = {
        tickers: [],
        caption: String(uuidv4()).replaceAll('-', ''),
        tablenames: {
          'tickersmeta': 'tickersMeta'
        },
        capiq_timeout: 30,
        jobid: String(uuidv4()).replaceAll('-', '')
      };
      const noOfIterations = Math.ceil(tickers.length/100);
      for(let iteration = 0; iteration < noOfIterations; iteration++) {
        const tickerlist = [];
        tickers.slice(iteration*100, (iteration*100)+100).forEach(ticker => {
          tickerlist.push(ticker);
        })
        if (tickerlist.length) body.tickers = tickerlist;
        console.log(body)
        await this.$msal.acquireToken()
        this.cfi.token = this.$msal.data.accessToken
        const config = {
          headers: {
            Authorization: `Bearer ${this.$msal.data.accessToken}`
          }
        }
        await HTTP
          .post('/download/tickermeta/', body, config)
          .then(
            (response) => {
              console.log(response)
            },
            (error) => {
              console.log(error)
            }
          )
        this.metaprogress = ((iteration +1 )/noOfIterations)*100
        }
        this.metaLoading = false
        this.cfi.getTableEntries(['tickersmeta'])
    },
    async downloadMetaTickers() {
      await this.$msal.acquireToken()
      const config = {
        headers: {
          Authorization: `Bearer ${this.$msal.data.accessToken}`
        }
      }
      this.cfi.uploadSheets.tickersmeta.reading = true
      await HTTP
        .get('/table/retrievebyrecords/tickersmeta', config)
        .then(
          (response) => {
            response.data.map((entry) => {
              delete entry['RowKey']
              delete entry['etag']
              delete entry['Timestamp']
              delete entry['_IndCol']
              if (entry['PartitionKey'] == 'sample') {
                entry['PartitionKey'] = 'latest'
              }
            })
            this.metaTickers = response.data.reduce((arr, entry) => {
              if (entry.ticker != undefined) {
                arr.push(entry.ticker)
              }
              return arr
            }, [])
            if (!this.metaTickers.length) {
              this.metaTickers = ['nothing:nothing']
            }
            this.cfi.uploadSheets.tickersmeta.reading = false
          },
          (error) => {
            console.log(error)
            this.metaTickers = ['nothing:nothing']
            this.cfi.uploadSheets.tickersmeta.reading = false
          }
        )
    },
  }
}
</script>