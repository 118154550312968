<template>
  <div :class="!cfi.tabledata.data ? 'mb-4' : ''">
    <v-stepper-step
      :complete="cfi.stepper > 1"
      step="1"
      class='my-0 py-0'
    >
      Inspect current table
      <v-progress-linear
        v-if="cfi.uploadProgress"
        size="25"
        color="offblack"
        :value="cfi.uploadProgress"
      />
    </v-stepper-step>
    <v-stepper-content
      v-if="cfi.tabledata.data != undefined"
      class="ma-1 pa-1"
      step="1"
    >
      <v-row align="center" class="my-0">
        <v-col class="d-flex" cols="6" md="6" xs="6">
          <v-btn
            class="ml-3 mb-2"
            x-small
            :disabled="!!cfi.uploadProgress"
            @click="uploadData()"
          >
            Upload staging
          </v-btn>
        </v-col>
        <v-col class="d-flex" cols="6" md="6" xs="6">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-6"
              >
                mdi-menu
              </v-icon>
            </template>
            <v-list color="offwhite" width="200" dense>
              <v-list-item
                @click="appendNewRows()"
                :disabled="!!cfi.uploadProgress"
              >
                <v-list-item-content>
                    <v-list-item-title>Append</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="
                    cfi.tabledata.data.filter((a) => a['PartitionKey'] == 'latest')
                    .length > 0
                "
                :disabled="!!cfi.uploadProgress"
                @click="removePartition('latest')"
              >
                <v-list-item-content>
                  <v-list-item-title>Remove Prod</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="
                  cfi.tabledata.data.filter((a) => a['PartitionKey'] == 'staging')
                  .length > 0
                "
                :disabled="!!cfi.uploadProgress"
                @click="removePartition('staging')"
              >
                <v-list-item-content>
                    <v-list-item-title>Remove staging</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="pasteSheet(cfi.tabledata, cfi.tabledata.name + '_inspect')"
                :disabled="!!cfi.uploadProgress"
              >
                <v-list-item-content>
                    <v-list-item-title>Paste to Sheet</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <div class="ml-4">{{cfi.successMessage}}<br /></div>
      <v-row v-if="cfi.tabledata.data != undefined" class="ma-0">
        <v-col class="ma-0 px-6">
          <div v-if="colsMismatchInExcel">
            Cols not in excel: <br />
            <v-chip
              v-for="(item, index) in Object.keys(
              cfi.tabledata.data[0]
              ).filter(
              (a) => !Object.keys(cfi.tabledata.excel[0]).includes(a)
              )"
              :key="index"
              x-small
              label
              class="mr-2"
              >{{ item }}
            </v-chip>
          </div>
          <div v-if="colsMismatchInDatabase">
            Cols not in database: <br />
            <v-chip
              v-for="(item, index) in Object.keys(
              cfi.tabledata.excel[0]
              ).filter(
              (a) => !Object.keys(cfi.tabledata.data[0]).includes(a)
              )"
              :key="index"
              x-small
              label
              class="mr-2"
            >
              {{ item }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <div class="float-right">
        <v-btn
          color="primary"
          x-small
          @click="
          cfi.stepper = 2
          cfi.successMessage = ''
          checkData(cfi.filterCol)
          "
        >
          Next
        </v-btn>
        <v-btn
          text
          x-small
          class="mx-2"
          @click="
          cfi.openOverlay = false
          cfi.tabledata = {}
          cfi.validateRowsMessage = ''
          "
        >
          Cancel
        </v-btn>
      </div>
    </v-stepper-content>
  </div>
</template>

<script>

export default {
  name: 'InspectTable',
  props: {
    cfi: {
      type: Object,
      required: true
    },
    checkData: {
      type: Function,
      required: true
    },
    excel: {
      type: Object,
      required: true
    },
    templates: {
      type: Object,
      required: true
    }
  },
  computed: {
    colsMismatchInExcel() {
      return Object.keys(this.cfi.tabledata.data[0]).filter((a) => !Object.keys(this.cfi.tabledata.data[0]).includes(a)).length
    },
    colsMismatchInDatabase() {
      return Object.keys(this.cfi.tabledata.data[0]).filter((a) => !Object.keys(this.cfi.tabledata.excel[0]).includes(a)).length
    }
  },
  methods: {
    async pasteSheet(data, sheetname) {
      const metadata = {
        sheetname
      }
      await this.cfi.writeToBuffer(
        data.data,
        metadata,
      )
      await this.excel.pasteSheets(this.cfi.buffer, this.cfi.header, this.$root.snackbar)
      this.cfi.buffer = []
      //await this.getData.pasteSheet(data.data, sheetname, this.$root.snackbar)
      let paste = window.Excel.run(async (context) => {
        let sheets = context.workbook.worksheets
        sheets.load('items/name')
        await context.sync()
        console.log('Going to get sheet: ', sheetname)
        let sheet = sheets.getItem(sheetname)
        let table = sheet.tables.getItem(
          sheetname.replaceAll(' ', '').substring(3)
        )
        let tabledata = table.getDataBodyRange().load('values')
        await context.sync()
        let rows = tabledata.values.length
        let rowsFormat = []
        data.data.map((entry) => {
          delete entry['PartitionKey']
          delete entry['RowKey']
          delete entry['etag']
          delete entry['Timestamp']
          delete entry['_IndCol']
        })
        data.excel.map((entry) => {
          delete entry['PartitionKey']
          delete entry['RowKey']
          delete entry['etag']
          delete entry['Timestamp']
          delete entry['_IndCol']
        })
        for (const item in data.data) {
          if (
            data.excel.find(
              (a) => JSON.stringify(a) == JSON.stringify(data.data[item])
            ) > -1
          ) {
            rowsFormat.push({ color: '#34C768' })
          } else {
            rowsFormat.push({ color: '#7A130D' })
          }
        }
        if (rows == rowsFormat.length) {
          console.log('++ Is Range same: ', rows, ' vs. ', rowsFormat.length)
          const rowRange = table.getHeaderRowRange().load('address')
          await context.sync()
          console.log('Header Row: ', rowRange)
          for (let r = 0; r < rowsFormat.length; r++) {
            let rowRange2 = rowRange.address.replaceAll('7', String(8 + r))
            let range = sheet.getRange(rowRange2)
            range.format.font.color = rowsFormat[r].color
          }
        }
        return await context.sync()
      }).catch((error) => {
        console.log(error)
      })
      return await paste
    },
    async appendNewRows() {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      this.cfi.appendNewRows(this.cfi.tabledata.name, this.cfi.tabledata.excel, 'append')
    },
    async removePartition(partitionType) {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      this.cfi.removePartition(this.cfi.tabledata.name, partitionType, true)
    },
    async uploadData() {
      await this.$msal.acquireToken()
      this.cfi.token = this.$msal.data.accessToken
      this.cfi.uploadData(this.cfi.tabledata.name, this.cfi.tabledata.excel,200, true)
    }
  }
}
</script>
