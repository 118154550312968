<template>
  <v-list v-if="jobs.tracking != {}" class="px-2" dense>
    <v-list-item
        link
        @click="updateSubJobs"
    >
      <v-list-item-content>
        <v-list-item-title>View running sub jobs
          <v-progress-circular
            v-if="jobs.loader.getSubJobs > 0"
            size="15"
            color="offblack"
            :value="jobs.loader.getSubJobs"
          />
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon small> mdi-arrow-right-thick </v-icon>
      </v-list-item-icon>
    </v-list-item>
    <v-subheader>Admin Queue</v-subheader>
    <div v-for="(user, i) in jobs.tracking" :key="i">
      {{ print(i) }}
      <!-- {{ filterForAdminRuns(user) }} -->
      <v-list-item v-for="(job, j) in filterForAdminRuns(user)" :key="j" link>
        <v-list-item-content @click="getPipeline(job.caption)">
          <v-list-item-title class="text-caption">
            {{ i.split('@')[0].replaceAll('.', ' ') }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ job.caption }}
            {{ print(job) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    
    <v-subheader>Other Users</v-subheader>
    <v-list-item
        v-for="(user, i) in trackingList.slice(jobpage * 10 - 10, jobpage * 10)"
        :key="i"
        link
        @click="updateUserId(jobs.tracking[user][0].userid)"
    >
        <v-list-item-content
          v-if="jobs.tracking[user][0].userid != jobs.profileMail"
        >
        <v-list-item-title
            class="text-caption"
            v-text="jobs.tracking[user][0].userid.split('@')[0].replaceAll('.', ' ')"
        />
          <v-list-item-subtitle>
            {{ jobs.tracking[user].length }} jobs
          </v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
    <pagination
      :isPagination="trackingList.length > 10"
      :noOfPages="Math.ceil(trackingList.length / 10)"
      v-model="jobpage"
      />
    <v-subheader>Clean Up</v-subheader>
    <v-list-item
      link
      @click="updateCleanupJobs"
    >
      <v-list-item-content>
        <v-list-item-title>View jobs with filtered options
          <v-progress-circular
            v-if="jobs.loader.getCleanupJobs > 0"
            size="15"
            color="offblack"
            :value="jobs.loader.getCleanupJobs"
          />
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon small> mdi-arrow-right-thick </v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>

<script>
import Pagination from './../Common/Pagination.vue'
export default {
  name: 'AllTracking',
  props: {
    jobs: {
      type: Object,
      required: true
    }
  },
  components: {
    pagination: Pagination
  },
  data: () => ({
    jobpage: 1
  }),
  model: {
      prop: 'openSubJobs',
      event: 'openSubJobsChange'
  },
  computed: {
    openSubJobsLocal: {
      get: function() {
          return this.openSubJobs
      },
      set: function(value) {
          this.$emit('openSubJobsChange', value)
      }
    },
    trackingList() {
      return Object.keys(this.jobs.tracking)
    }
  },
  methods: {
    async updateSubJobs() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      await this.jobs.getSubJobs(this.jobs.profileMail)
      this.openSubJobsLocal = true
    },
    async updateCleanupJobs() {
      await this.$msal.acquireToken()
      this.jobs.token = this.$msal.data.accessToken
      await this.jobs.getJobsForCleanUp()
      this.$emit('on-update-jobcleanup', true)
    },
    updateUserId(userId) {
      this.$emit('on-update-userId', {trackingFocus: userId, openJobMenu: true})
    },
    // async getPipeline(tableId) {
    //   // await this.$msal.acquireToken()
    //   // this.jobs.token = this.$msal.data.accessToken
    //   // this.$emit('on-update-overlay', true)
    //   // this.openJobMenuLocal = !this.openJobMenu
    //   // this.jobs.pipelineFocus= {...this.jobs.pipelineFocus, name: tableId}
    //   // this.jobs.getPipelineInfo(this.jobs.pipelineFocus.name)
    // },
    filterForAdminRuns(array){
      let filteredArray = []
      for(let i = 0; i < array.length; i++){
        // console.log(array[i].message)
        if(array[i].message.indexOf("cfi-jobs-1") >= 0){
          filteredArray.push(array[i])
        }
      }
      // console.log("filtered")
      // for(let i = 0; i < filteredArray.length; i++){
      //   console.log(filteredArray[i].message)
      // }
      return filteredArray
    },
    print(s){
      console.log(s)
    }
  }
}
</script>
